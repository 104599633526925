.comments {
  position: relative;
}
@media (max-width: 1200px) {
  .comments {
    display: flex;
    flex-direction: column-reverse;
  }
}
.comments textarea {
  margin-top: -0.625rem;
}
.comments__loading {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
}
@media (max-width: 1200px) {
  .comments__content {
    margin-top: 4rem;
  }
}
.comments__content__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.4;
  color: #030303;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .comments__content__title {
    font-size: 1.5rem;
    padding-bottom: 2rem;
  }
}
.comments__content__comment {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .comments__content__comment {
    margin-top: 0;
    margin-bottom: 3rem;
  }
}
.comments__content__comment__photo {
  min-width: 3rem;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  background-color: #f5f5f5;
  margin-right: 1rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .comments__content__comment__photo {
    margin-right: 0.5rem;
  }
}
.comments__content__comment__photo img {
  width: 100%;
}
.comments__content__comment__data {
  flex: 1;
}
.comments__content__comment__data__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}
@media (max-width: 1200px) {
  .comments__content__comment__data__header {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
.comments__content__comment__data__header__author {
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .comments__content__comment__data__header__author {
    font-size: 0.875rem;
  }
}
.comments__content__comment__data__header__author span {
  font-weight: 600;
}
.comments__content__comment__data__header__date {
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .comments__content__comment__data__header__date {
    font-size: 0.625rem;
    margin-bottom: 0.5rem;
  }
}
.comments__content__comment__data__text {
  margin-top: 1rem;
  line-height: 1.8;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .comments__content__comment__data__text {
    font-size: 0.875rem;
  }
}
.comments__content__button,
.comments__content button.comments__content__button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  display: block;
  margin: 4rem auto 0 auto;
}
@media (max-width: 1200px) {
  .comments__content__button,
  .comments__content button.comments__content__button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .comments__content__button,
  .comments__content button.comments__content__button {
    margin-bottom: 4rem;
  }
}
.comments__create {
  margin-top: 7rem;
}
@media (max-width: 1200px) {
  .comments__create {
    margin-top: 0;
  }
}
.comments__create__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  color: #030303;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .comments__create__title {
    font-size: 1.5rem;
  }
}
.comments__create__text {
  margin-top: 1.5rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .comments__create__text {
    margin-top: 1rem;
    font-size: 0.875rem;
  }
}
.comments__create > .input {
  margin-top: 2rem;
}
@media (max-width: 1200px) {
  .comments__create > .input {
    margin-top: 1.5rem;
  }
}
.comments__create__send {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
@media (max-width: 1200px) {
  .comments__create__send {
    flex-direction: column;
  }
}
.comments__create__send__button,
.comments__create__send button.comments__create__send__button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  margin-left: 2rem;
}
@media (max-width: 1200px) {
  .comments__create__send__button,
  .comments__create__send button.comments__create__send__button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .comments__create__send__button,
  .comments__create__send button.comments__create__send__button {
    margin-left: 0;
    margin-top: 1rem;
  }
}
