@import 'variables';

.comments {
    position: relative;

    @media (max-width: @mobileBreakpoint) {
        display: flex;
        flex-direction: column-reverse;
    }

    textarea {
        margin-top: -0.625rem;
    }

    &__loading {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
    }

    &__content {
        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacing2XL;
        }

        &__title {
            .fontBarlow();

            font-size: 2rem;
            font-weight: bold;
            font-style: italic;
            line-height: 1.4;
            color: @colorDark;
            text-transform: uppercase;

            @media (max-width: @mobileBreakpoint) {
                font-size: 1.5rem;
                padding-bottom: @spacingL;
            }
        }

        &__comment {
            margin-top: @spacingXL;
            display: flex;
            flex-direction: row;

            @media (max-width: @mobileBreakpoint) {
                margin-top: 0;
                margin-bottom: @spacingXL;
            }

            &__photo {
                min-width: 3rem;
                width: 3rem;
                height: 3rem;
                border-radius: 100%;
                background-color: @colorNeutral1;
                margin-right: @spacingS;
                overflow: hidden;

                @media (max-width: @mobileBreakpoint) {
                    margin-right: @spacingXXS;
                }

                img {
                    width: 100%;
                }
            }

            &__data {
                flex: 1;

                &__header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    flex: 1;

                    @media (max-width: @mobileBreakpoint) {
                        flex-direction: column-reverse;
                        align-items: flex-start;
                    }

                    &__author {
                        color: #1f1f1f;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                        }

                        span {
                            font-weight: 600;
                        }
                    }

                    &__date {
                        font-size: 0.75rem;
                        color: #1f1f1f;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.625rem;
                            margin-bottom: @spacingXXS;
                        }
                    }
                }

                &__text {
                    margin-top: @spacingS;
                    line-height: 1.8;
                    color: #1f1f1f;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.875rem;
                    }
                }
            }
        }

        &__button, button.comments__content__button {
            .button();

            display: block;
            margin: @spacing2XL auto 0 auto;

            @media (max-width: @mobileBreakpoint) {
                margin-bottom: @spacing2XL;
            }
        }
    }

    &__create {
        margin-top: @spacing4XL;

        @media (max-width: @mobileBreakpoint) {
            margin-top: 0;
        }

        &__title {
            .fontBarlow();

            font-size: 2rem;
            font-weight: bold;
            font-style: italic;
            color: @colorDark;
            text-transform: uppercase;

            @media (max-width: @mobileBreakpoint) {
                font-size: 1.5rem;
            }
        }

        &__text {
            margin-top: @spacingM;
            color: #1f1f1f;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingS;
                font-size: 0.875rem;
            }
        }

        >.input {
            margin-top: @spacingL;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingM;
            }
        }

        &__send {
            margin-top: @spacingL;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            @media (max-width: @mobileBreakpoint) {
                flex-direction: column;
            }

            &__button, button.comments__create__send__button {
                .button();

                margin-left: @spacingL;

                @media (max-width: @mobileBreakpoint) {
                    margin-left: 0;
                    margin-top: @spacingS;
                }
            }
        }
    }
}
