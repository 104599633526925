@import 'variables';

.public {

    &__header {
        height: 54rem;
        display: flex;
        flex-direction: row;

        @media (max-width: @mobileBreakpoint) {
            height: auto;
            flex-direction: column-reverse;
        }

        &__left {
            position: relative;
            flex: 1;
            margin-left: @spacing5XL;
            display: flex;
            flex-direction: column;
            max-width: 28.5rem;

            @media (max-width: @mobileBreakpoint) {
                max-width: 100%;
                margin-left: 0;
            }

            &__up {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;

                &__title {
                    .fontBarlow();

                    font-size: 8rem;
                    font-weight: bold;
                    font-style: italic;
                    line-height: 1.2;
                    color: #1f1f1f;
                    white-space: nowrap;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 3.5rem;
                        padding-top: @spacingM;
                    }
                }

                &__text {
                    margin-top: @spacingXXS;
                    //text-transform: uppercase;
                    font-size: 1.25rem;
                    line-height: 1.6;
                    color: @colorNeutral2;
                    font-weight: normal;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1rem;
                    }
                }

                &__button, button {
                    .buttonPrimary();

                    margin-top: @spacing2XL;
                    width: 11rem;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingL;
                        width: 9.8125rem;
                        height: 2.5rem;
                    }
                }
            }

            &__down {
                padding-bottom: @spacingXL;
                border-top: 2px solid @colorNeutral1;
                width: ~"calc(100% + @{spacing4XL})";

                @media (max-width: @mobileBreakpoint) {
                    padding-bottom: 0;
                    width: 100%;
                    margin-top: @spacing2XL;
                }

                &__title {
                    padding-top: @spacingM;
                    font-size: 0.875rem;
                    font-weight: 600;
                    letter-spacing: 0.7px;
                    color: #161616;
                    text-transform: uppercase;
                }

                &__partners {
                    margin-top: @spacingM;
                    height: 4rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingS;
                        height: 2rem;
                    }

                    button:first-of-type svg {
                        .rotate(-180deg);
                    }
                    svg {
                        width: 3rem;
                        height: 3rem;
                        color: @colorNeutral2;

                        @media (max-width: @mobileBreakpoint) {
                            width: 2rem;
                            height: 2rem;
                        }
                    }

                    &__content {
                        overflow: hidden;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        height: 4rem;
                        flex-wrap: nowrap;
                        margin: @spacingXXS;

                        img {
                            margin-right: @spacingL;
                            width: auto;
                            height: 2rem;
                            color: @colorNeutral2;

                            @media (max-width: @mobileBreakpoint) {
                                width: 2rem;
                                height: 2rem;
                            }
                        }
                    }
                }
            }
        }

        &__photo {
            margin-left: 16rem;
            min-width: 38rem;
            width: 38rem;
            height: 38rem;
            overflow: hidden;

            @media (max-width: @mobileBreakpoint) {
                min-width: 100%;
                width: 100%;
                max-width: 38rem;
                height: auto;
                margin-left: 0;
            }

            img {
                width: 100%;
            }
        }
    }

    &__stats {
        margin: @spacing5XL auto 0 auto;
        width: 62.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacing4XL;
            width: 100%;
            flex-direction: column;
        }

        &__panel {
            width: 22rem;

            @media (max-width: @mobileBreakpoint) {
                width: 100%;
            }

            &__text {
                .fontBarlow();

                font-size: 2rem;
                font-weight: bold;
                font-style: italic;
                line-height: 1.4;
                color: @colorDark;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.5rem;
                    padding-bottom: @spacingM;
                }
            }

            &__subtext {
                padding-top: @spacingM;
                line-height: 1.8;
                color: @colorDark;
                font-size: 1.25rem;
                font-weight: normal;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                    padding-top: 0;
                    padding-bottom: @spacingL;
                }
            }

            &__item {
                margin-bottom: @spacing3XL;

                @media (max-width: @mobileBreakpoint) {
                    margin-bottom: @spacing2XL;
                    padding-left: @spacing4XL;
                }

                &__title {
                    .fontBarlow();

                    font-size: 2rem;
                    font-weight: bold;
                    font-style: italic;
                    color: @colorDark;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1.5rem;
                    }
                }

                &__text {
                    margin-top: @spacingL;
                    line-height: 1.8;
                    color: #1f1f1f;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingS;
                        font-size: 0.875rem;
                    }
                }
            }
            &__item:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &__categories {
        margin-top: @spacing5XL;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media (max-width: @mobileBreakpoint) {
            flex-direction: column;
        }

        &__category, button.public__categories__category {
            position: relative;
            margin-right: @spacingL;
            margin-bottom: @spacingL;
            width: ~"calc((100% - @{spacingL} * 3) / 4)";
            padding: @spacingL;
            border-radius: 0;
            background-color: #fff;
            box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
            height: 12.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            overflow: hidden;

            @media (max-width: @mobileBreakpoint) {
                margin-right: 0;
                margin-bottom: @spacingS;
                width: 100%;
                padding: @spacingM;
                height: 9rem;
            }

            img {
                position: absolute;
                right: -5rem;
                bottom: -5rem;
                height: 16.25rem;
                mix-blend-mode: multiply;

                @media (max-width: @mobileBreakpoint) {
                    height: 12rem;
                    right: -3rem;
                    bottom: -3rem;
                }
            }

            &__name {
                .fontBarlow();

                font-size: 1.5rem;
                font-weight: bold;
                font-style: italic;
                line-height: 1.2;
                color: #1f1f1f;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.25rem;
                }
            }

            &__link {
                display: flex;
                flex-direction: row;
                align-items: center;

                svg {
                    width: 2rem;
                    height: 2rem;
                    color: @colorNeutral2;

                    @media (max-width: @mobileBreakpoint) {
                        width: 1.75rem;
                        height: 1.75rem;
                    }
                }

                span {
                    .fontBarlow();

                    margin-left: @spacingXS;
                    font-weight: bold;
                    font-style: italic;
                    line-height: 1.2;
                    letter-spacing: 0.8px;
                    color: @colorNeutral2;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.875rem;
                    }
                }
            }
        }
        &__category:hover, button.public__categories__category:hover {
            box-shadow: none;
        }
        &__category:nth-child(4n + 4), button.public__categories__category:nth-child(4n + 4) {
            margin-right: 0;
        }
        &__category:last-of-type {
            @media (max-width: @mobileBreakpoint) {
                margin-bottom: 0;
            }
        }
    }

    &__goalies {
        margin-top: @spacing2XL;

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacing4XL;
        }

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @media (max-width: @mobileBreakpoint) {
                flex-direction: column;
            }

            &__left {
                width: 38rem;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                }

                &__title {
                    .fontBarlow();

                    font-size: 3.5rem;
                    font-weight: bold;
                    font-stretch: condensed;
                    font-style: italic;
                    color: #1f1f1f;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 2.5rem;
                    }
                }

                &__text {
                    margin-top: @spacingXL;
                    line-height: 1.8;
                    color: #1f1f1f;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.875rem;
                    }
                }
            }

            &__button, button.public__goalies__header__button {
                @media (max-width: @mobileBreakpoint) {
                    display: none;
                }

                svg {
                    width: 2rem;
                    height: 2rem;
                    color: @colorNeutral2;
                }

                span {
                    .fontBarlow();

                    margin-left: @spacingXS;
                    font-weight: bold;
                    font-style: italic;
                    line-height: 1.2;
                    letter-spacing: 0.8px;
                    color: @colorNeutral2;
                    text-transform: uppercase;
                }
            }
        }

        &__content {
            position: relative;
            padding-left: 8rem;

            @media (max-width: @mobileBreakpoint) {
                padding-left: 0;
            }

            .swiper {
                position: initial;
            }

            .swiper-scrollbar {
                left: 8rem !important;
                width: ~"calc(100% - 8rem)" !important;
            }

            &__arrows {
                position: absolute;
                left: 0;
                top: @spacing4XL;
                width: 8rem - @spacingS;
                height: 16.5rem;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;

                @media (max-width: @mobileBreakpoint) {
                    display: none;
                }

                svg {
                    width: 3rem;
                    height: 3rem;
                    color: #1f1f1f;
                    opacity: .5;
                    cursor: pointer;
                }
                svg:first-of-type {
                    .rotate(-180deg);
                }
            }

            .goaly {
                min-width: 23.5rem;
                padding-bottom: @spacingL;

                @media (max-width: @mobileBreakpoint) {
                    width: 9.8125rem;
                    min-width: 9.8125rem;
                    margin-right: @spacingXXS;
                    margin-top: @spacing3XL;
                }

                .goaly__photo {
                    @media (max-width: @mobileBreakpoint) {
                        height: 11.125rem;
                    }

                    img {
                        @media (max-width: @mobileBreakpoint) {
                            height: 100%;
                            width: auto;
                        }
                    }
                }

                .goaly__header {
                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingS;
                        height: auto;
                    }

                    svg {
                        @media (max-width: @mobileBreakpoint) {
                            display: none;
                        }
                    }

                    .goaly__header__info {
                        @media (max-width: @mobileBreakpoint) {
                            margin-left: 0;
                        }

                        .goaly__header__info__name {
                            @media (max-width: @mobileBreakpoint) {
                                margin-bottom: @spacingXXS;
                                font-size: 0.875rem;
                            }
                        }
                    }
                }

                .goaly__text {
                    @media (max-width: @mobileBreakpoint) {
                        display: none;
                    }
                }
            }
        }
    }

    &__products {
        margin-top: @spacing6XL;

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacing4XL;
        }

        &__title {
            .fontBarlow();

            font-size: 3.5rem;
            font-weight: bold;
            font-stretch: condensed;
            font-style: italic;
            color: #1f1f1f;
            text-transform: uppercase;

            @media (max-width: @mobileBreakpoint) {
                font-size: 2.5rem;
            }
        }

        &__buttons {
            margin-top: @spacingXL;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingL;
            }

            &__left {
                display: flex;
                flex-direction: row;
                align-items: center;

                @media (max-width: @mobileBreakpoint) {
                    flex: 1;
                }

                &__button, button.public__products__buttons__left__button {
                    .button();

                    margin-right: @spacingS;

                    @media (max-width: @mobileBreakpoint) {
                        flex: 1;
                        margin-right: @spacingXXS;
                    }
                }
                &__button:last-of-type, button.public__products__buttons__left__button:last-of-type {
                    margin-right: 0;
                }
                button.public__products__buttons__left__button.active {
                    box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.16);
                    background-color: #1f1f1f;
                    color: #fefefe;
                }
            }

            &__right {
                @media (max-width: @mobileBreakpoint) {
                    display: none;
                }

                &__button, button.public__products__buttons__right__button {
                    svg {
                        width: 2rem;
                        height: 2rem;
                        color: @colorNeutral2;
                    }

                    span {
                        .fontBarlow();

                        margin-left: @spacingXS;
                        font-weight: bold;
                        font-style: italic;
                        line-height: 1.2;
                        letter-spacing: 0.8px;
                        color: @colorNeutral2;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .products-list {
            margin-top: @spacing3XL;

            @media (max-width: @mobileBreakpoint) {
                margin-top: 0;
            }

            button.products-list__content__item {
                width: ~"calc((100% - @{spacingL} * 3) / 4)";

                @media (max-width: @mobileBreakpoint) {
                    width: ~"calc((100% - @{spacingXXS}) / 2)";
                }
            }
            button.products-list__content__item:nth-child(3n + 3) {
                margin-right: @spacingL;

                @media (max-width: @mobileBreakpoint) {
                    margin-right: @spacingXXS;
                }
            }
            button.products-list__content__item:nth-child(4n + 4) {
                margin-right: 0;

                @media (max-width: @mobileBreakpoint) {
                    margin-right: @spacingXXS;
                }
            }
            button.products-list__content__item:nth-child(2n + 2) {
                @media (max-width: @mobileBreakpoint) {
                    margin-right: 0;
                }
            }

            .products-list__load {
                .buttonPrimary();

                @media (max-width: @mobileBreakpoint) {
                    width: 9.8125rem;
                    height: 2.5rem;
                }
            }
        }
    }

    &__references {
        margin-top: @spacing6XL;

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacing4XL;
        }

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &__title {
                .fontBarlow();

                font-size: 3.5rem;
                font-weight: bold;
                font-stretch: condensed;
                font-style: italic;
                color: #1f1f1f;
                text-transform: uppercase;
                text-align: center;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 2.5rem;
                }
            }

            &__button, button.public__references__header__button {
                display: none;

                svg {
                    width: 2rem;
                    height: 2rem;
                    color: @colorNeutral2;
                }

                span {
                    .fontBarlow();

                    margin-left: @spacingXS;
                    font-weight: bold;
                    font-style: italic;
                    line-height: 1.2;
                    letter-spacing: 0.8px;
                    color: @colorNeutral2;
                    text-transform: uppercase;
                }
            }
        }

        &__content {
            position: relative;
            margin-top: @spacingXL;
            padding-left: 8rem;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingL;
                padding-left: 0;
            }

            .swiper {
                position: initial;
            }

            .swiper-scrollbar {
                left: 8rem !important;
                width: ~"calc(100% - 8rem)" !important;
            }

            &__arrows {
                position: absolute;
                left: 0;
                top: 0;
                width: 8rem - @spacingS;
                height: 20rem;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;

                @media (max-width: @mobileBreakpoint) {
                    display: none;
                }

                svg {
                    width: 3rem;
                    height: 3rem;
                    color: #1f1f1f;
                    opacity: .5;
                    cursor: pointer;
                }
                svg:first-of-type {
                    .rotate(-180deg);
                }
            }

            &__reference {
                margin-right: @spacing2XL;
                min-width: 23.5rem;
                padding-bottom: @spacing2XL;

                @media (max-width: @mobileBreakpoint) {
                    width: 13.25rem;
                    min-width: 13.25rem;
                    margin-right: @spacing2XL;
                }

                &__up {
                    .fontBarlow();

                    font-size: 3.5rem;
                    font-weight: bold;
                    font-style: italic;
                    line-height: 1.3;
                    color: #1f1f1f;
                    margin-left: 0.5rem;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 2.5rem;
                    }
                }

                &__text {
                    margin-top: @spacingL;
                    font-style: italic;
                    line-height: 1.8;
                    color: @colorDark;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.875rem;
                        margin-top: @spacingM;
                    }
                }

                &__name {
                    .fontBarlow();

                    margin-top: @spacingM;
                    font-size: 1.125rem;
                    font-weight: bold;
                    font-style: italic;
                    line-height: 1.2;
                    letter-spacing: 0.9px;
                    color: #1f1f1f;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.875rem;
                    }
                }

                &__team {
                    margin-top: @spacingXXS;
                    font-size: 0.75rem;
                    line-height: 1.4;
                    color: #1f1f1f;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.625rem;
                    }
                }
            }
            &__reference:last-of-type {
                @media (max-width: @mobileBreakpoint) {
                    margin-right: 0;
                }
            }
        }

        &__logos {
            margin-top: @spacing3XL;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingM;
                flex-direction: column;
            }

            img {
                margin-right: @spacingXL;
                width: auto;
                height: 3rem;
                color: @colorNeutral2;

                @media (max-width: @mobileBreakpoint) {
                    margin-right: 0;
                    margin-bottom: @spacingXL;
                    height: 2rem;
                }
            }
            img:last-of-type {
                margin-right: 0;
            }
        }

        &__logos-text {
            margin-top: @spacingXL;
            line-height: 1.8;
            color: #1f1f1f;
            text-align: center;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingL;
                font-size: 0.875rem;
            }
        }
    }

    &__blog {
        margin-top: @spacing7XL;

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacing4XL;
        }

        &__title {
            .fontBarlow();

            font-size: 3.5rem;
            font-weight: bold;
            font-stretch: condensed;
            font-style: italic;
            color: #1f1f1f;
            text-transform: uppercase;
            text-align: center;

            @media (max-width: @mobileBreakpoint) {
                font-size: 2.5rem;
            }
        }

        .blog {
            margin-top: @spacing3XL;
        }
    }
}
