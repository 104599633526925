@import 'variables';

.results {
    display: flex;
    flex-direction: row;
    width: 100%;

    &__menu {
        margin-right: 10rem;
        width: 13rem;

        @media (max-width: @mobileBreakpoint) {
            width: 100%;
            margin-right: 0;
            margin-top: @spacingXL;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        button {
            margin-bottom: @spacingM;
            display: flex;
            flex-direction: row;
            align-items: center;

            @media (max-width: @mobileBreakpoint) {
                margin-right: @spacingM;
                width: ~"calc((100% - @{spacingM}) / 2)";
                justify-content: flex-start;
            }

            span {
                line-height: 1.8;
                color: #1f1f1f;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                    white-space: nowrap;
                }
            }
            span.count {
                margin-left: @spacingXXS;
                font-weight: 300;
                line-height: 1.19;
                color: #aaa;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                }
            }
        }
        button:nth-child(2n + 2) {
            @media (max-width: @mobileBreakpoint) {
                margin-right: 0;
            }
        }
        button.active {
            span:first-of-type {
                color: @colorPrimary;
                font-weight: 600;
            }
        }
    }

    &__content {
        flex: 1;

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacingM;
        }

        &__input {
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            .input {
                width: 52.25rem;

                @media (max-width: @mobileBreakpoint) {
                    width: ~"calc(100% - @{spacingXXS} - 2rem)";
                }
            }

            svg {
                margin-bottom: 0.8rem;
                width: 2.2rem;
                height: 2.2rem;
                color: @colorNeutral2;
            }
            svg:first-of-type {
                margin-left: @spacingXS;

                @media (max-width: @mobileBreakpoint) {
                    margin-left: @spacingXXS;
                    width: 2rem;
                    height: 2rem;
                    margin-bottom: 0.9rem;
                }
            }
        }

        &__title {
            .fontBarlow();

            padding-top: @spacingL;
            font-size: 2rem;
            font-weight: bold;
            font-stretch: condensed;
            font-style: italic;
            line-height: 1.4;
            letter-spacing: normal;
            text-align: left;
            color: #1f1f1f;
            text-transform: uppercase;

            @media (max-width: @mobileBreakpoint) {
                padding-top: @spacingM;
                font-size: 1.5rem;
                line-height: 1.2;
            }

            span {
                color: @colorNeutral2;
            }
        }

        &__buttons {
            margin-top: @spacingXL;
            display: flex;
            flex-direction: row;
            align-items: center;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingL;
            }

            button {
                .button();

                margin-right: @spacingS;

                @media (max-width: @mobileBreakpoint) {
                    margin-right: @spacingXXS;
                    width: ~"calc((100% - @{spacingXXS}) / 2)";
                    min-width: auto;
                }
            }
            button:last-of-type {
                margin-right: 0;
            }
            button.active {
                box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.16);
                background-color: #1f1f1f;
                color: #fefefe;
            }
        }

        &__data {
            margin-top: @spacing2XL;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingS;
            }

            button.blog__content__item {
                width: ~"calc((100% - @{spacingL} * 2) / 3)";

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                    margin-right: 0;
                }
            }
            button.blog__content__item:nth-child(4n + 4) {
                margin-right: @spacingL;

                @media (max-width: @mobileBreakpoint) {
                    margin-right: 0;
                }
            }
            button.blog__content__item:nth-child(3n + 3) {
                margin-right: 0;
            }
        }
    }
}
