@import 'variables';

.full-admin {
    margin: -@spacingL ~"calc((100vw - 1600px) / -2 - @{spacingXL})" -@spacing4XL ~"calc((100vw - 1600px) / -2 - @{spacingXL})";
    min-height: 100vh;
    background-color: #ecedf0;

    .translate-html-lightbox__tabs div {
        background-color: @colorNeutral2;
    }

    .translate-html-lightbox__buttons {
        display: none;
    }

    .ck-editor {
        width: ~"calc((100vw - 300px) / 2)" !important;
        margin-bottom: @spacingM !important;
    }

    .blogs .ck-editor {
        width: ~"calc(100vw - 380px)" !important;
        margin-bottom: @spacingM !important;
    }

    .input {
        .MuiFilledInput-root {
            border-radius: 0 !important;
            background-color: @colorBaseColor !important;
            border: 1px solid transparent !important;
        }
    }

    .translations {
        .input {
            margin: 0 !important;
            min-width: 400px;

            .input__label {
                display: none;
            }

            .MuiFilledInput-root {
                background-color: @colorNeutral3 !important;
            }
        }
    }

    .orders .edit__panels__panel:last-of-type button {
        width: 100%;
        margin-bottom: @spacingS;
        font-weight: bold;
        color: @colorNeutral2;
        text-decoration: underline;
        text-align: left;
        justify-content: left;
        align-items: flex-start;
    }

    .input__upload {
        position: relative;
        margin-top:@spacingS;
        border-radius: 0.5rem;
        background-color: @colorNeutral3;
        padding: @spacingXL;
        max-width: ~"calc((100vw - 300px) / 2)";

        >span, >svg {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: .5;
        }

        img {
            width: 100%;
        }

        input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
    .input__upload.icon {
        max-width: 60px;
    }

    .select {
        &__container {
            div:first-of-type {
                background-color: @colorBaseColor !important;
            }
        }
    }

    .edit__panels {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__panel {
            flex: 1;
        }
        &__panel:first-of-type {
            margin-right: @spacingXL;
        }
    }

    &__header {
        height: 60px;
        background-color: #ffffff;
        position: fixed;
        left: 0;
        top: 0;
        width: ~"calc(100% - 60px)";
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        z-index:100;

        >img {
            height: 40px;
        }

        &__buttons {
            display: flex;
            flex-direction: row;

            button {
                flex-direction: column;
                align-items: center;

                span {
                    font-size: 0.75rem;
                }
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: row;
        padding-top: 60px;
        min-height: ~"calc(100vh - 60px)";

        &__menu {
            flex: 1;
            min-width: 300px;
            max-width: 300px;
            background-color: #1d2127;
            position: fixed;
            left: 0;
            top: 60px;
            height: ~"calc(100vh - 60px)";
            overflow: scroll;

            &__header {
                height: 50px;
                background-color: #21262d;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-left: 30px;
                margin-bottom: 10px;
                color: #abb4be;

                svg {
                    width: 2rem;
                    height: 2rem;
                }

                span {
                    margin-left: @spacingXS;
                    text-transform: uppercase;
                }
            }

            button {
                display: flex;
                flex-direction: row;
                align-items: center;
                color: #abb4be;
                height: 34px;
                padding: 0 30px 0 50px;
                font-size: 0.875rem;
                width: 100%;

                span {
                    flex: 1;
                    text-align: left;
                }

                svg {
                    width: 1.25rem;
                    height: 1.25rem;
                }
            }
            button.active {
                color: #00cc87;
            }
            button:last-of-type {
                margin-bottom: 60px;
            }
        }

        &__screen {
            padding-left: 300px;
            flex: 1;
            max-width: ~"calc(100vw - 300px)";

            &__header {
                height: 50px;
                background-color: #000;
                color: @colorBaseColor;
                display: flex;
                align-items: center;
                padding: 0 40px;
                font-weight: bold;
            }

            &__content {
                padding: 40px;
                width: ~"calc(100% - 80px)";

                &__header {
                    height: 40px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;

                    button {
                        height: 40px;
                        padding: 0 20px;
                        background-color: @colorGreen;
                        color: @colorBaseColor;
                        border-radius: 4px;
                    }
                    button.back {
                        background-color: @colorNeutral2;
                    }
                }

                table {
                    td:last-of-type {
                        text-align: right;
                    }
                }

                .input, .select, .checkbox {
                    margin-bottom: @spacingM;
                }

                button.save {
                    margin: @spacingL auto 0 auto;
                    width: 100%;
                    height: 50px;
                    padding: 0 20px;
                    background-color: @colorGreen;
                    color: @colorBaseColor;
                    border-radius: 4px;
                }
            }
        }
    }
}

.translate-lightbox {
    width: 800px;

    .input {
        margin-bottom: @spacingM;
    }
}
