.drawer-close {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 100000;
}
.drawer {
  position: fixed;
  right: 0;
  top: 0;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: width 0.25s;
  z-index: 1000000;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 4rem;
  height: calc(100% - 4rem * 2);
}
@media (max-width: 1200px) {
  .drawer {
    padding: 3rem 1.5rem 1.5rem 1.5rem;
    width: calc(100vw - 1.5rem * 2);
    height: calc(100% - 1.5rem * 2);
  }
}
.drawer__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
}
@media (max-width: 1200px) {
  .drawer__header {
    position: fixed;
    left: 0;
    top: 0;
    width: calc(100% - 1.5rem * 2);
    background-color: #fff;
    z-index: 1000001;
    padding: 1.5rem;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  }
}
.drawer__header__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  color: #030303;
  text-transform: uppercase;
}
.drawer__header__button,
.drawer__header button.drawer__header__button {
  width: 3rem;
  height: 3rem;
}
.drawer__header__button svg,
.drawer__header button.drawer__header__button svg {
  width: 2rem;
  height: 2rem;
}
.drawer__content {
  margin-top: 4rem;
}
@media (max-width: 1200px) and (min-width: 450px) {
  .drawer.cart {
    width: calc(80vw - 1.5rem * 2);
  }
}
@media (max-width: 1200px) and (min-width: 450px) {
  .drawer.cart .drawer__header {
    right: 0;
    left: auto;
    width: calc(80vw - 1.5rem * 2);
  }
}
@media (max-width: 1200px) and (min-width: 450px) {
  .drawer.cart .cart-content__down {
    right: 0;
    left: auto;
    width: calc(80vw - 2rem * 2);
  }
}
