@import 'variables';

.shops {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        .fontBarlow();

        font-size: 5rem;
        font-weight: bold;
        font-stretch: condensed;
        font-style: italic;
        line-height: 1.1;
        letter-spacing: normal;
        text-align: center;
        color: #1f1f1f;
        text-transform: uppercase;

        @media (max-width: @mobileBreakpoint) {
            font-size: 3.5rem;
        }
    }

    &__map {
        margin-top: @spacingM;
        min-width: 100%;
        width: 100%;
        height: 46rem;
        background-color: @colorNeutral1;
        border-radius: 1rem;
        overflow: hidden;

        @media (max-width: @mobileBreakpoint) {
            height: 13.375rem;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    &__panel {
        margin-top: @spacing4XL;
        padding-left: 8rem;
        width: ~"calc(100% - 8rem)";

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacingXL;
            padding-left: 0;
            width: 100%;
        }

        &__title {
            .fontBarlow();

            font-size: 2rem;
            font-weight: bold;
            font-stretch: condensed;
            font-style: italic;
            line-height: 1.4;
            letter-spacing: normal;
            text-align: left;
            color: #1f1f1f;
            text-transform: uppercase;

            @media (max-width: @mobileBreakpoint) {
                font-size: 1.5rem;
            }
        }

        &__items {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            &__item, button.shops__panel__items__item {
                display: flex;
                flex-direction: column;
                margin-top: @spacingXL;
                margin-right: @spacingXL;
                width: 14rem;

                @media (max-width: @mobileBreakpoint) {
                    margin-right: @spacingXXS;
                    width: ~"calc((100% - @{spacingXXS}) / 2)";
                }

                &__title {
                    width: 100%;
                    font-weight: 600;
                    line-height: 1.8;
                    color: #1f1f1f;
                    text-align: left;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.875rem;
                    }
                }

                &__text {
                    margin-top: @spacingS;
                    line-height: 1.8;
                    color: #1f1f1f;
                    width: 100%;
                    text-align: left;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.875rem;
                    }
                }
            }
            &__item:nth-child(2n + 2), button.shops__panel__items__item:nth-child(2n + 2) {
                @media (max-width: @mobileBreakpoint) {
                    margin-right: 0;
                }
            }
            &__item.active, button.shops__panel__items__item.active {
                .shops__panel__items__item__title {
                    color: @colorPrimary;
                }
            }
        }
    }
}
