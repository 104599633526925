@import 'variables';

.account {
    display: flex;
    flex-direction: row;

    @media (max-width: @mobileBreakpoint) {
        flex-direction: column;
    }

    .phone {
        display: flex;
        flex-direction: row;
        flex: 1;

        .select {
            width: 7.25rem;
            margin-top: -3px;

            @media (max-width: @mobileBreakpoint) {
                margin-top: 0;
            }

            .select__label {
                opacity: 0;
            }

            .select__container {
                border-bottom-right-radius: 0 !important;
                border-top-right-radius: 0 !important;
            }
        }

        .input {
            .input__label {
                margin-left: -7.25rem;
            }

            .MuiFilledInput-root {
                border-bottom-left-radius: 0 !important;
                border-top-left-radius: 0 !important;
            }
        }
    }

    &__menu {
        margin-right: 8rem;
        width: 16rem;

        @media (max-width: @mobileBreakpoint) {
            margin-right: 0;
            width: 100%;
        }

        &__title {
            .fontBarlow();

            font-weight: bold;
            font-stretch: condensed;
            font-style: italic;
            line-height: 1.1;
            letter-spacing: normal;
            text-align: left;
            color: #1f1f1f;
            text-transform: uppercase;
            display: none;

            @media (max-width: @mobileBreakpoint) {
                display: block;
                font-size: 3.5rem;
            }
        }

        &__panel {
            padding: @spacingS;
            border-radius: 1rem;
            background-color: #f7f7f7;
            display: flex;
            flex-direction: row;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingM;
            }

            &__photo {
                width: 5rem;
                height: 5rem;
                border-radius: 100%;
                background-color: #1f1f1f;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                cursor: pointer;

                &__empty {
                    .fontBarlow();

                    font-size: 2rem;
                    font-weight: bold;
                    font-stretch: condensed;
                    font-style: italic;
                    line-height: 1.4;
                    letter-spacing: normal;
                    text-align: left;
                    color: @colorBaseColor;
                    text-transform: uppercase;
                }

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                }

                &__add {
                    position: absolute;
                    right: 0.25rem;
                    bottom: 0;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 100%;
                    background-color: @colorBaseColor;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        width: 1rem;
                        height: 1rem;
                        color: #1f1f1f;
                    }
                }

                input {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    cursor: pointer;
                }
            }
            &__photo.has-photo {
                background-color: transparent;
            }

            &__credit {
                margin-left: @spacingL;
                display: flex;
                flex-direction: column;
                justify-content: center;

                &__name {
                    font-size: 0.875rem;
                    line-height: 2.06;
                    color: #1f1f1f;
                }

                &__value {
                    margin-top: @spacingXS;
                    font-size: 0.875rem;
                    font-weight: 600;
                    line-height: 1.29;
                    letter-spacing: 0.7px;
                    text-align: left;
                    color: #161616;
                }
            }
        }

        &__buttons {
            margin-top: @spacing2XL;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingXL;
            }

            button {
                margin-bottom: @spacingM;
                display: flex;
                flex-direction: row;
                padding: @spacingXXS;
                border-radius: 0.5rem;
                width: 100%;
                justify-content: flex-start;

                svg {
                    width: 1.5rem;
                    height: 1.5rem;
                    color: #1f1f1f;
                }

                span {
                    margin-left: @spacingM;
                    line-height: 1.8;
                    color: #1f1f1f;
                    text-align: left;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.875rem;
                    }
                }
            }
            button.active, button:hover {
                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
                background-color: @colorBaseColor;

                @media (max-width: @mobileBreakpoint) {
                    box-shadow: none;
                    background-color: transparent;
                }
            }
        }
    }

    &__content {
        flex: 1;

        .account__menu__panel {
            display: none;

            @media (max-width: @mobileBreakpoint) {
                display: flex;
            }
        }

        .account-back {
            display: none;

            @media (max-width: @mobileBreakpoint) {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: @spacingL;

                svg {
                    width: 1.5rem;
                    height: 1.5rem;

                    .rotate(-180deg);
                }

                span {
                    margin-left: @spacingL;
                    font-size: 1rem;
                    line-height: 1.8;
                    color: #1f1f1f;
                }
            }
        }

        &__order {
            width: 62rem;

            @media (max-width: @mobileBreakpoint) {
                width: 100%;
            }

            &__title {
                .fontBarlow();

                font-size: 5rem;
                font-weight: bold;
                font-stretch: condensed;
                font-style: italic;
                line-height: 1.1;
                letter-spacing: normal;
                text-align: left;
                color: #1f1f1f;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 3.5rem;
                }
            }

            &__header {
                margin-top: @spacingXL;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacing2XL;
                    flex-direction: column;
                    align-items: flex-end;
                }

                &__left {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    @media (max-width: @mobileBreakpoint) {
                        justify-content: space-between;
                        width: 100%;
                    }

                    &__text {
                        .fontBarlow();

                        font-size: 1.5rem;
                        font-weight: bold;
                        font-stretch: condensed;
                        font-style: italic;
                        line-height: 1.2;
                        color: #1f1f1f;
                        text-transform: uppercase;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 1.25rem;
                        }
                    }

                    &__number {
                        .fontBarlow();

                        margin-left: @spacing3XL;
                        font-size: 1.5rem;
                        font-weight: bold;
                        font-stretch: condensed;
                        font-style: italic;
                        line-height: 1.2;
                        color: #1f1f1f;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 1.25rem;
                            margin-left: 0;
                        }
                    }
                }

                &__right {
                    button {
                        .button();

                        @media (max-width: @mobileBreakpoint) {
                            margin-top: @spacingL;
                        }
                    }
                }
            }

            &__payment-state {
                height: 2.5rem;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                margin-top: @spacingL;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingXL;
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                }

                &__icon {
                    min-width: 1.5rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    min-height: 1.5rem;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: @spacingS;

                    @media (max-width: @mobileBreakpoint) {
                        min-width: 1rem;
                        width: 1rem;
                        height: 1rem;
                        min-height: 1rem;
                    }

                    svg {
                        width: 1.5rem;
                        height: 1.5rem;
                        color: #fff;

                        @media (max-width: @mobileBreakpoint) {
                            width: 1rem;
                            height: 1rem;
                        }
                    }
                }

                &__text {
                    font-size: 1.5rem;
                    color: #000;
                    line-height: 1.4;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1.25rem;
                        margin-left: @spacingXS;
                        text-align: left;
                        flex: 1;
                    }
                }

                &__button {
                    display: flex;
                    flex-direction: row;
                    margin-top: @spacingXL;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacing2XL;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                    }

                    button:first-of-type {
                        .buttonPrimary();

                        width: 11rem;
                        height: 3rem;
                    }
                    button:last-of-type {
                        .button();

                        margin-left: @spacingS;

                        @media (max-width: @mobileBreakpoint) {
                            margin-left: 0;
                            margin-top: @spacingS;
                        }
                    }
                }
            }
            &__payment-state.success {
                .account__content__order__payment-state__icon {
                    background-color: @colorGreen;
                }

                .account__content__order__payment-state__text {
                    color: @colorGreen;
                }
            }
            &__payment-state.waiting {
                .account__content__order__payment-state__icon {
                    background-color: @colorOrange;

                    svg {
                        width: 1.25rem;
                        height: 1.25rem;

                        @media (max-width: @mobileBreakpoint) {
                            width: 0.75rem;
                            height: 0.75rem;
                        }
                    }
                }

                .account__content__order__payment-state__text {
                    color: @colorOrange;
                }
            }
            &__payment-state.error {
                max-width: 33.75rem;
                height: auto;

                .account__content__order__payment-state__icon {
                    background-color: @colorRed;
                }

                .account__content__order__payment-state__text {
                    color: @colorRed;
                }
            }

            &__transfer {
                margin-top: @spacing2XL;
                padding: @spacing2XL;
                border-radius: 1rem;
                background-color: #f7f7f7;
                display: flex;
                flex-direction: row;

                @media (max-width: @mobileBreakpoint) {
                    margin: @spacing2XL -1.625rem 0 -1.625rem;
                    padding: @spacingXL 1.625rem;
                    width: 100%;
                    border-radius: 0;
                    flex-direction: column;
                }

                &__qrcode {
                    width: 11.25rem;
                    margin-right: @spacing7XL;

                    @media (max-width: @mobileBreakpoint) {
                        width: 100%;
                        margin-right: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    &__title {
                        .fontBarlow();

                        font-size: 1.5rem;
                        font-weight: bold;
                        font-style: italic;
                        color: #1f1f1f;
                        text-transform: uppercase;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 1.25rem;
                        }
                    }

                    img {
                        margin-top: @spacingL;
                        width: 11.25rem;

                        @media (max-width: @mobileBreakpoint) {
                            margin-top: @spacingS;
                            width: 9.8125rem;
                        }
                    }
                }

                &__info {
                    flex: 1;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacing2XL;
                    }

                    &__title {
                        .fontBarlow();

                        font-size: 1.5rem;
                        font-weight: bold;
                        font-style: italic;
                        color: #1f1f1f;
                        text-transform: uppercase;
                        margin-bottom: @spacingL;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 1.25rem;
                            margin-bottom: 0;
                        }
                    }

                    &__item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: @spacingM;

                        @media (max-width: @mobileBreakpoint) {
                            margin-bottom: 0;
                            margin-top: @spacingM;
                        }

                        &__name {
                            font-size: 1rem;
                            color: #1f1f1f;
                            width: 13.4375rem;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                                width: 7rem;
                            }
                        }

                        &__value {
                            font-weight: 500;
                            color: #000;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                                text-align: left;
                            }

                            svg {
                                margin-left: @spacingS;
                                color: @colorNeutral2;
                                cursor: pointer;
                            }
                        }
                    }
                    &__item:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            &__panels {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                &__panel {
                    margin-top: @spacing2XL;
                    margin-right: @spacing7XL;
                    width: ~"calc((100% - @{spacing7XL}) / 2)";

                    @media (max-width: @mobileBreakpoint) {
                        margin-right: @spacingM;
                        width: ~"calc((100% - @{spacingM}) / 2)";
                    }

                    &__value {
                        margin-bottom: @spacingM;
                        display: flex;
                        flex-direction: row;

                        &__name {
                            min-width: 16rem;
                            width: 16rem;
                            line-height: 1.8;
                            color: #1f1f1f;

                            @media (max-width: @mobileBreakpoint) {
                                min-width: 50%;
                                width: 50%;
                                font-size: 0.875rem;
                            }
                        }

                        &__text {
                            font-weight: 600;
                            line-height: 1.8;
                            color: #1f1f1f;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                            }

                            a {
                                margin-left: 0.5rem;
                                font-weight: 600;
                                line-height: 1.8;
                                color: @colorNeutral2;
                                text-decoration: underline;
                            }
                        }
                    }
                    &__value:last-of-type {
                        margin-bottom: 0;
                    }

                    &__buttons {
                        display: flex;
                        flex-direction: column;

                        button:first-of-type {
                            .button();

                            margin-bottom: @spacingM;
                            width: 11rem;
                            height: 3rem;
                            white-space: nowrap;
                        }
                        button:last-of-type {
                            .buttonPrimary();

                            width: 11rem;
                            height: 3rem;
                        }
                    }

                    &__address {
                        &__title {
                            font-size: 0.875rem;
                            font-weight: 600;
                            line-height: 1.29;
                            letter-spacing: 0.7px;
                            color: #161616;
                            text-transform: uppercase;
                        }

                        &__value {
                            margin-top: @spacingM;
                            line-height: 2;
                            text-align: left;
                            color: #1f1f1f;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                                margin-top: @spacingS;
                            }
                        }
                    }
                }
                &__panel:nth-child(2n + 2) {
                    margin-right: 0;
                }
                &__panel:first-of-type {
                    @media (max-width: @mobileBreakpoint) {
                        width: 100%;
                        margin-right: 0;
                    }
                }
                &__panel:nth-child(2) {
                    @media (max-width: @mobileBreakpoint) {
                        display: none;
                    }
                }
            }

            &__products {
                margin-top: @spacing3XL;
                width: 58rem;
                padding-bottom: @spacingXL;

                @media (max-width: @mobileBreakpoint) {
                    width: auto;
                    overflow-x: scroll;
                    margin-top: @spacing2XL;
                }

                &__product {
                    margin-bottom: @spacingL;

                    @media (max-width: @mobileBreakpoint) {
                        width: 40rem;
                    }

                    &__up {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        min-height: 4rem;

                        @media (max-width: @mobileBreakpoint) {
                            min-height: 2.5rem;
                        }

                        &__left {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            &__photo {
                                width: 4rem;
                                height: 4rem;

                                @media (max-width: @mobileBreakpoint) {
                                    width: 2.5rem;
                                    height: 2.5rem;
                                }

                                img {
                                    width: 100%;
                                    mix-blend-mode: multiply;
                                }
                            }
                        }

                        &__info {
                            padding: 0 @spacing2XL;
                            display: flex;
                            flex-direction: column;
                            flex: 1;

                            @media (max-width: @mobileBreakpoint) {
                                padding: 0 @spacingXXS;
                            }

                            &__name {
                                .fontBarlow();

                                font-size: 1.125rem;
                                font-weight: bold;
                                font-style: italic;
                                letter-spacing: 0.9px;
                                color: #1f1f1f;
                                text-transform: uppercase;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.875rem;
                                }
                            }
                        }

                        &__stock {
                            width: 6.25rem;
                            text-align: right;
                            white-space: nowrap;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                                width: 6rem;
                            }
                        }
                        &__stock.instock {
                            color: @colorGreen;
                        }
                        &__stock.order {
                            color: @colorOrange;
                        }

                        &__count {
                            width: 8rem;
                            text-align: right;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                                width: 4rem;
                            }
                        }

                        &__unitprice {
                            width: 9rem;
                            text-align: right;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                                width: 8rem;
                            }
                        }

                        &__price {
                            color: #1f1f1f;
                            font-size: 1rem;
                            text-align: right;
                            width: 9.5rem;
                            font-weight: 600;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                                width: 8rem;
                            }
                        }
                    }
                    &__up.with-extensions {
                        align-items: flex-start;
                    }

                    &__extensions {
                        margin-top: -1.5rem;

                        @media (max-width: @mobileBreakpoint) {
                            margin-top: -0.5rem;
                        }

                        &__extension {
                            margin-top: @spacingXXS;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            padding-left: @spacing2XL + 4rem;

                            @media (max-width: @mobileBreakpoint) {
                                padding-left: @spacingXXS + 2.5rem;
                            }

                            &__name {
                                font-size: 0.75rem;
                                color: #1f1f1f;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.625rem;
                                }

                                svg {
                                    margin-left: 0.25rem;
                                    width: 1rem;
                                    height: 1rem;
                                    color: @colorNeutral2;
                                }
                            }

                            &__price {
                                font-size: 0.75rem;
                                color: #1f1f1f;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.625rem;
                                }
                            }
                        }
                        &__extension:first-of-type {
                            margin-top: 0;
                        }
                    }
                }
                &__product:last-of-type {
                    margin-bottom: 0;
                }
            }

            &__summary {
                margin-top: @spacingXL;
                width: 22.125rem;
                margin-left: 35.875rem;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                border-radius: 1rem;
                background-color: #f7f7f7;
                padding: @spacingL;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                    margin: @spacing3XL -1.625rem -@spacingXL -1.625rem;
                    padding: @spacingL 1.625rem;
                    border-radius: 0;
                }

                &__amounts {
                    width: 100%;

                    &__item {
                        margin-bottom: @spacingS;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        &__name {
                            font-size: 1rem;
                            color: #1f1f1f;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                            }
                        }

                        &__value {
                            font-size: 1rem;
                            color: #1f1f1f;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                            }
                        }
                    }
                    &__item:last-of-type {
                        margin-bottom: 0;
                    }
                }

                &__amount {
                    margin-top: @spacingM;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    &__name {
                        font-size: 1.5rem;
                        color: #000;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 1.25rem;
                        }
                    }

                    &__value {
                        font-size: 1.375rem;
                        color: #000;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 1.25rem;
                        }
                    }
                }

                &__vat {
                    margin-top: @spacingS;
                    width: 100%;

                    &__item {
                        margin-bottom: @spacingXXS;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        &__name {
                            font-size: 0.75rem;
                            color: #1f1f1f;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.625rem;
                            }
                        }

                        &__value {
                            font-size: 0.75rem;
                            color: #1f1f1f;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.625rem;
                            }
                        }
                    }
                    &__item:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &__reviews {
            width: 46rem;

            @media (max-width: @mobileBreakpoint) {
                width: 100%;
            }

            &__title {
                .fontBarlow();

                font-size: 5rem;
                font-weight: bold;
                font-stretch: condensed;
                font-style: italic;
                line-height: 1.1;
                letter-spacing: normal;
                text-align: left;
                color: #1f1f1f;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 3.5rem;
                }
            }

            &__subtitle {
                .fontBarlow();

                padding-top: @spacingXL;
                font-size: 1.5rem;
                font-weight: bold;
                font-stretch: condensed;
                font-style: italic;
                line-height: 1.2;
                color: #1f1f1f;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.25rem;
                    padding-top: @spacing2XL;
                }
            }

            &__table {
                margin-top: @spacingL + @spacingXXS;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingXL;
                    overflow-x: scroll;
                }

                &__line {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;

                    &__cell {
                        flex: 1;
                        padding: @spacingM 0;
                        border-top: 2px solid @colorNeutral2;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                        }
                    }
                    &__cell:nth-child(1) {
                        @media (max-width: @mobileBreakpoint) {
                            min-width: 12rem;
                        }

                        button {
                            color: @colorNeutral2;
                            text-decoration: underline;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                            }
                        }
                    }
                    &__cell:nth-child(2) {
                        width: 8rem;
                        max-width: 8rem;
                        min-width: 8rem;

                        @media (max-width: @mobileBreakpoint) {
                            width: 7.5rem;
                            max-width: 7.5rem;
                            min-width: 7.5rem;
                        }
                    }
                    &__cell:nth-child(3) {
                        width: 8rem;
                        max-width: 8rem;
                        min-width: 8rem;

                        @media (max-width: @mobileBreakpoint) {
                            width: 7.5rem;
                            max-width: 7.5rem;
                            min-width: 7.5rem;
                        }

                        svg {
                            width: 1rem;
                            height: 1rem;
                        }

                        button {
                            color: @colorNeutral2;
                            text-decoration: underline;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                            }
                        }
                    }
                    &__cell:nth-child(4) {
                        width: 14rem;
                        max-width: 14rem;
                        min-width: 14rem;

                        span {
                            line-height: 1.8;
                        }

                        button {
                            margin-top: @spacingS;
                            font-size: 0.75rem;
                            display: block;
                            color: @colorNeutral2;
                            text-decoration: underline;
                        }
                    }
                }
                &__line:first-of-type {
                    .account__content__reviews__table__line__cell {
                        font-weight: 600;
                        border-top: 0;
                    }
                }
            }
        }

        &__orders {
            width: 54rem;

            @media (max-width: @mobileBreakpoint) {
                width: 100%;
            }

            &__title {
                .fontBarlow();

                font-size: 5rem;
                font-weight: bold;
                font-stretch: condensed;
                font-style: italic;
                line-height: 1.1;
                letter-spacing: normal;
                text-align: left;
                color: #1f1f1f;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 3.5rem;
                }
            }

            &__subtitle {
                .fontBarlow();

                padding-top: @spacingXL;
                font-size: 1.5rem;
                font-weight: bold;
                font-stretch: condensed;
                font-style: italic;
                line-height: 1.2;
                color: #1f1f1f;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    padding-top: @spacing2XL;
                    font-size: 1.25rem;
                }
            }

            &__table {
                margin-top: @spacingL + @spacingXXS;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingXL;
                    overflow-x: scroll;
                }

                &__line {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;

                    &__cell {
                        flex: 1;
                        padding: @spacingM 0;
                        border-top: 2px solid @colorNeutral2;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                        }
                    }
                    &__cell:nth-child(1) {
                        @media (max-width: @mobileBreakpoint) {
                            min-width: 10rem;
                        }

                        button {
                            color: @colorNeutral2;
                            text-decoration: underline;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                            }
                        }
                    }
                    &__cell:nth-child(2) {
                        width: 8rem;
                        max-width: 8rem;
                        min-width: 8rem;

                        @media (max-width: @mobileBreakpoint) {
                            width: 7.5rem;
                            max-width: 7.5rem;
                            min-width: 7.5rem;
                        }
                    }
                    &__cell:nth-child(3) {
                        width: 8rem;
                        max-width: 8rem;
                        min-width: 8rem;

                        @media (max-width: @mobileBreakpoint) {
                            width: 7.5rem;
                            max-width: 7.5rem;
                            min-width: 7.5rem;
                        }
                    }
                    &__cell:nth-child(4) {
                        width: 16rem;
                        max-width: 16rem;
                        min-width: 16rem;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        @media (max-width: @mobileBreakpoint) {
                            width: 12rem;
                            max-width: 12rem;
                            min-width: 12rem;
                        }

                        img {
                            margin-right: @spacingXXS;
                            height: 1.5rem;
                            mix-blend-mode: multiply;
                        }

                        a {
                            font-size: 0.75rem;
                            line-height: 1.4;
                            color: @colorNeutral2;
                            text-decoration: underline;
                        }
                    }
                    &__cell:nth-child(5) {
                        width: 8rem;
                        max-width: 8rem;
                        min-width: 8rem;

                        @media (max-width: @mobileBreakpoint) {
                            width: 7.5rem;
                            max-width: 7.5rem;
                            min-width: 7.5rem;
                        }
                    }
                }
                &__line:first-of-type {

                    .account__content__orders__table__line__cell {
                        font-weight: 600;
                        border-top: 0;
                    }
                }
            }
        }

        &__credit {
            width: 46rem;

            @media (max-width: @mobileBreakpoint) {
                width: 100%;
            }

            &__title {
                .fontBarlow();

                font-size: 5rem;
                font-weight: bold;
                font-stretch: condensed;
                font-style: italic;
                line-height: 1.1;
                letter-spacing: normal;
                text-align: left;
                color: #1f1f1f;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 3.5rem;
                }
            }

            &__panel {
                margin-top: @spacingXL;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacing2XL;
                }

                &__title {
                    .fontBarlow();

                    font-size: 1.5rem;
                    font-weight: bold;
                    font-stretch: condensed;
                    font-style: italic;
                    line-height: 1.2;
                    color: #1f1f1f;
                    margin-bottom: @spacingM;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1.25rem;
                        margin-bottom: @spacingS;
                    }
                }

                &__line {
                    margin-bottom: @spacingXS;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    @media (max-width: @mobileBreakpoint) {
                        margin-bottom: @spacingS;
                        justify-content: space-between;
                    }

                    &__name {
                        line-height: 1.8;
                        color: #1f1f1f;
                        width: 16rem;

                        @media (max-width: @mobileBreakpoint) {
                            width: auto;
                            font-size: 0.875rem;
                        }
                    }

                    &__value {
                        font-weight: 600;
                        line-height: 1.8;
                        color: #1f1f1f;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                        }
                    }
                }
            }

            &__table {
                margin-top: @spacingL + @spacingXXS;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingXL;
                    overflow-x: scroll;
                }

                &__line {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;

                    &__cell {
                        flex: 1;
                        padding: @spacingM 0;;
                        border-top: 2px solid @colorNeutral2;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                        }
                    }
                    &__cell:nth-child(1) {
                        width: 8rem;
                        max-width: 8rem;
                        min-width: 8rem;

                        @media (max-width: @mobileBreakpoint) {
                            width: 6rem;
                            max-width: 6rem;
                            min-width: 6rem;
                        }
                    }
                    &__cell:nth-child(2) {
                        @media (max-width: @mobileBreakpoint) {
                            min-width: 10rem;
                        }

                        button {
                            color: @colorNeutral2;
                            text-decoration: underline;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                            }
                        }
                    }
                    &__cell:nth-child(3) {
                        width: 8rem;
                        max-width: 8rem;
                        min-width: 8rem;

                        @media (max-width: @mobileBreakpoint) {
                            width: 7.5rem;
                            max-width: 7.5rem;
                            min-width: 7.5rem;
                        }
                    }
                    &__cell:nth-child(4) {
                        width: 8rem;
                        max-width: 8rem;
                        min-width: 8rem;

                        @media (max-width: @mobileBreakpoint) {
                            width: 7.5rem;
                            max-width: 7.5rem;
                            min-width: 7.5rem;
                        }
                    }
                }
                &__line:first-of-type {

                    .account__content__credit__table__line__cell {
                        font-weight: 600;
                        border-top: 0;
                    }
                }
                .account__content__credit__table__line.credit {
                    svg {
                        color: @colorGreen;
                    }

                    .account__content__credit__table__line__cell:nth-child(3), .account__content__credit__table__line__cell:last-of-type {
                        color: @colorGreen;
                    }
                }
                .account__content__credit__table__line.debet {
                    svg {
                        color: @colorRed;
                    }

                    .account__content__credit__table__line__cell:nth-child(3), .account__content__credit__table__line__cell:last-of-type {
                        color: @colorRed;
                    }
                }
            }
        }

        &__settings {
            width: 46rem;

            @media (max-width: @mobileBreakpoint) {
                width: 100%;
            }

            .input__left {
                top: auto;
                bottom: 0;
            }

            &__title {
                .fontBarlow();

                font-size: 5rem;
                font-weight: bold;
                font-stretch: condensed;
                font-style: italic;
                line-height: 1.1;
                letter-spacing: normal;
                text-align: left;
                color: #1f1f1f;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 3.5rem;
                }
            }

            &__password {
                margin-top: @spacingXL;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacing2XL;
                }

                &__title {
                    .fontBarlow();

                    font-size: 1.5rem;
                    font-weight: bold;
                    font-style: italic;
                    color: @colorDark;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1.25rem;
                    }
                }

                &__data {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-top: @spacingM;

                    @media (max-width: @mobileBreakpoint) {
                        flex-direction: column;
                        align-items: flex-end;
                        margin-top: @spacingS;
                    }

                    &__text {
                        flex: 1;
                        line-height: 1.8;
                        color: #1f1f1f;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                            line-height: 2.06;
                            width: 100%;
                        }
                    }

                    &__email {
                        flex: 1;
                        font-weight: 600;
                        line-height: 1.8;
                        color: #1f1f1f;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                            margin-top: @spacingS;
                            width: 100%;
                        }
                    }

                    button {
                        flex: 1;
                        font-weight: 300;
                        line-height: 1.75;
                        color: @colorNeutral2;
                        text-decoration: underline;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                            line-height: 2;
                            margin-top: @spacingM;
                        }
                    }
                }

                &__settings {
                    margin-top: @spacingM;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingXXS;
                    }

                    >.input {
                        margin-top: @spacingM;
                        width: ~"calc((100% - @{spacingL}) / 2)";

                        @media (max-width: @mobileBreakpoint) {
                            width: 100%;
                        }
                    }
                    >.input:first-of-type {
                        margin-right: 40%;

                        @media (max-width: @mobileBreakpoint) {
                            margin-right: 0;
                        }
                    }
                    >.input:nth-child(2) {
                        margin-right: @spacingL;

                        @media (max-width: @mobileBreakpoint) {
                            margin-right: 0;
                        }
                    }
                }
            }

            &__contact {
                margin-top: @spacingXL;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacing3XL;
                }

                &__title {
                    .fontBarlow();

                    font-size: 1.5rem;
                    font-weight: bold;
                    font-style: italic;
                    color: @colorDark;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1.25rem;
                    }
                }

                &__inputs {
                    margin-top: @spacingM;
                    display: flex;
                    flex-direction: row;

                    @media (max-width: @mobileBreakpoint) {
                        flex-direction: column;
                    }

                    .input {
                        flex: 1;
                    }
                    .input:first-of-type {
                        margin-right: @spacingL;
                        max-width: 21.25rem;

                        @media (max-width: @mobileBreakpoint) {
                            max-width: 100%;
                            margin-right: 0;
                            margin-bottom: @spacingM;
                        }
                    }

                    >.select:first-of-type {
                        margin-right: @spacingL;
                        max-width: 21.25rem;

                        @media (max-width: @mobileBreakpoint) {
                            margin-right: 0;
                            max-width: 100%;
                        }
                    }
                }

                >.input {
                    margin-top: @spacingL;
                    width: ~"calc((100% - @{spacingL}) / 2)";
                }
            }

            &__address {
                margin: @spacingXL 0 @spacingM 0;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacing3XL;
                }

                &__title {
                    .fontBarlow();

                    font-size: 1.5rem;
                    font-weight: bold;
                    font-style: italic;
                    color: @colorDark;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1.25rem;
                    }
                }

                >.checkbox {
                    margin-top: @spacingM;
                }

                &__inputs {
                    margin-top: @spacingM;
                    display: flex;
                    flex-direction: row;

                    @media (max-width: @mobileBreakpoint) {
                        flex-direction: column;
                    }

                    .input {
                        flex: 1;
                    }
                    .input:first-of-type {
                        margin-right: @spacingL;
                        max-width: 21.25rem;

                        @media (max-width: @mobileBreakpoint) {
                            margin-right: 0;
                            max-width: 100%;
                            margin-bottom: @spacingM;
                        }
                    }

                    >.select:first-of-type {
                        margin-right: @spacingL;
                        max-width: 21.25rem;

                        @media (max-width: @mobileBreakpoint) {
                            margin-right: 0;
                            max-width: 100%;
                        }
                    }
                }

                &__manual, button.account__content__settings__address__manual {
                    margin-top: @spacingXXS;
                    margin-bottom: @spacingXXS;
                    font-weight: 300;
                    color: @colorNeutral2;
                    text-decoration: underline;
                    display: block;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.875rem;
                    }
                }

                >.input, >.select {
                    margin-top: @spacingL;
                }
            }

            &__delivery {
                margin-top: @spacingM;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacing2XL;
                }

                &__title {
                    .fontBarlow();

                    font-size: 1.5rem;
                    font-weight: bold;
                    font-style: italic;
                    color: @colorDark;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1.25rem;
                    }
                }

                >.checkbox {
                    margin-top: @spacingM;
                }

                &__inputs {
                    margin-top: @spacingM;
                    display: flex;
                    flex-direction: row;

                    .input {
                        flex: 1;
                    }
                    .input:first-of-type {
                        margin-right: @spacingL;
                        max-width: 21.25rem;
                    }

                    >.select:first-of-type {
                        margin-right: @spacingL;
                        max-width: 21.25rem;
                    }
                }

                &__manual, button.account__content__settings__delivery__manual {
                    margin-top: @spacingXXS;
                    margin-bottom: @spacingXXS;
                    font-weight: 300;
                    color: @colorNeutral2;
                    text-decoration: underline;
                    display: block;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.875rem;
                    }
                }

                >.input, >.select {
                    margin-top: @spacingL;
                }
            }

            &__button, button.account__content__settings__button {
                .buttonPrimary();

                margin-top: @spacingXL;
                width: 100%;
                cursor: pointer;
            }
        }
    }
}
