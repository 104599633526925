nav.sections {
  margin-bottom: 3rem;
}
@media (max-width: 1200px) {
  nav.sections {
    margin-bottom: 0.5rem;
  }
}
nav.sections li,
nav.sections a {
  color: #8b8b8b;
  font-size: 0.75rem;
}
@media (max-width: 1200px) {
  nav.sections li,
  nav.sections a {
    font-size: 0.625rem;
  }
}
