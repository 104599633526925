.newsletter {
  width: 1000px;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .newsletter {
    width: 100%;
    flex-direction: column;
  }
}
.newsletter__left {
  flex: 1;
}
.newsletter__left__text {
  color: #1f1f1f;
  line-height: 1.8;
}
@media (max-width: 1200px) {
  .newsletter__left__text {
    font-size: 0.875rem;
  }
}
.newsletter__left__text span:last-of-type {
  margin-left: 0.25rem;
  font-weight: 600;
}
.newsletter__left__subtext {
  font-size: 0.75rem;
  line-height: 1.4;
  color: #1f1f1f;
  margin-top: 1rem;
}
@media (max-width: 1200px) {
  .newsletter__left__subtext {
    font-size: 0.625rem;
  }
}
.newsletter__left__subtext a {
  margin-left: 0.25rem;
  color: #8b8b8b;
  text-decoration: underline;
}
.newsletter__right {
  margin-left: 10rem;
  width: 21.875rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media (max-width: 1200px) {
  .newsletter__right {
    margin-left: 0;
    margin-top: 3rem;
    width: 100%;
  }
}
.newsletter__right .input {
  width: 100%;
}
.newsletter__right__button,
.newsletter__right button.newsletter__right__button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  margin-top: 1rem;
}
@media (max-width: 1200px) {
  .newsletter__right__button,
  .newsletter__right button.newsletter__right__button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .newsletter__right__button,
  .newsletter__right button.newsletter__right__button {
    white-space: nowrap;
  }
}
