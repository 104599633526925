@import 'variables';

.filter {
    width: 16.25rem;
    margin-right: @spacing4XL;
    height: 100%;

    @media (max-width: @mobileBreakpoint) {
        position: relative;
        top: 0;
        width: 100%;
        margin-right: 0;
        height: auto;
    }

    .products__content__sort {
        display: none;

        @media (max-width: @mobileBreakpoint) {
            display: flex;
            margin-top: 0;
            margin-bottom: @spacing2XL;
            flex-wrap: wrap;
        }

        button {
            @media (max-width: @mobileBreakpoint) {
                margin-right: @spacingXXS;
                margin-bottom: @spacingXXS;
                width: ~"calc((100% - @{spacingXXS}) / 2)";
                min-width: ~"calc((100% - @{spacingXXS}) / 2)" !important;
                max-width: ~"calc((100% - @{spacingXXS}) / 2)" !important;
            }
        }
        button:nth-child(2n + 2) {
            @media (max-width: @mobileBreakpoint) {
                margin-right: 0;
            }
        }
    }

    &__section-title, button.filter__section-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: @spacingS;

        &__name {
            font-size: 0.875rem;
            font-weight: 600;
            letter-spacing: 0.7px;
            color: #161616;
            text-transform: uppercase;
        }

        svg {
            height: 1.125rem;
            color: #1f1f1f;

            .rotate(90deg);
        }
    }
    &__section-title:first-of-type, button.filter__section-title:first-of-type {
        margin-top: 0;
    }
    &__section-title-closed, button.filter__section-title-closed {
        svg {
            .rotate(0deg);
        }
    }

    &__section {
        &__storage {
            margin-bottom: @spacingL;

            .checkbox {
                width: 100%;
            }

            .count {
                font-weight: 300;
                color: #aaa;
            }
        }

        &__categories {
            margin-bottom: @spacingL;

            >div {
                margin-top: @spacingS;
            }
            >div:first-of-type {
                margin-top: 0;
            }

            &__category, button.filter__section__categories__category {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 1.25rem;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                }

                &__name {
                    color: #1f1f1f;
                }

                svg {
                    height: 1.125rem;
                    color: #1f1f1f;

                    .rotate(90deg);
                }
            }

            &__category-closed, button.filter__section__categories__category-closed {
                svg {
                    .rotate(0deg);
                }
            }
            &__category.active, button.filter__section__categories__category.active {
                .filter__section__categories__category__name {
                    color: @colorPrimary;
                    font-weight: bold;
                }
            }

            &__subcategory, button.filter__section__categories__subcategory {
                margin-top: @spacingS;
                padding-left: @spacingL;
                color: @colorNeutral2;
                height: 1.25rem;
                width: 100%;
                justify-content: flex-start;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                }
            }
            &__subcategory.active, button.filter__section__categories__subcategory.active {
                color: @colorPrimary;
                font-weight: bold;
            }
        }

        &__variants {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: -@spacingXS + @spacingL;

            .checkbox {
                margin-right: 0;
                margin-bottom: @spacingXS;
                width: 50%;

                .count {
                    font-weight: 300;
                    color: #aaa;
                }
            }
        }

        &__variables {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: @spacingL;

            .checkbox {
                margin-right: 0;
                margin-bottom: @spacingXS;
                width: 100%;

                .count {
                    font-weight: 300;
                    color: #aaa;
                }
            }
        }

        &__inputs {
            margin-top: @spacingM;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .input {
                width: 42%;
            }

            &__currency {
                font-weight: 300;
                color: #aaa;
            }
        }
    }

    &__buyed {
        margin-top: @spacing2XL;

        @media (max-width: @mobileBreakpoint) {
            padding-bottom: 6.5rem + @spacingL;
        }

        &__title {
            font-size: 0.875rem;
            font-weight: 600;
            letter-spacing: 0.7px;
            color: #161616;
            text-transform: uppercase;
        }
    }

    &__down {
        display: none;

        @media (max-width: @mobileBreakpoint) {
            display: flex;
            margin-top: 0;
            position: fixed;
            left: 0;
            bottom: 0;
            padding: @spacingL;
            width: ~"calc(100% - @{spacingL} * 2)";
            box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.16);
            background-color: #f7f7f7;
        }

        .products__content__sort {
            margin-bottom: 0;
            width: 100%;

            button {
                flex: 1;
            }
            button:last-of-type {
                .buttonPrimary();

                height: 2.5rem;
            }
        }
    }
}
