.menu {
  position: sticky;
  top: 0;
  padding: 5rem 0 2rem 0;
  height: 2.5rem;
  background-color: #fdfdfd;
  z-index: 200;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
}
@media (max-width: 1200px) {
  .menu {
    padding: 1rem 0;
    height: 2rem;
  }
}
.menu__head {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 3rem;
  background-color: #f5f5f5;
}
@media (max-width: 1200px) {
  .menu__head {
    display: none;
  }
}
.menu__head__content {
  margin: auto;
  padding: 0 3rem;
  max-width: calc(1600px - 3rem * 2);
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
}
.menu__head__content__left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menu__head__content__left__phone {
  font-weight: bold;
}
.menu__head__content__left__clock {
  margin-left: 1rem;
}
.menu__head__content__left__shops {
  margin-left: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menu__head__content__left__shops__text {
  font-weight: bold;
  margin-right: 0.5rem;
}
.menu__head__content__left__shops button {
  font-size: 0.875rem;
}
.menu__head__content__left__shops button svg {
  width: 1rem;
  height: 1rem;
}
.menu__head__content__left__shops button:hover span {
  text-decoration: underline;
}
.menu__head__content__right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menu__head__content__right button {
  margin-left: 1.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
}
.menu__head__content__right button svg {
  width: 1rem;
  height: 1rem;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.menu__head__content__right button:hover {
  text-decoration: underline;
}
.menu__content {
  margin: auto;
  padding: 0 3rem;
  max-width: calc(1600px - 3rem * 2);
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1200px) {
  .menu__content {
    padding: 0 1.625rem;
  }
}
.menu__content__left {
  flex-direction: row;
  align-items: center;
  display: none;
}
@media (max-width: 1200px) {
  .menu__content__left {
    display: flex;
  }
}
.menu__content__left__button,
.menu__content__left button.menu__content__left__button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
}
.menu__content__left__button__content,
.menu__content__left button.menu__content__left__button__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.menu__content__left__button__content svg,
.menu__content__left button.menu__content__left__button__content svg {
  margin-top: 0.1875rem;
  margin-bottom: 0.1875rem;
  width: 1.5rem;
  height: 1.5rem;
}
@media (max-width: 1200px) {
  .menu__content__left__button__content svg,
  .menu__content__left button.menu__content__left__button__content svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}
@media (max-width: 1200px) {
  .menu__content__left__button__content span,
  .menu__content__left button.menu__content__left__button__content span {
    font-size: 0.5rem;
  }
}
.menu__content__left__button > span,
.menu__content__left button.menu__content__left__button > span {
  font-size: 0.75rem;
}
.menu__content__logo {
  height: 3.625rem;
  cursor: pointer;
  mix-blend-mode: multiply;
}
@media (max-width: 1200px) {
  .menu__content__logo {
    height: 2rem;
  }
}
.menu__content__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
@media (max-width: 1200px) {
  .menu__content__buttons {
    display: none;
  }
}
.menu__content__buttons button {
  margin-right: 3rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}
.menu__content__buttons button svg {
  color: #8b8b8b;
  height: 1.125rem;
}
.menu__content__buttons button.show-big > svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.menu__content__buttons button:last-of-type {
  margin-right: 0;
}
.menu__content__buttons button.active {
  color: #4030dc;
}
.menu__content__right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menu__content__right__button,
.menu__content__right button.menu__content__right__button {
  margin-right: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
}
@media (max-width: 1200px) {
  .menu__content__right__button,
  .menu__content__right button.menu__content__right__button {
    display: none;
  }
}
.menu__content__right__button__content,
.menu__content__right button.menu__content__right__button__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.menu__content__right__button__content svg,
.menu__content__right button.menu__content__right__button__content svg {
  width: 1.875rem;
  height: 1.875rem;
  color: #8b8b8b;
}
.menu__content__right__button__content span,
.menu__content__right button.menu__content__right__button__content span {
  font-size: 0.75rem;
  color: #8b8b8b;
}
.menu__content__right__button > span,
.menu__content__right button.menu__content__right__button > span {
  font-size: 0.75rem;
}
.menu__content__right__button-currency,
.menu__content__right button.menu__content__right__button-currency {
  background-color: #f5f5f5;
  width: 1.5rem;
  height: 1.5rem;
}
.menu__content__right__button-country,
.menu__content__right button.menu__content__right__button-country {
  background-color: #f5f5f5;
  width: 1.5rem;
  height: 1.5rem;
  overflow: hidden;
  margin-right: 2rem;
}
.menu__content__right__button-country img,
.menu__content__right button.menu__content__right__button-country img {
  width: 1.5rem;
  height: 1.5rem;
}
@media (max-width: 1200px) {
  .menu__content__right__button-cart,
  .menu__content__right button.menu__content__right__button-cart,
  .menu__content__right button.menu__content__right__button-favorites,
  .menu__content__right button.menu__content__right__button-lists,
  .menu__content__right button.menu__content__right__button-compares {
    display: inline-flex;
  }
}
@media (max-width: 1200px) {
  .menu__content__right__button-cart .BaseBadge-badge,
  .menu__content__right button.menu__content__right__button-cart .BaseBadge-badge,
  .menu__content__right button.menu__content__right__button-favorites .BaseBadge-badge,
  .menu__content__right button.menu__content__right__button-lists .BaseBadge-badge,
  .menu__content__right button.menu__content__right__button-compares .BaseBadge-badge {
    font-size: 0.625rem;
  }
}
.menu__content__right__button-cart .menu__content__right__button__content svg,
.menu__content__right button.menu__content__right__button-cart .menu__content__right__button__content svg,
.menu__content__right button.menu__content__right__button-favorites .menu__content__right__button__content svg,
.menu__content__right button.menu__content__right__button-lists .menu__content__right__button__content svg,
.menu__content__right button.menu__content__right__button-compares .menu__content__right__button__content svg {
  margin-top: 0.1875rem;
  margin-bottom: 0.1875rem;
  width: 1.5rem;
  height: 1.5rem;
}
@media (max-width: 1200px) {
  .menu__content__right__button-cart .menu__content__right__button__content svg,
  .menu__content__right button.menu__content__right__button-cart .menu__content__right__button__content svg,
  .menu__content__right button.menu__content__right__button-favorites .menu__content__right__button__content svg,
  .menu__content__right button.menu__content__right__button-lists .menu__content__right__button__content svg,
  .menu__content__right button.menu__content__right__button-compares .menu__content__right__button__content svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}
@media (max-width: 1200px) {
  .menu__content__right__button-cart .menu__content__right__button__content span,
  .menu__content__right button.menu__content__right__button-cart .menu__content__right__button__content span,
  .menu__content__right button.menu__content__right__button-favorites .menu__content__right__button__content span,
  .menu__content__right button.menu__content__right__button-lists .menu__content__right__button__content span,
  .menu__content__right button.menu__content__right__button-compares .menu__content__right__button__content span {
    font-size: 0.5rem;
  }
}
.menu__content__right button.menu__content__right__button-favorites,
.menu__content__right button.menu__content__right__button-lists,
.menu__content__right button.menu__content__right__button-compares {
  margin-right: 1rem;
}
@media (max-width: 1200px) {
  .menu__content__right button.menu__content__right__button-favorites,
  .menu__content__right button.menu__content__right__button-lists,
  .menu__content__right button.menu__content__right__button-compares {
    display: none;
  }
}
.menu__content__right__button:last-of-type,
.menu__content__right button.menu__content__right__button:last-of-type {
  margin-right: 0;
}
.menu__search {
  position: absolute;
  left: 0;
  top: 9.5rem;
  width: 100%;
  min-height: calc(100vh - 2.5rem - 4rem - 3rem);
  background-color: rgba(0, 0, 0, 0.25);
}
.menu__search__window {
  padding: 4rem 0 5.5rem 0;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;
}
.menu__search__window__content {
  margin: auto;
  width: 56rem;
}
.menu__search__window__content__input {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.menu__search__window__content__input div.MuiFilledInput-root {
  background-color: #fdfdfd !important;
}
.menu__search__window__content__input svg {
  margin-bottom: 0.9rem;
  width: 2rem;
  height: 2rem;
  color: #8b8b8b;
}
.menu__search__window__content__input svg:first-of-type {
  margin-left: 0.75rem;
}
.menu__search__window__content__panels {
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
}
.menu__search__window__content__panels__products {
  flex: 1;
}
.menu__search__window__content__panels__products__title {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: 0.7px;
  color: #161616;
  text-transform: uppercase;
}
.menu__search__window__content__panels__products__items {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.menu__search__window__content__panels__products__items__product,
.menu__search__window__content__panels__products__items button.menu__search__window__content__panels__products__items__product {
  margin-bottom: 3rem;
  width: calc(50% - 0.5rem * 2);
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
}
.menu__search__window__content__panels__products__items__product__photo,
.menu__search__window__content__panels__products__items button.menu__search__window__content__panels__products__items__product__photo {
  width: 4rem;
  height: 4rem;
}
.menu__search__window__content__panels__products__items__product__photo img,
.menu__search__window__content__panels__products__items button.menu__search__window__content__panels__products__items__product__photo img {
  width: 100%;
  mix-blend-mode: multiply;
}
.menu__search__window__content__panels__products__items__product__info,
.menu__search__window__content__panels__products__items button.menu__search__window__content__panels__products__items__product__info {
  margin-left: 1rem;
  flex: 1;
}
.menu__search__window__content__panels__products__items__product__info__name,
.menu__search__window__content__panels__products__items button.menu__search__window__content__panels__products__items__product__info__name {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
  color: #1f1f1f;
  text-transform: uppercase;
  text-align: left;
}
.menu__search__window__content__panels__products__items__product__info__price,
.menu__search__window__content__panels__products__items button.menu__search__window__content__panels__products__items__product__info__price {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.29;
  color: #1f1f1f;
  text-align: left;
}
.menu__search__window__content__panels__products__items__product:hover,
.menu__search__window__content__panels__products__items button.menu__search__window__content__panels__products__items__product:hover {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
}
.menu__search__window__content__panels__products__button,
.menu__search__window__content__panels__products button.menu__search__window__content__panels__products__button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
}
@media (max-width: 1200px) {
  .menu__search__window__content__panels__products__button,
  .menu__search__window__content__panels__products button.menu__search__window__content__panels__products__button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
.menu__search__window__content__panels__articles {
  margin-left: 6.5rem;
  width: 15rem;
}
.menu__search__window__content__panels__articles__title {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: 0.7px;
  color: #161616;
  text-transform: uppercase;
}
.menu__search__window__content__panels__articles__article,
.menu__search__window__content__panels__articles button.menu__search__window__content__panels__articles__article {
  margin: 1.5rem -0.5rem 0 -0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.menu__search__window__content__panels__articles__article__category,
.menu__search__window__content__panels__articles button.menu__search__window__content__panels__articles__article__category {
  font-size: 0.75rem;
  line-height: 1.4;
  color: #8b8b8b;
}
.menu__search__window__content__panels__articles__article__name,
.menu__search__window__content__panels__articles button.menu__search__window__content__panels__articles__article__name {
  margin-top: 0.5rem;
  font-weight: 600;
  line-height: 1.8;
  color: #1f1f1f;
}
.menu__search__window__content__panels__articles__article__date,
.menu__search__window__content__panels__articles button.menu__search__window__content__panels__articles__article__date {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  line-height: 1.4;
  color: #1f1f1f;
}
.menu__search__window__content__panels__articles__article:hover,
.menu__search__window__content__panels__articles button.menu__search__window__content__panels__articles__article:hover {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
}
.menu__search__window__content__panels__articles__button,
.menu__search__window__content__panels__articles button.menu__search__window__content__panels__articles__button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  margin-top: 3rem;
}
@media (max-width: 1200px) {
  .menu__search__window__content__panels__articles__button,
  .menu__search__window__content__panels__articles button.menu__search__window__content__panels__articles__button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
.menu__big {
  position: absolute;
  left: 0;
  top: 5.5rem;
  width: 100%;
  min-height: calc(100vh - 2.5rem - 4rem + 1rem);
  display: flex;
  flex-direction: column;
}
@media (max-width: 1200px) {
  .menu__big {
    display: none;
  }
}
.menu__big input {
  opacity: 0;
}
.menu__big__background {
  position: absolute;
  left: 0;
  top: 3rem;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}
.menu__big__window {
  position: relative;
  margin-top: 3rem;
  padding: 4rem 0 5.5rem 0;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;
}
.menu__big__window__content {
  margin: auto;
  padding: 0 3rem;
  max-width: calc(1600px - 3rem * 2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.menu__big__window__content__panel {
  width: 12.8125rem;
}
.menu__big__window__content__panel__title,
.menu__big__window__content__panel button.menu__big__window__content__panel__title {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: 0.7px;
  color: #161616;
  margin-bottom: 2rem;
  text-transform: uppercase;
}
.menu__big__window__content__panel__links button {
  margin-top: 1.5rem;
  font-size: 1rem;
  line-height: 1.58;
  color: #161616;
  display: block;
  white-space: nowrap;
}
.menu__big__window__content__panel__links button:first-of-type {
  margin-top: 0;
}
.menu__big__window__content__panel:last-of-type button {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
}
.menu__big__back {
  flex: 1;
}
.mobile-menu {
  background-color: #f7f7f7;
}
.mobile-menu .input .MuiFilledInput-root {
  background-color: #fdfdfd !important;
}
.mobile-menu .input input {
  font-size: 0.875rem !important;
}
.mobile-menu__categories {
  margin: 1.5rem -1.5rem 3rem -1.5rem;
  padding: 2rem 1.5rem;
  background-color: #fdfdfd;
}
.mobile-menu__categories__category {
  margin-bottom: 2rem;
}
.mobile-menu__categories__category__button,
.mobile-menu__categories__category button.mobile-menu__categories__category__button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.mobile-menu__categories__category__button__name,
.mobile-menu__categories__category button.mobile-menu__categories__category__button__name {
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.29;
  letter-spacing: 0.7px;
  text-align: left;
  color: #161616;
  text-transform: uppercase;
}
.mobile-menu__categories__category__button svg,
.mobile-menu__categories__category button.mobile-menu__categories__category__button svg {
  width: 2rem;
  height: 2rem;
  color: #8b8b8b;
}
.mobile-menu__categories__category__subcategories {
  margin-left: 1.5rem;
}
.mobile-menu__categories__category__subcategories button {
  margin-top: 1.5rem;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.8;
  color: #1f1f1f;
  justify-content: flex-start;
}
.mobile-menu__categories__category:last-of-type {
  margin-bottom: 0;
}
.mobile-menu__section,
.mobile-menu button.mobile-menu__section {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.mobile-menu__section__left,
.mobile-menu button.mobile-menu__section__left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mobile-menu__section__left__icon,
.mobile-menu button.mobile-menu__section__left__icon {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.5rem;
  background-color: #fdfdfd;
  overflow: hidden;
}
.mobile-menu__section__left__icon img,
.mobile-menu button.mobile-menu__section__left__icon img {
  width: 1.5rem;
  height: 1.5rem;
}
.mobile-menu__section__left__icon span,
.mobile-menu button.mobile-menu__section__left__icon span {
  font-size: 0.75rem;
  color: #8b8b8b;
}
.mobile-menu__section__left__name,
.mobile-menu button.mobile-menu__section__left__name {
  margin-left: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.8;
  color: #1f1f1f;
}
.mobile-menu__section > svg,
.mobile-menu button.mobile-menu__section > svg {
  width: 1.5rem;
  height: 1.5rem;
  color: #8b8b8b;
}
.mobile-menu__down {
  margin-top: 7rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.mobile-menu__down button {
  font-size: 0.875rem;
  line-height: 1.8;
  color: #1f1f1f;
}
.menu.menu-checkout {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 0;
  box-shadow: none;
}
.menu.menu-checkout .menu__head {
  display: none;
}
.menu.menu-checkout .menu__content__left {
  display: none;
}
.menu.menu-checkout .menu__content__buttons {
  display: none;
}
.menu.menu-checkout .menu__content__right__button-search,
.menu.menu-checkout .menu__content__right__button-account,
.menu.menu-checkout .menu__content__right__button-cart,
.menu.menu-checkout .menu__content__right__button-favorites,
.menu.menu-checkout .menu__content__right__button-lists,
.menu.menu-checkout .menu__content__right__button-compares {
  display: none;
}
.menu.menu-checkout .menu__content__right__button-country {
  margin-right: 0;
}
@media (max-width: 1200px) {
  .menu.menu-checkout .menu__content__right__button-country {
    display: inline-flex;
  }
}
@media (max-width: 1200px) {
  .menu.menu-checkout .menu__content__right__button-currency {
    display: inline-flex;
  }
}
.pop {
  top: 1.5rem !important;
}
.pop .MuiPopover-paper {
  display: flex;
  flex-direction: column;
}
.pop__item,
.pop button.pop__item {
  width: 6.25rem;
  height: 2.5rem;
  font-size: 0.75rem;
}
.pop__item__icon,
.pop button.pop__item__icon {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 0.5rem;
}
.pop-info {
  top: -1rem !important;
  justify-content: flex-start;
}
.pop-info .pop__item,
.pop-info button.pop__item {
  width: 22rem;
  height: 3.75rem;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  white-space: nowrap;
}
.pop-info .pop__item svg,
.pop-info button.pop__item svg {
  width: 1.25rem;
  height: 1.25rem;
}
.pop-info .pop__item:hover,
.pop-info button.pop__item:hover {
  color: #4030dc;
}
