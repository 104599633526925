.login {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
@media (max-width: 1200px) {
  .login {
    min-height: 86vh;
  }
}
.login__panel {
  width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .login__panel {
    width: 100%;
  }
}
.login__panel__up__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  font-weight: bold;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .login__panel__up__title {
    font-size: 3.5rem;
  }
}
.login__panel__up__text {
  margin-top: 1rem;
  padding-bottom: 2rem;
  font-size: 1rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .login__panel__up__text {
    font-size: 0.875rem;
  }
}
.login__panel__up .input {
  margin-top: 1.5rem;
}
.login__panel__up__lost-password {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.login__panel__up__lost-password__button,
.login__panel__up__lost-password button.login__panel__up__lost-password__button {
  margin-left: 4rem;
  font-weight: 300;
  color: #8b8b8b;
  text-decoration: underline;
}
@media (max-width: 1200px) {
  .login__panel__up__lost-password__button,
  .login__panel__up__lost-password button.login__panel__up__lost-password__button {
    font-size: 0.875rem;
  }
}
.login__panel__up__buttons {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 1200px) {
  .login__panel__up__buttons {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}
.login__panel__up__buttons__button,
.login__panel__up__buttons button.login__panel__up__buttons__button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
}
@media (max-width: 1200px) {
  .login__panel__up__buttons__button,
  .login__panel__up__buttons button.login__panel__up__buttons__button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .login__panel__up__buttons__button,
  .login__panel__up__buttons button.login__panel__up__buttons__button {
    margin-bottom: 0.75rem;
  }
}
.login__panel__down {
  color: #1f1f1f;
}
.login__panel__down button {
  margin-left: 0.25rem;
  font-weight: 300;
  color: #8b8b8b;
  text-decoration: underline;
}
@media (max-width: 1200px) {
  .login__panel__down button {
    font-size: 0.875rem;
  }
}
@media (max-width: 1200px) {
  .login__panel__down {
    font-size: 0.875rem;
    margin-top: 4rem;
  }
}
.login__photo {
  margin-left: 10rem;
  min-width: 46rem;
  width: 46rem;
  height: 46rem;
  background-color: #f5f5f5;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .login__photo {
    display: none;
  }
}
.login__photo img {
  width: 100%;
}
