@import 'variables';

.login {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media (max-width: @mobileBreakpoint) {
        min-height: 86vh;
    }

    &__panel {
        width: 30rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: @mobileBreakpoint) {
            width: 100%;
        }

        &__up {
            &__title {
                .fontBarlow();

                font-size: 5rem;
                font-weight: bold;
                font-style: italic;
                color: #1f1f1f;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 3.5rem;
                }
            }

            &__text {
                margin-top: @spacingS;
                padding-bottom: @spacingL;
                font-size: 1rem;
                color: #1f1f1f;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                }
            }

            .input {
                margin-top: @spacingM;
            }

            &__lost-password {
                margin-top: @spacingXXS;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                &__button, button.login__panel__up__lost-password__button {
                    margin-left: @spacing2XL;
                    font-weight: 300;
                    color: @colorNeutral2;
                    text-decoration: underline;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.875rem;
                    }
                }
            }

            &__buttons {
                margin-top: @spacingM;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                @media (max-width: @mobileBreakpoint) {
                    flex-direction: column-reverse;
                    align-items: flex-end;
                }

                &__button, button.login__panel__up__buttons__button {
                    .button();

                    @media (max-width: @mobileBreakpoint) {
                        margin-bottom: @spacingXS;
                    }
                }
            }
        }

        &__down {
            color: #1f1f1f;

            button {
                margin-left: 0.25rem;
                font-weight: 300;
                color: @colorNeutral2;
                text-decoration: underline;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                }
            }

            @media (max-width: @mobileBreakpoint) {
                font-size: 0.875rem;
                margin-top: @spacing2XL;
            }
        }
    }

    &__photo {
        margin-left: 10rem;
        min-width: 46rem;
        width: 46rem;
        height: 46rem;
        background-color: @colorNeutral1;
        overflow: hidden;

        @media (max-width: @mobileBreakpoint) {
            display: none;
        }

        img {
            width: 100%;
        }
    }
}
