.blog-screen__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1200px) {
  .blog-screen__header {
    align-items: flex-start;
  }
}
.blog-screen__header__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  font-weight: bold;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .blog-screen__header__title {
    font-size: 3.5rem;
  }
}
.blog-screen__header__text {
  margin-top: 1.5rem;
  width: 46rem;
  line-height: 1.8;
  text-align: center;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .blog-screen__header__text {
    margin-top: 2rem;
    font-size: 0.875rem;
    width: 100%;
    text-align: left;
  }
}
.blog-screen__header__categories {
  position: relative;
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 47rem;
}
@media (max-width: 1200px) {
  .blog-screen__header__categories {
    width: auto;
  }
}
.blog-screen__header__categories__category,
.blog-screen__header__categories button.blog-screen__header__categories__category {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  padding: 0;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
@media (max-width: 1200px) {
  .blog-screen__header__categories__category,
  .blog-screen__header__categories button.blog-screen__header__categories__category {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .blog-screen__header__categories__category,
  .blog-screen__header__categories button.blog-screen__header__categories__category {
    width: calc((100% - 1rem) / 2);
    min-width: calc((100% - 1rem) / 2);
  }
}
.blog-screen__header__categories__category:nth-child(4n + 4),
.blog-screen__header__categories button.blog-screen__header__categories__category:nth-child(4n + 4) {
  margin-right: 0;
}
@media (max-width: 1200px) {
  .blog-screen__header__categories__category:nth-child(4n + 4),
  .blog-screen__header__categories button.blog-screen__header__categories__category:nth-child(4n + 4) {
    margin-right: 1rem;
  }
}
@media (max-width: 1200px) {
  .blog-screen__header__categories__category:nth-child(2n + 2),
  .blog-screen__header__categories button.blog-screen__header__categories__category:nth-child(2n + 2) {
    margin-right: 0;
  }
}
.blog-screen__header__categories__category.active,
.blog-screen__header__categories button.blog-screen__header__categories__category.active {
  box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: #1f1f1f;
  color: #fefefe;
}
.blog-screen .blog {
  margin-top: 7rem;
}
