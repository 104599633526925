@import 'variables';

.admin {
    .upload {
        width: 40px;
        height: 40px;
        background-color: @colorNeutral1;
        border-radius: 2px;
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }

        img {
            display: block;
            width: 100%;
        }
    }

    .import {
        position: relative;

        input {
            position: absolute;
            left: 0;
            top: 0;
            width: 40px;
            height: 40px;
            opacity: 0;
            cursor: pointer;
        }
    }

    &__menu {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        box-shadow: none !important;

        button {
            margin-bottom: 4px;
            background-color: @colorNeutral1;
            color: @colorDark;
        }
        button:hover {
            background-color: @colorNeutral1 !important;
        }
        button.active {
            background-color: @colorPrimary;
            color: @colorBaseColor;
        }
        button.active:hover {
            background-color: @colorPrimary !important;
        }
    }

    &__content {
        margin-top: 40px;

        &__edit {
            margin-bottom: 10px;
            min-height: 50px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .inputs {
                flex: 1;
                display: flex;
                flex-direction: column;

                .input {
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .input__label {
                        margin-right: @spacingM;
                    }
                }
                .input:last-of-type {
                    margin-bottom: 0;
                }
            }

            .input {
                flex: 1;

                input {
                    padding: 0 @spacingM;
                    height: 50px;
                    font-size: 14px !important;
                }
            }

            .select .MuiSelect-select {
                flex: 1;
                font-size: 14px !important;
                background-color: @colorBaseColor !important;
            }

            &__buttons {
                display: flex;
                flex-direction: row;

                >button {
                    .button();

                    margin-left: @spacingM;
                    width: 150px;
                }
                >button:last-of-type {
                    .buttonPrimary();

                    margin-left: @spacingM;
                    width: 200px;
                    height: 3rem;
                }
            }
        }

        &__grid {
            min-height: 70vh;
        }
    }
}

.MuiDataGrid-cell:focus {
    outline: none !important;
}

.translate-html-lightbox {
    width: 900px;
    min-height: 600px;

    &__tabs {
        width: 100%;
        background-color: @colorPrimary !important;
        color: @colorBaseColor !important;
        align-items: center;

        .Mui-selected {
            font-weight: bold;
            color: @colorBaseColor !important;
        }

        [role="tablist"] {
            justify-content: space-around;
        }
    }

    &__buttons {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        &__button, button.translate-html-lightbox__buttons__button {
            .buttonPrimary();

            width: 300px;
        }
    }
}

.prices-lightbox {
    width: 400px;
    display: flex;
    flex-direction: column;

    &__price {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: @spacingM;

        .input:first-of-type {
            min-width: 120px;
            max-width: 120px;
            margin-right: @spacingM;
        }

        button {
            width: 40px;
            height: 40px;
        }
    }

    &__add {
        display: flex;
        flex-direction: row;

        .input {
            min-width: 120px;
            max-width: 120px;
            margin-right: @spacingM;
            margin-bottom: 0;
        }

        .button {
            flex: 1;
            height: 100%;
        }
    }

    .input {
        input {
            background-color: @colorBaseColor !important;
        }
    }
}

.company-lightbox {
    width: 400px;

    .input {
        margin-bottom: @spacingM;

        input {
            background-color: @colorBaseColor !important;
        }
    }
}

.address-lightbox {
    width: 600px;

    .input {
        margin-bottom: @spacingM;

        input {
            background-color: @colorBaseColor !important;
        }
    }

    .select {
        margin-top: 2px;
        margin-bottom: 0;

        &__container {
            background-color: @colorBaseColor !important;
            height: 44px;

            div:first-of-type {
                padding-top: @spacingM;
            }
        }
    }

    &__panels {
        display: flex;
        flex-direction: row;

        &__panel {
            flex: 1;
            margin-right: @spacingM * 2;
            display: flex;
            flex-direction: column;
        }
        &__panel:last-of-type {
            margin-right: 0;
        }
    }
}
