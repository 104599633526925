button.filter-button {
  position: fixed;
  right: 0;
  bottom: 3.75rem;
  width: 3.5rem;
  height: 4.625rem;
  display: none;
}
@media (max-width: 1200px) {
  button.filter-button {
    display: flex;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
    background-color: #4030dc;
    z-index: 100;
    color: #fdfdfd;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    flex-direction: column;
  }
  button.filter-button svg {
    width: 2rem;
    height: 2rem;
  }
  button.filter-button div {
    margin-top: 0.5rem;
    font-size: 0.5rem;
  }
}
.products {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
}
@media (max-width: 1200px) {
  .products > .filter {
    display: none;
  }
}
.products__content {
  flex: 1;
  text-align: center;
}
.products__content__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
  font-size: 5rem;
  text-align: left;
}
@media (max-width: 1200px) {
  .products__content__title {
    font-size: 3.5rem;
  }
}
.products__content__text {
  margin-top: 2rem;
  max-width: 37.5rem;
  font-size: 1rem;
  line-height: 1.8;
  color: #1f1f1f;
  text-align: left;
}
@media (max-width: 1200px) {
  .products__content__text {
    font-size: 0.875rem;
  }
}
.products__content__text button {
  display: block;
  color: #8b8b8b;
  text-decoration: underline;
}
@media (max-width: 1200px) {
  .products__content__text button {
    font-size: 0.875rem;
  }
}
.products__content__categories {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.products__content__categories__category,
.products__content__categories button.products__content__categories__category {
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  width: 14.875rem;
  height: 3.5rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fefefe;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1200px) {
  .products__content__categories__category,
  .products__content__categories button.products__content__categories__category {
    margin-right: 0;
    margin-bottom: 0.5rem;
    width: 100%;
  }
}
.products__content__categories__category__info,
.products__content__categories button.products__content__categories__category__info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.products__content__categories__category__info__name,
.products__content__categories button.products__content__categories__category__info__name {
  font-size: 0.875rem;
  font-weight: 600;
  color: #1f1f1f;
  text-align: left;
}
@media (max-width: 1200px) {
  .products__content__categories__category__info__name,
  .products__content__categories button.products__content__categories__category__info__name {
    font-size: 0.75rem;
  }
}
.products__content__categories__category__info__count,
.products__content__categories button.products__content__categories__category__info__count {
  font-size: 0.75rem;
  color: #8b8b8b;
}
.products__content__categories__category img,
.products__content__categories button.products__content__categories__category img {
  width: 2.5rem;
  height: 2.5rem;
}
.products__content__categories__category:hover,
.products__content__categories button.products__content__categories__category:hover {
  box-shadow: none;
}
.products__content__popular {
  margin-top: 4rem;
  padding: 2rem;
  background-color: #f5f5f5;
  border-radius: 1rem;
}
@media (max-width: 1200px) {
  .products__content__popular {
    margin: 3rem -1.625rem 0 -1.625rem;
    padding: 2rem 1.625rem;
  }
}
.products__content__popular__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  text-align: center;
  color: #1f1f1f;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}
.products__content__popular__items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .products__content__popular__items {
    flex-direction: column;
  }
}
.products__content__popular__items__item,
.products__content__popular__items button.products__content__popular__items__item {
  margin-top: 1.5rem;
  width: 42%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .products__content__popular__items__item,
  .products__content__popular__items button.products__content__popular__items__item {
    width: 100%;
    margin-bottom: 1.5rem;
    height: 3rem;
  }
}
.products__content__popular__items__item__left,
.products__content__popular__items button.products__content__popular__items__item__left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.products__content__popular__items__item__left img,
.products__content__popular__items button.products__content__popular__items__item__left img {
  width: 4rem;
  height: 4rem;
  mix-blend-mode: multiply;
}
@media (max-width: 1200px) {
  .products__content__popular__items__item__left img,
  .products__content__popular__items button.products__content__popular__items__item__left img {
    width: 3rem;
    height: 3rem;
  }
}
.products__content__popular__items__item__left__info,
.products__content__popular__items button.products__content__popular__items__item__left__info {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}
.products__content__popular__items__item__left__info__variables,
.products__content__popular__items button.products__content__popular__items__item__left__info__variables {
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  color: #8b8b8b;
  text-align: left;
}
@media (max-width: 1200px) {
  .products__content__popular__items__item__left__info__variables,
  .products__content__popular__items button.products__content__popular__items__item__left__info__variables {
    font-size: 0.5rem;
  }
}
.products__content__popular__items__item__left__info__name,
.products__content__popular__items button.products__content__popular__items__item__left__info__name {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.9px;
  color: #1f1f1f;
  text-align: left;
  text-transform: uppercase;
  max-width: 86%;
}
@media (max-width: 1200px) {
  .products__content__popular__items__item__left__info__name,
  .products__content__popular__items button.products__content__popular__items__item__left__info__name {
    font-size: 0.875rem;
  }
}
.products__content__popular__items__item__amount,
.products__content__popular__items button.products__content__popular__items__item__amount {
  font-size: 0.875rem;
  color: #1f1f1f;
  white-space: nowrap;
}
@media (max-width: 1200px) {
  .products__content__popular__items__item__amount,
  .products__content__popular__items button.products__content__popular__items__item__amount {
    font-size: 0.75rem;
  }
}
.products__content__popular-button,
.products__content button.products__content__popular-button {
  padding: 1rem;
  background-color: #f5f5f5;
  color: #1f1f1f;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
@media (max-width: 1200px) {
  .products__content__popular-button,
  .products__content button.products__content__popular-button {
    font-size: 0.875rem;
  }
}
.products__content__popular-button svg,
.products__content button.products__content__popular-button svg {
  height: 1.125rem;
  color: #1f1f1f;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.products__content button.products__content__popular-button.show svg {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.products__content__sort {
  margin-top: 4rem;
  text-align: left;
}
@media (max-width: 1200px) {
  .products__content__sort {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
  }
}
.products__content__sort button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  margin-right: 1rem;
}
@media (max-width: 1200px) {
  .products__content__sort button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .products__content__sort button {
    flex: 1;
    min-width: 0 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .products__content__sort button svg {
    margin-right: 0.75rem;
    width: 1rem;
    height: 1rem;
  }
}
@media (max-width: 1200px) {
  .products__content__sort button:last-of-type {
    margin-right: 0;
  }
}
.products__content__sort button.active {
  box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: #1f1f1f;
  color: #fefefe;
}
.products__content__filtered {
  margin-top: 1.5rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 1200px) {
  .products__content__filtered {
    margin-top: 1rem;
  }
}
.products__content__filtered > div {
  font-size: 0.75rem;
  background-color: #f5f5f5;
  color: #1f1f1f;
  height: 2rem !important;
  margin-right: 0.75rem;
  border-radius: 0 !important;
}
.products__content__filtered > div span {
  padding-left: 1rem;
  padding-right: 1rem;
}
.products__content__filtered > div svg {
  width: 1rem;
  height: 1rem;
  color: #8b8b8b;
}
.products__content .products-list {
  margin-top: 4rem;
}
@media (max-width: 1200px) {
  .products__content .products-list {
    margin-top: 0;
  }
}
.products__content__empty {
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1200px) {
  .products__content__empty {
    margin-top: 4rem;
  }
}
.products__content__empty__text {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  width: 42rem;
  font-size: 3.5rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.3;
  text-align: center;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .products__content__empty__text {
    width: 100%;
    font-size: 2rem;
    margin-bottom: 4rem;
  }
}
.products__content__empty > button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  margin-top: 5.5rem;
}
@media (max-width: 1200px) {
  .products__content__empty > button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
.products__content__empty__button-text {
  margin-top: 1.5rem;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: center;
  color: #1f1f1f;
}
