.content__screen {
  position: relative;
  margin: auto;
  padding: 2rem 3rem 7rem 3rem;
  max-width: calc(1600px - 3rem * 2);
  min-height: calc(100vh - 160px - 7rem);
}
@media (max-width: 1599px) {
  .content__screen {
    max-width: calc(100% - 3rem * 2);
  }
}
@media (max-width: 1200px) {
  .content__screen {
    padding: 2rem 1.625rem 3rem 1.625rem;
    max-width: calc(100% - 1.625rem * 2);
    min-height: calc(100vh - 4rem - 3rem);
  }
}
