@import 'variables';

.thanks {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: @mobileBreakpoint) {
        align-items: flex-start;
    }

    &__title {
        .fontBarlow();

        padding-top: @spacing2XL;
        font-size: 5rem;
        font-weight: bold;
        font-style: italic;
        color: #1f1f1f;
        text-transform: uppercase;

        @media (max-width: @mobileBreakpoint) {
            font-size: 3.5rem;
            padding-top: 0;
        }
    }

    &__text1 {
        margin-top: @spacingXL;
        color: #1f1f1f;

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacingL;
            font-size: 0.875rem;
        }

        span:nth-child(2) {
            margin: 0 0.25rem;
            font-weight: 600;
        }
    }

    &__text2 {
        margin-top: @spacingS;
        color: #1f1f1f;

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacingM;
            font-size: 0.875rem;
        }

        span:nth-child(2) {
            margin-left: 0.25rem;
            font-weight: 600;
        }
    }

    &__subtext {
        margin-top: @spacingXS;
        font-size: 0.75rem;
        color: #1f1f1f;

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacingS;
            font-size: 0.625rem;
        }
    }

    &__order {
        margin-top: @spacingL;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacingXL;
            align-items: flex-start;
        }

        &__payment-state {
            height: 2.5rem;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            max-width: 52.5rem;
            margin-top: @spacingL;

            @media (max-width: @mobileBreakpoint) {
                margin-top: 0;
                width: 100%;
                max-width: 100%;
                height: auto;
            }

            &__icon {
                min-width: 1.5rem;
                width: 1.5rem;
                height: 1.5rem;
                min-height: 1.5rem;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: @mobileBreakpoint) {
                    min-width: 1rem;
                    width: 1rem;
                    height: 1rem;
                    min-height: 1rem;
                }

                svg {
                    width: 1.5rem;
                    height: 1.5rem;
                    color: #fff;

                    @media (max-width: @mobileBreakpoint) {
                        width: 1rem;
                        height: 1rem;
                    }
                }
            }

            &__text {
                margin-left: @spacingS;
                font-size: 1.5rem;
                color: #000;
                line-height: 1.4;
                text-align: center;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.25rem;
                    margin-left: @spacingXS;
                    text-align: left;
                    flex: 1;
                }
            }

            &__button {
                display: flex;
                flex-direction: row;
                margin-top: @spacingXL;

                @media (max-width: @mobileBreakpoint) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    min-width: 100%;
                }

                button:first-of-type {
                    .buttonPrimary();

                    width: 11rem;
                    height: 3rem;

                    @media (max-width: @mobileBreakpoint) {
                        width: 9.8125rem;
                        max-width: 9.8125rem;
                        height: 2.5rem;
                    }
                }
                button:last-of-type {
                    .button();

                    margin-left: @spacingS;

                    @media (max-width: @mobileBreakpoint) {
                        margin-left: 0;
                        margin-top: @spacingS;
                        width: 12rem;
                    }
                }
            }
        }
        &__payment-state.success {
            .thanks__order__payment-state__icon {
                background-color: @colorGreen;
            }

            .thanks__order__payment-state__text {
                color: @colorGreen;
            }
        }
        &__payment-state.waiting {
            .thanks__order__payment-state__icon {
                background-color: @colorOrange;

                svg {
                    width: 1.25rem;
                    height: 1.25rem;

                    @media (max-width: @mobileBreakpoint) {
                        width: 0.75rem;
                        height: 0.75rem;
                    }
                }
            }

            .thanks__order__payment-state__text {
                color: @colorOrange;
            }
        }
        &__payment-state.error {
            max-width: 33.75rem;
            height: auto;

            .thanks__order__payment-state__icon {
                background-color: @colorRed;
            }

            .thanks__order__payment-state__text {
                color: @colorRed;
            }
        }

        &__transfer {
            margin-top: @spacing2XL;
            padding: @spacing2XL;
            width: ~"calc(1000px - @{spacing2XL} * 2)";
            background-color: #f7f7f7;
            display: flex;
            flex-direction: row;

            @media (max-width: @mobileBreakpoint) {
                margin: @spacing2XL -1.625rem 0 -1.625rem;
                padding: @spacingXL 1.625rem;
                width: 100%;
                border-radius: 0;
                flex-direction: column;
            }

            &__qrcode {
                width: 11.25rem;
                margin-right: @spacing7XL;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                    margin-right: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                &__title {
                    .fontBarlow();

                    font-size: 1.5rem;
                    font-weight: bold;
                    font-style: italic;
                    color: #1f1f1f;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1.25rem;
                    }
                }

                img {
                    margin-top: @spacingL;
                    width: 11.25rem;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingS;
                        width: 9.8125rem;
                    }
                }
            }

            &__info {
                flex: 1;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacing2XL;
                }

                &__title {
                    .fontBarlow();

                    font-size: 1.5rem;
                    font-weight: bold;
                    font-style: italic;
                    color: #1f1f1f;
                    text-transform: uppercase;
                    margin-bottom: @spacingL;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1.25rem;
                        margin-bottom: 0;
                    }
                }

                &__item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: @spacingM;

                    @media (max-width: @mobileBreakpoint) {
                        margin-bottom: 0;
                        margin-top: @spacingM;
                    }

                    &__name {
                        font-size: 1rem;
                        color: #1f1f1f;
                        width: 13.4375rem;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                            width: 7rem;
                        }
                    }

                    &__value {
                        font-weight: 500;
                        color: #000;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                            text-align: left;
                        }

                        svg {
                            margin-left: @spacingS;
                            color: @colorNeutral2;
                            cursor: pointer;
                        }
                    }
                }
                &__item:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        &__account {
            margin-top: @spacing4XL;
            width: 46.25rem;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacing2XL;
                width: 100%;
            }

            &__title {
                .fontBarlow();

                font-size: 2rem;
                font-weight: bold;
                font-style: italic;
                color: @colorDark;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.5rem;
                }
            }

            &__panels {
                margin-top: @spacingL;
                display: flex;
                flex-direction: row;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingM;
                    flex-direction: column;
                }

                &__panel {
                    flex: 1;

                    &__item {
                        margin-bottom: @spacingL;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        svg {
                            margin-right: @spacingS;
                            color: #1f1f1f;
                        }

                        span {
                            color: #1f1f1f;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                            }
                        }
                    }
                    &__item:last-of-type {
                        margin-bottom: 0;
                    }

                    .input {
                        @media (max-width: @mobileBreakpoint) {
                            margin-top: @spacing2XL;
                        }
                    }

                    &__register-text {
                        margin-top: @spacingXXS;
                        font-size: 0.75rem;
                        color: #1f1f1f;

                        a {
                            color: @colorNeutral2;
                            text-decoration: underline;
                        }
                    }

                    &__buttons {
                        margin-top: @spacingM;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;

                        @media (max-width: @mobileBreakpoint) {
                            margin-top: @spacingL;
                        }

                        &__button, button.thanks__order__account__panels__panel__buttons__button {
                            .button();
                        }
                    }
                }
                &__panel:first-of-type {
                    margin-right: @spacingL;

                    @media (max-width: @mobileBreakpoint) {
                        margin-right: 0;
                    }
                }
            }
        }

        &__data {
            margin-top: @spacing3XL;
            padding: @spacing2XL;
            background-color: #f7f7f7;
            width: ~"calc(100% - @{spacing2XL} * 2)";

            @media (max-width: @mobileBreakpoint) {
                margin: @spacing2XL -1.625rem 0 -1.625rem;
                padding: @spacingXL 1.625rem;
                width: 100%;
                border-radius: 0;
            }

            &__title {
                .fontBarlow();

                font-size: 2rem;
                font-weight: bold;
                font-style: italic;
                color: @colorDark;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.5rem;
                }
            }

            &__info {
                margin-top: @spacing2XL;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingM;
                    flex-wrap: wrap;
                }

                &__panel {
                    margin-right: @spacingL;

                    @media (max-width: @mobileBreakpoint) {
                        margin-right: 0;
                    }

                    &__item {
                        margin-bottom: @spacingM;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        &__name {
                            color: #1f1f1f;
                            width: 15.625rem;

                            @media (max-width: @mobileBreakpoint) {
                                width: 50%;
                                font-size: 0.875rem;
                            }
                        }

                        &__value {
                            font-weight: 600;
                            color: #1f1f1f;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                            }
                        }
                    }
                    &__item:last-of-type {
                        margin-bottom: 0;
                    }

                    &__address {
                        &__title {
                            font-size: 0.875rem;
                            font-weight: 600;
                            letter-spacing: 0.7px;
                            color: #161616;
                            text-transform: uppercase;
                            margin-bottom: @spacingM;
                        }

                        &__line {
                            margin-bottom: @spacingS;
                            color: #1f1f1f;
                            line-height: 2;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                            }
                        }
                        &__line:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
                &__panel:first-of-type {
                    flex: 1;

                    @media (max-width: @mobileBreakpoint) {
                        min-width: 100%;
                        width: 100%;
                        margin-bottom: @spacing3XL;
                    }
                }
                &__panel:nth-child(2) {
                    width: 21.875rem;

                    @media (max-width: @mobileBreakpoint) {
                        width: 50%;
                    }
                }
                &__panel:nth-child(3) {
                    width: 21.875rem;
                    margin-right: 0;

                    @media (max-width: @mobileBreakpoint) {
                        width: 50%;
                    }
                }
            }

            &__down {
                margin-top: @spacing2XL;
                display: flex;
                flex-direction: row;

                @media (max-width: @mobileBreakpoint) {
                    flex-direction: column;
                }

                &__products {
                    flex: 1;
                    padding: @spacing2XL;
                    background-color: #fefefe;

                    @media (max-width: @mobileBreakpoint) {
                        padding: @spacingM @spacingS;
                        overflow-x: scroll;
                        border-bottom-right-radius: 0;
                        border-top-right-radius: 0;
                        margin-right: -1.625rem;
                    }

                    &__product {
                        margin-top: @spacingL;

                        @media (max-width: @mobileBreakpoint) {
                            width: 40rem;
                        }

                        &__up {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            min-height: 2.5rem;

                            &__left {
                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                &__photo {
                                    width: 2.5rem;
                                    height: 2.5rem;

                                    img {
                                        width: 100%;
                                        mix-blend-mode: multiply;
                                    }
                                }
                            }

                            &__info {
                                padding: 0 @spacingXL;
                                display: flex;
                                flex-direction: column;
                                flex: 1;

                                @media (max-width: @mobileBreakpoint) {
                                    padding: 0 @spacingXXS;
                                }

                                &__name {
                                    .fontBarlow();

                                    font-size: 1.125rem;
                                    font-weight: bold;
                                    font-style: italic;
                                    letter-spacing: 0.9px;
                                    color: #1f1f1f;
                                    text-transform: uppercase;

                                    @media (max-width: @mobileBreakpoint) {
                                        font-size: 0.875rem;
                                    }
                                }
                            }

                            &__stock {
                                width: 6.25rem;
                                text-align: right;
                                white-space: nowrap;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.875rem;
                                    width: 6rem;
                                }
                            }
                            &__stock.instock {
                                color: @colorGreen;
                            }
                            &__stock.order {
                                color: @colorOrange;
                            }

                            &__count {
                                width: 8rem;
                                text-align: right;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.875rem;
                                    width: 4rem;
                                }
                            }

                            &__unitprice {
                                width: 8rem;
                                text-align: right;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.875rem;
                                    width: 8rem;
                                }
                            }

                            &__price {
                                color: #1f1f1f;
                                font-size: 1rem;
                                text-align: right;
                                width: 9.5rem;
                                font-weight: 600;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.875rem;
                                    width: 8rem;
                                }
                            }
                        }
                        &__up.with-extensions {
                            align-items: flex-start;
                        }

                        &__extensions {
                            &__extension {
                                margin-top: @spacingXXS;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                padding-left: @spacingXL + 2.5rem;

                                @media (max-width: @mobileBreakpoint) {
                                    padding-left: @spacingXXS + 2.5rem;
                                }

                                &__name {
                                    font-size: 0.75rem;
                                    color: #1f1f1f;

                                    @media (max-width: @mobileBreakpoint) {
                                        font-size: 0.625rem;
                                    }

                                    svg {
                                        margin-left: 0.25rem;
                                        width: 1rem;
                                        height: 1rem;
                                        color: @colorNeutral2;
                                    }
                                }

                                &__price {
                                    font-size: 0.75rem;
                                    color: #1f1f1f;

                                    @media (max-width: @mobileBreakpoint) {
                                        font-size: 0.625rem;
                                    }
                                }
                            }
                            &__extension:first-of-type {
                                margin-top: 0;

                                @media (max-width: @mobileBreakpoint) {
                                    margin-top: -@spacingXS;
                                }
                            }
                        }
                    }
                    &__product:first-of-type {
                        margin-top: 0;
                    }
                }

                &__summary {
                    width: 21.875rem;
                    margin-left: @spacingL;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;

                    @media (max-width: @mobileBreakpoint) {
                        width: 100%;
                        margin-left: 0;
                        margin-top: @spacing3XL;
                    }

                    &__amounts {
                        width: 100%;

                        &__item {
                            margin-bottom: @spacingS;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;

                            &__name {
                                font-size: 1rem;
                                color: #1f1f1f;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.875rem;
                                }
                            }

                            &__value {
                                font-size: 1rem;
                                color: #1f1f1f;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.875rem;
                                }
                            }
                        }
                        &__item:last-of-type {
                            margin-bottom: 0;
                        }
                    }

                    &__amount {
                        margin-top: @spacingM;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;

                        &__name {
                            font-size: 1.5rem;
                            color: #000;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 1.25rem;
                            }
                        }

                        &__value {
                            font-size: 1.375rem;
                            color: #000;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 1.25rem;
                            }
                        }
                    }

                    &__vat {
                        margin-top: @spacingS;
                        width: 100%;

                        &__item {
                            margin-bottom: @spacingXXS;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;

                            &__name {
                                font-size: 0.75rem;
                                color: #1f1f1f;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.625rem;
                                }
                            }

                            &__value {
                                font-size: 0.75rem;
                                color: #1f1f1f;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.625rem;
                                }
                            }
                        }
                        &__item:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .newsletter {
        margin-top: @spacing3XL;

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacing2XL;
        }
    }
}
