@import 'variables';

.drawer-close {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.25);
    z-index: 100000;
}

.drawer {
    position: fixed;
    right: 0;
    top: 0;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: width 0.25s;
    z-index: 1000000;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding: @spacing2XL;
    height: ~"calc(100% - @{spacing2XL} * 2)";

    @media (max-width: @mobileBreakpoint) {
        padding: @spacingXL @spacingM @spacingM @spacingM;
        width: ~"calc(100vw - @{spacingM} * 2)";
        height: ~"calc(100% - @{spacingM} * 2)";
    }

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 2.5rem;

        @media (max-width: @mobileBreakpoint) {
            position: fixed;
            left: 0;
            top: 0;
            width: ~"calc(100% - @{spacingM} * 2)";
            background-color: #fff;
            z-index: 1000001;
            padding: @spacingM;
            box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);

        }

        &__title {
            .fontBarlow();

            font-size: 2rem;
            font-weight: bold;
            font-style: italic;
            color: @colorDark;
            text-transform: uppercase;
        }

        &__button, button.drawer__header__button {
            width: 3rem;
            height: 3rem;

            svg {
                width: 2rem;
                height: 2rem;
            }
        }
    }

    &__content {
        margin-top: @spacing2XL;
    }
}
.drawer.cart {
    @media (max-width: @mobileBreakpoint) and (min-width: 450px) {
        width: ~"calc(80vw - @{spacingM} * 2)";
    }

    .drawer__header {
        @media (max-width: @mobileBreakpoint) and (min-width: 450px) {
            right: 0;
            left: auto;
            width: ~"calc(80vw - @{spacingM} * 2)";
        }
    }

    .cart-content__down {
        @media (max-width: @mobileBreakpoint) and (min-width: 450px) {
            right: 0;
            left: auto;
            width: ~"calc(80vw - 2rem * 2)";
        }
    }
}
