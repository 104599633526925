@import 'variables';

.checkbox {
    svg {
        color: @colorNeutral2;
    }

    >span {
        color: #1f1f1f;

        @media (max-width: @mobileBreakpoint) {
            font-size: 0.875rem;
        }
    }
}

.MuiCheckbox-colorPrimary.Mui-checked {
    svg {
        color: @colorPrimary !important;
    }
}
