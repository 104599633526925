@import 'variables';

.register {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media (max-width: @mobileBreakpoint) {
        min-height: 86vh;
    }

    &__panel {
        width: 40rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: @mobileBreakpoint) {
            width: 100%;
        }

        &__up {
            &__title {
                .fontBarlow();

                font-size: 5rem;
                font-weight: bold;
                font-style: italic;
                color: #1f1f1f;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 3.5rem;
                }
            }

            &__text {
                margin-top: @spacingS;
                padding-bottom: @spacingL;
                font-size: 1rem;
                color: #1f1f1f;
                line-height: 1.8;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                }
            }

            &__buttons {
                display: flex;
                flex-direction: row;
                margin-bottom: @spacingL;

                button {
                    .button();
                }
                button.active {
                    .buttonPrimary();

                    height: 3rem;
                }
            }

            .input {
                margin-top: @spacingM;
            }

            .checkbox {
                margin-top: @spacingM;

                >span {
                    font-size: 0.75rem;
                    line-height: 1.4;
                    color: #1f1f1f;

                    button {
                        color: @colorNeutral2;
                        text-decoration: underline;
                        margin: 0 0.25rem;
                        font-size: 0.75rem;
                        line-height: 1.2;
                    }
                }
            }
        }

        &__down {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: @spacingL;

            @media (max-width: @mobileBreakpoint) {
                flex-direction: column-reverse;
                align-items: flex-end;
                margin-top: 0;
            }

            &__login {
                color: #1f1f1f;

                button {
                    margin-left: 0.25rem;
                    font-weight: 300;
                    color: @colorNeutral2;
                    text-decoration: underline;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.875rem;
                    }
                }

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                }
            }

            &__button, button.register__panel__down__button {
                .button();

                @media (max-width: @mobileBreakpoint) {
                    margin-bottom: @spacing2XL;
                }
            }
        }
    }

    &__right {
        margin-left: 10rem;
        min-width: 36rem;
        width: 36rem;

        @media (max-width: @mobileBreakpoint) {
            display: none;
        }

        &__photo {
            height: 20rem;
            background-color: @colorNeutral1;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        &__title {
            .fontBarlow();

            padding-top: @spacingXL;
            padding-bottom: @spacingXL;
            font-size: 2rem;
            font-weight: bold;
            font-style: italic;
            color: #1f1f1f;
            text-transform: uppercase;
        }

        &__panel {
            flex: 1;

            &__item {
                margin-bottom: @spacingL;
                display: flex;
                flex-direction: row;
                align-items: center;

                svg {
                    margin-right: @spacingS;
                    color: @colorGreen;
                }

                span {
                    color: #1f1f1f;
                }
            }
            &__item:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
