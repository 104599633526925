.blog {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blog__content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 1200px) {
  .blog__content {
    flex-direction: column;
  }
}
.blog__content__item,
.blog__content button.blog__content__item {
  width: calc((100% - 2rem * 3) / 4);
  margin-right: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 37.5rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .blog__content__item,
  .blog__content button.blog__content__item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5.5rem;
    height: auto;
  }
}
.blog__content__item__photo,
.blog__content button.blog__content__item__photo {
  height: 16.5rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .blog__content__item__photo,
  .blog__content button.blog__content__item__photo {
    height: auto;
  }
}
.blog__content__item__photo img,
.blog__content button.blog__content__item__photo img {
  height: 100%;
}
@media (max-width: 1200px) {
  .blog__content__item__photo img,
  .blog__content button.blog__content__item__photo img {
    width: 100%;
    height: auto;
  }
}
.blog__content__item__content,
.blog__content button.blog__content__item__content {
  padding: 1.5rem 1rem;
  flex: 1;
  width: calc(100% - 1rem * 2);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
@media (max-width: 1200px) {
  .blog__content__item__content,
  .blog__content button.blog__content__item__content {
    padding: 0;
    width: 100%;
  }
}
.blog__content__item__content__up,
.blog__content button.blog__content__item__content__up {
  flex: 1;
  margin-bottom: 3rem;
  text-align: left;
}
.blog__content__item__content__up__categories,
.blog__content button.blog__content__item__content__up__categories {
  font-size: 0.75rem;
  line-height: 1.4;
  color: #8b8b8b;
}
@media (max-width: 1200px) {
  .blog__content__item__content__up__categories,
  .blog__content button.blog__content__item__content__up__categories {
    font-size: 0.625rem;
    margin-top: 1.5rem;
  }
}
.blog__content__item__content__up__name,
.blog__content button.blog__content__item__content__up__name {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-top: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .blog__content__item__content__up__name,
  .blog__content button.blog__content__item__content__up__name {
    font-size: 1.25rem;
  }
}
.blog__content__item__content__up__text,
.blog__content button.blog__content__item__content__up__text {
  margin-top: 2rem;
  line-height: 1.8;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .blog__content__item__content__up__text,
  .blog__content button.blog__content__item__content__up__text {
    font-size: 0.875rem;
  }
}
.blog__content__item__content__button,
.blog__content button.blog__content__item__content__button {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.blog__content__item__content__button svg,
.blog__content button.blog__content__item__content__button svg {
  width: 2rem;
  height: 2rem;
  color: #8b8b8b;
}
.blog__content__item__content__button span,
.blog__content button.blog__content__item__content__button span {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-left: 0.75rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
  letter-spacing: 0.8px;
  color: #8b8b8b;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .blog__content__item__content__button span,
  .blog__content button.blog__content__item__content__button span {
    font-size: 0.875rem;
  }
}
.blog__content__item:hover {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
}
.blog__content__item:nth-child(4n + 4),
.blog__content button.blog__content__item:nth-child(4n + 4) {
  margin-right: 0;
}
.blog__button,
.blog button.blog__button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  margin-top: 4rem;
}
@media (max-width: 1200px) {
  .blog__button,
  .blog button.blog__button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .blog__button,
  .blog button.blog__button {
    margin-top: 0;
  }
}
