@import 'variables';

.buy {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        .fontBarlow();

        font-size: 5rem;
        font-weight: bold;
        font-stretch: condensed;
        font-style: italic;
        line-height: 1.1;
        letter-spacing: normal;
        text-align: center;
        color: #1f1f1f;
        text-transform: uppercase;

        @media (max-width: @mobileBreakpoint) {
            font-size: 3.5rem;
        }
    }

    &__text {
        margin-top: @spacing4XL;
        line-height: 1.6;
        font-size: 1rem;

        @media (max-width: @mobileBreakpoint) {
            font-size: 0.75rem;

            margin-top: @spacing2XL;
        }
    }
}
