.select {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.select__label {
  margin-bottom: 0.5rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .select__label {
    font-size: 0.875rem;
  }
}
.select__container {
  border-radius: 0 !important;
  overflow: hidden;
}
.select__container div:first-of-type {
  background-color: #f7f7f7;
  padding: 1rem;
  height: 1.5rem;
}
.select__container:before {
  border-bottom: 0 !important;
}
.select__container:after {
  border-bottom: 0 !important;
}
.select__flag {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2rem;
  padding: 0 !important;
}
.select__flag img {
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.select__flag span {
  margin-left: 0.5rem;
  font-size: 1rem;
}
.select.disabled {
  opacity: 0.5;
}
