@import 'variables';

.footer {
    background-color: #1f1f1f;

    &__content {
        margin: auto;
        padding: 0 @spacingXL;
        max-width: ~"calc(1600px - @{spacingXL} * 2)";

        @media (max-width: @mobileBreakpoint) {
            padding: 0 1.625rem;
        }

        &__main {
            padding: @spacing4XL 0;
            border-bottom: 1px solid #fefefe;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @media (max-width: @mobileBreakpoint) {
                padding: @spacingXL 0;
                flex-direction: column;
            }

            &__panel {
                display: flex;
                flex-direction: column;

                &__logo {
                    width: 4.125rem;
                    height: 1.5rem;
                    cursor: pointer;

                    @media (max-width: @mobileBreakpoint) {
                        width: 2.75rem;
                        height: 1rem;
                    }
                }

                &__logo-text {
                    margin-top: @spacingXL;
                    text-transform: uppercase;
                    color: @colorBaseColor;
                    line-height: 1.6;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingS;
                        font-size: 0.875rem;
                        white-space: nowrap;
                    }
                }

                &__section {
                    .fontBarlow();

                    color: @colorBaseColor;
                    font-size: 1.125rem;
                    font-weight: bold;
                    font-stretch: condensed;
                    font-style: italic;
                    letter-spacing: 0.9px;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                    }
                }

                a, button {
                    margin-top: @spacingS;
                    font-size: 0.875rem;
                    color: #fefefe;
                    justify-content: flex-start;
                    text-align: left;
                }
                a:first-of-type {
                    margin-top: @spacingL;
                }
            }
            &__panel:first-of-type {
                width: 8.75rem;
                max-width: 8.75rem;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: @spacingXL;
                }
            }
            &__panel:nth-child(2) {
                @media (max-width: @mobileBreakpoint) {
                    margin-bottom: @spacingM;
                    cursor: pointer;
                }

                .footer__content__main__panel__section {
                    @media (max-width: @mobileBreakpoint) {
                        .font();

                        font-size: 0.875rem;
                        font-weight: 600;
                    }
                }

                a, button {
                    @media (max-width: @mobileBreakpoint) {
                        display: none;
                    }
                }
            }
            &__panel:nth-child(2).show {
                .footer__content__main__panel__section {
                    svg {
                        .rotate(90deg);
                    }
                }

                a, button {
                    @media (max-width: @mobileBreakpoint) {
                        display: block;
                    }
                }
            }
            &__panel:nth-child(3) {
                @media (max-width: @mobileBreakpoint) {
                    cursor: pointer;
                }

                .footer__content__main__panel__section {
                    @media (max-width: @mobileBreakpoint) {
                        .font();

                        font-size: 0.875rem;
                        font-weight: 600;
                    }
                }

                a, button {
                    @media (max-width: @mobileBreakpoint) {
                        display: none;
                    }
                }
            }
            &__panel:nth-child(3).show {
                .footer__content__main__panel__section {
                    svg {
                        .rotate(90deg);
                    }
                }

                a, button {
                    @media (max-width: @mobileBreakpoint) {
                        display: block;
                    }
                }
            }
            &__panel:nth-child(4) {
                @media (max-width: @mobileBreakpoint) {
                    flex-direction: row;
                    justify-content: space-between;
                    padding: @spacingXL 0 @spacing2XL 0;
                    border-top: 1px solid #fefefe;
                    margin-top: @spacingXL;
                }

                a, button {
                    @media (max-width: @mobileBreakpoint) {
                        margin-top: 0;
                    }
                }

                .footer__content__main__panel__section {
                    @media (max-width: @mobileBreakpoint) {
                        display: none;
                    }
                }
            }
            &__panel:last-of-type {
                display: flex;
                flex-direction: column;

                @media (max-width: @mobileBreakpoint) {
                    flex-direction: row;
                    justify-content: space-between;
                }

                a {
                    margin-top: 0;
                }

                img {
                    margin-bottom: @spacingM;
                    height: 1.5rem;
                    cursor: pointer;

                    @media (max-width: @mobileBreakpoint) {
                        margin-bottom: 0;
                        height: 2rem;
                    }
                }
                a:last-of-type img {
                    margin-bottom: 0;
                }
            }
        }

        &__down {
            padding: @spacingL 0 @spacing2XL 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @media (max-width: @mobileBreakpoint) {
                padding-top: @spacingM;
            }

            &__left {
                display: flex;
                flex-direction: row;
                align-items: center;

                @media (max-width: @mobileBreakpoint) {
                    display: none;
                }

                img {
                    margin-right: @spacingS;
                    height: 1.25rem;
                }
                img[alt="visa"], img[alt="mollie"], img[alt="paypal"], img[alt="dhl"], img[alt="fedex"], img[alt="gls"] {
                    height: 1rem;
                }
                img[alt="mastercard"], img[alt="gopay"], img[alt="ups"], img[alt="zasilkovna"] {
                    height: 1.875rem;
                }
                svg:last-of-type {
                    margin-right: 0;
                }
            }

            &__right {
                font-size: 0.75rem;
                color: @colorBaseColor;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                    font-size: 0.625rem;
                    text-align: center;
                }
            }
        }
    }
}

.footer.footer-checkout {
    @media (min-width: @mobileBreakpoint) {
        .footer__content__main__panel__logo-text {
            display: none;
        }

        .footer__content__main__panel:nth-child(3) {
            .footer__content__main__panel__section {
                display: none;
            }

            a:nth-child(2), button:nth-child(4) {
                display: none;
            }
        }

        .footer__content__main__panel:nth-child(2), .footer__content__main__panel:nth-child(4), .footer__content__main__panel:nth-child(5) {
            display: none;
        }

        .footer__content__main {
            border-bottom: 0;
            padding: @spacingL 0 @spacingXL 0;
        }

        .footer__content__down {
            display: none;
        }
    }
}
