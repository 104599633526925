@import 'variables';

.delivery {
    margin: 0 @spacing6XL - @spacingXL 0 @spacing6XL - @spacingXL;
    padding-top: @spacingM;

    @media (max-width: @mobileBreakpoint) {
        margin: 0;
        padding-top: @spacingS;
    }

    &__title {
        .fontBarlow();

        font-size: 5rem;
        font-weight: bold;
        font-style: italic;
        color: #1f1f1f;
        text-transform: uppercase;

        @media (max-width: @mobileBreakpoint) {
            font-size: 3.5rem;
        }
    }

    &__text {
        margin-top: @spacingS;
        font-size: 0.875rem;
        color: #1f1f1f;
        text-transform: uppercase;

        @media (max-width: @mobileBreakpoint) {
            font-size: 0.75rem;
        }
    }

    >.select {
        margin-top: @spacingXL;
        width: 37.5rem;

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacing2XL;
            width: 100%;
        }
    }
}
