.input {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #1f1f1f !important;
}
.input .MuiFilledInput-root {
  border-radius: 0 !important;
  background-color: #f7f7f7 !important;
  border: 1px solid transparent !important;
}
.input .MuiFilledInput-root:not(.MuiInputBase-multiline) {
  max-height: 3.5rem;
}
.input .MuiFilledInput-root:before,
.input .MuiFilledInput-root:after {
  border-bottom: 0 !important;
}
.input__error {
  font-size: 0.75rem;
  color: #c24646;
  margin-top: 0.5rem;
}
.input__left {
  position: absolute;
  left: 0.75rem;
  top: 0.2rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  z-index: 100;
}
.input__left svg {
  width: 2.5rem;
  height: 2.5rem;
  color: #8b8b8b;
}
.input__right {
  position: absolute;
  right: 1rem;
  top: 1.625rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
}
.input__right-password {
  position: absolute;
  right: 1rem;
  bottom: 0.35rem;
}
.input__right-password svg {
  width: 1.5rem;
  height: 1.5rem;
}
.input .valid-icon {
  color: #409b04;
}
.input .error-icon {
  color: #c24646;
}
.input__label {
  height: 1.125rem;
  margin-bottom: 0.5rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .input__label {
    font-size: 0.875rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.input__label span {
  font-size: 0.75rem;
  color: #1f1f1f;
}
.input input {
  padding: 1rem;
  height: 1.5rem;
}
@media (max-width: 1200px) {
  .input input {
    font-size: 0.875rem;
  }
}
.input .Mui-error {
  border: 1px solid #c24646;
}
.input.disabled {
  opacity: 0.5;
}
.input.right input {
  padding-right: 3rem !important;
}
.input.left input {
  padding-left: 4rem !important;
}
