.change-delivery {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media (max-width: 1200px) {
  .change-delivery {
    flex-direction: column;
    align-items: center;
  }
}
.change-delivery__button,
.change-delivery button.change-delivery__button {
  position: relative;
  margin-right: 2rem;
  width: 10rem;
  height: 10rem;
  border-radius: 1rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
}
@media (max-width: 1200px) {
  .change-delivery__button,
  .change-delivery button.change-delivery__button {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
.change-delivery__button > svg,
.change-delivery button.change-delivery__button > svg {
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  width: 1.25rem;
  height: 1.25rem;
  color: #4030dc;
  opacity: 0;
}
.change-delivery__button img,
.change-delivery button.change-delivery__button img {
  margin-top: -3rem;
  height: 3.5rem;
}
.change-delivery__button__icon,
.change-delivery button.change-delivery__button__icon {
  margin-top: -3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.change-delivery__button__icon svg,
.change-delivery button.change-delivery__button__icon svg {
  margin-bottom: 0.5rem;
  color: #8b8b8b;
  width: 2.5rem;
  height: 2.5rem;
}
.change-delivery__button__icon span,
.change-delivery button.change-delivery__button__icon span {
  text-align: center;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.08;
  color: #000;
}
.change-delivery__button__price,
.change-delivery button.change-delivery__button__price {
  color: #8b8b8b;
  position: absolute;
  margin: auto;
  bottom: 2rem;
  left: 0;
  right: 0;
}
.change-delivery__button:last-of-type,
.change-delivery button.change-delivery__button:last-of-type {
  margin-right: 0;
}
.change-delivery__button.active,
.change-delivery button.change-delivery__button.active {
  border-color: #4030dc;
}
.change-delivery__button.active > svg,
.change-delivery button.change-delivery__button.active > svg {
  opacity: 1;
}
