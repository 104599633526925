@import 'variables';

.blog {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__content {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media (max-width: @mobileBreakpoint) {
            flex-direction: column;
        }

        &__item, button.blog__content__item {
            width: ~"calc((100% - @{spacingL} * 3) / 4)";
            margin-right: @spacingL;
            margin-bottom: @spacingL;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 37.5rem;
            overflow: hidden;

            @media (max-width: @mobileBreakpoint) {
                width: 100%;
                margin-right: 0;
                margin-bottom: @spacing3XL;
                height: auto;
            }

            &__photo {
                height: 16.5rem;
                overflow: hidden;

                @media (max-width: @mobileBreakpoint) {
                    height: auto;
                }

                img {
                    height: 100%;

                    @media (max-width: @mobileBreakpoint) {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            &__content {
                padding: @spacingM @spacingS;
                flex: 1;
                width: ~"calc(100% - @{spacingS} * 2)";
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                @media (max-width: @mobileBreakpoint) {
                    padding: 0;
                    width: 100%;
                }

                &__up {
                    flex: 1;
                    margin-bottom: @spacingXL;
                    text-align: left;

                    &__categories {
                        font-size: 0.75rem;
                        line-height: 1.4;
                        color: @colorNeutral2;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.625rem;
                            margin-top: @spacingM;
                        }
                    }

                    &__name {
                        .fontBarlow();

                        margin-top: @spacingXXS;
                        font-size: 1.5rem;
                        font-weight: bold;
                        font-style: italic;
                        line-height: 1.2;
                        color: #1f1f1f;
                        text-transform: uppercase;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 1.25rem;
                        }
                    }

                    &__text {
                        margin-top: @spacingL;
                        line-height: 1.8;
                        color: #1f1f1f;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                        }
                    }
                }

                &__button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    svg {
                        width: 2rem;
                        height: 2rem;
                        color: @colorNeutral2;
                    }

                    span {
                        .fontBarlow();

                        margin-left: @spacingXS;
                        font-weight: bold;
                        font-style: italic;
                        line-height: 1.2;
                        letter-spacing: 0.8px;
                        color: @colorNeutral2;
                        text-transform: uppercase;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                        }
                    }
                }
            }
        }
        &__item:hover {
            box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
        }
        &__item:nth-child(4n + 4), button.blog__content__item:nth-child(4n + 4) {
            margin-right: 0;
        }
    }

    &__button, button.blog__button {
        .button();

        margin-top: @spacing2XL;

        @media (max-width: @mobileBreakpoint) {
            margin-top: 0;
        }
    }
}
