@import 'variables';

nav.sections {
    margin-bottom: @spacingXL;

    @media (max-width: @mobileBreakpoint) {
        margin-bottom: @spacingXXS;
    }

    li, a {
        color: @colorNeutral2;
        font-size: 0.75rem;

        @media (max-width: @mobileBreakpoint) {
            font-size: 0.625rem;
        }
    }
}
