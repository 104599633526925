@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@300;400;500;600;700&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,400;0,700;1,400;1,700&display=swap&subset=latin-ext');

body {
    padding: 0;
    margin: 0;
    background-color: @colorBaseColor;
    color: @colorDark;

    .font();
}

h1, h2, h3 {
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    margin-inline-start: 0 !important;
    margin-inline-end: 0 !important;
}

html {
    font-size: 16px;

    @media (max-width: 1550px) {
        font-size: 1550px / 1600px * 16px;
    }

    @media (max-width: 1500px) {
        font-size: 1500px / 1600px * 16px;
    }

    @media (max-width: 1450px) {
        font-size: 1450px / 1600px * 16px;
    }

    @media (max-width: 1400px) {
        font-size: 1400px / 1600px * 16px;
    }

    @media (max-width: 1350px) {
        font-size: 1350px / 1600px * 16px;
    }

    @media (max-width: 1300px) {
        font-size: 1300px / 1600px * 16px;
    }

    @media (max-width: 1250px) {
        font-size: 1250px / 1600px * 16px;
    }

    @media (max-width: @mobileBreakpoint) {
        font-size: 16px;
    }
}

button {
    .font();
}

a {
    text-decoration: none;
}

#screen-loading {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
}

button[disabled] {
    background-color: @colorNeutral3 !important;
    box-shadow: none;
}

.c-green {
    color: @colorGreen !important;
}
.c-red {
    color: @colorRed !important;
}

b {
    font-weight: 600;
}

.snackbar {
    .snackbar-content {
        box-shadow: none !important;
    }

    .snackbar-message {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        padding: 0 @spacingL;

        svg {
            margin-right: 10px;
        }
    }
}
.snackbar.success {
    .snackbar-content {
        background-color: @colorGreen;
    }
}
.snackbar.error {
    .snackbar-content {
        background-color: @colorRed;
    }
}

.MuiDialog-paperScrollPaper {
    max-width: 100rem !important;
}

.MuiSnackbar-root {
    .MuiPaper-root {
        padding: 0 !important;
        height: 52px;

        .MuiSnackbarContent-message {
            padding: 0 !important;
        }
    }
}

.swiper-scrollbar {
    background-color: transparent;
    border-top: 2px solid rgba(31,31,31,0.5);
    border-radius: 0;
}

.swiper-scrollbar-drag {
    margin-top: -5px;
    background-color: @colorNeutral2;
    height: 8px;
}
