.popular-products {
  width: 100%;
}
.popular-products__title {
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.7px;
  color: #161616;
  text-transform: uppercase;
}
.popular-products__product,
.popular-products button.popular-products__product {
  margin-top: 1rem;
  margin-left: -0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  width: calc(100% - 0.5rem * 2);
}
.popular-products__product__photo,
.popular-products button.popular-products__product__photo {
  width: 4rem;
  height: 4rem;
}
.popular-products__product__photo img,
.popular-products button.popular-products__product__photo img {
  width: 100%;
  mix-blend-mode: multiply;
}
.popular-products__product__info,
.popular-products button.popular-products__product__info {
  margin-left: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 3.125rem;
}
.popular-products__product__info__name,
.popular-products button.popular-products__product__info__name {
  font-weight: 600;
  color: #1f1f1f;
  text-align: left;
}
@media (max-width: 1200px) {
  .popular-products__product__info__name,
  .popular-products button.popular-products__product__info__name {
    font-size: 0.875rem;
  }
}
.popular-products__product__info__price,
.popular-products button.popular-products__product__info__price {
  color: #1f1f1f;
  text-align: left;
  font-size: 0.875rem;
}
.popular-products__product:first-of-type,
.popular-products button.popular-products__product:first-of-type {
  margin-top: 1.5rem;
}
.popular-products__product:hover,
.popular-products button.popular-products__product:hover {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
}
