.story {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.story__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: center;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .story__title {
    font-size: 3.5rem;
  }
}
.story__text {
  margin-top: 7rem;
  line-height: 2;
  font-size: 1.25rem;
}
@media (max-width: 1200px) {
  .story__text {
    font-size: 0.75rem;
    margin-top: 4rem;
  }
}
