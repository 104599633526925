@import 'variables';

.newsletter {
    width: 1000px;
    display: flex;
    flex-direction: row;

    @media (max-width: @mobileBreakpoint) {
        width: 100%;
        flex-direction: column;
    }

    &__left {
        flex: 1;

        &__text {
            color: #1f1f1f;
            line-height: 1.8;

            @media (max-width: @mobileBreakpoint) {
                font-size: 0.875rem;
            }

            span:last-of-type {
                margin-left: 0.25rem;
                font-weight: 600;
            }
        }

        &__subtext {
            font-size: 0.75rem;
            line-height: 1.4;
            color: #1f1f1f;
            margin-top: @spacingS;

            @media (max-width: @mobileBreakpoint) {
                font-size: 0.625rem;
            }

            a {
                margin-left: 0.25rem;
                color: @colorNeutral2;
                text-decoration: underline;
            }
        }
    }

    &__right {
        margin-left: 10rem;
        width: 21.875rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media (max-width: @mobileBreakpoint) {
            margin-left: 0;
            margin-top: @spacingXL;
            width: 100%;
        }

        .input {
            width: 100%;
        }

        &__button, button.newsletter__right__button {
            .button();

            margin-top: @spacingS;

            @media (max-width: @mobileBreakpoint) {
                white-space: nowrap;
            }
        }
    }
}
