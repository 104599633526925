@import 'variables';

.popular-products {
    width: 100%;

    &__title {
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: 0.7px;
        color: #161616;
        text-transform: uppercase;
    }

    &__product, button.popular-products__product {
        margin-top: @spacingS;
        margin-left: -@spacingXXS;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: @spacingXXS;
        width: ~"calc(100% - @{spacingXXS} * 2)";

        &__photo {
            width: 4rem;
            height: 4rem;

            img {
                width: 100%;
                mix-blend-mode: multiply;
            }
        }

        &__info {
            margin-left: @spacingXS;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 3.125rem;

            &__name {
                font-weight: 600;
                color: #1f1f1f;
                text-align: left;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                }
            }

            &__price {
                color: #1f1f1f;
                text-align: left;
                font-size: 0.875rem;
            }
        }
    }
    &__product:first-of-type, button.popular-products__product:first-of-type {
        margin-top: @spacingM;
    }
    &__product:hover, button.popular-products__product:hover {
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    }
}
