.footer {
  background-color: #1f1f1f;
}
.footer__content {
  margin: auto;
  padding: 0 3rem;
  max-width: calc(1600px - 3rem * 2);
}
@media (max-width: 1200px) {
  .footer__content {
    padding: 0 1.625rem;
  }
}
.footer__content__main {
  padding: 7rem 0;
  border-bottom: 1px solid #fefefe;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .footer__content__main {
    padding: 3rem 0;
    flex-direction: column;
  }
}
.footer__content__main__panel {
  display: flex;
  flex-direction: column;
}
.footer__content__main__panel__logo {
  width: 4.125rem;
  height: 1.5rem;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .footer__content__main__panel__logo {
    width: 2.75rem;
    height: 1rem;
  }
}
.footer__content__main__panel__logo-text {
  margin-top: 3rem;
  text-transform: uppercase;
  color: #fdfdfd;
  line-height: 1.6;
}
@media (max-width: 1200px) {
  .footer__content__main__panel__logo-text {
    margin-top: 1rem;
    font-size: 0.875rem;
    white-space: nowrap;
  }
}
.footer__content__main__panel__section {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  color: #fdfdfd;
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .footer__content__main__panel__section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.footer__content__main__panel a,
.footer__content__main__panel button {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #fefefe;
  justify-content: flex-start;
  text-align: left;
}
.footer__content__main__panel a:first-of-type {
  margin-top: 2rem;
}
.footer__content__main__panel:first-of-type {
  width: 8.75rem;
  max-width: 8.75rem;
}
@media (max-width: 1200px) {
  .footer__content__main__panel:first-of-type {
    width: 100%;
    max-width: 100%;
    margin-bottom: 3rem;
  }
}
@media (max-width: 1200px) {
  .footer__content__main__panel:nth-child(2) {
    margin-bottom: 1.5rem;
    cursor: pointer;
  }
}
@media (max-width: 1200px) {
  .footer__content__main__panel:nth-child(2) .footer__content__main__panel__section {
    font-family: 'Red Hat Text', sans-serif !important;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    font-weight: 600;
  }
}
@media (max-width: 1200px) {
  .footer__content__main__panel:nth-child(2) a,
  .footer__content__main__panel:nth-child(2) button {
    display: none;
  }
}
.footer__content__main__panel:nth-child(2).show .footer__content__main__panel__section svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
@media (max-width: 1200px) {
  .footer__content__main__panel:nth-child(2).show a,
  .footer__content__main__panel:nth-child(2).show button {
    display: block;
  }
}
@media (max-width: 1200px) {
  .footer__content__main__panel:nth-child(3) {
    cursor: pointer;
  }
}
@media (max-width: 1200px) {
  .footer__content__main__panel:nth-child(3) .footer__content__main__panel__section {
    font-family: 'Red Hat Text', sans-serif !important;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    font-weight: 600;
  }
}
@media (max-width: 1200px) {
  .footer__content__main__panel:nth-child(3) a,
  .footer__content__main__panel:nth-child(3) button {
    display: none;
  }
}
.footer__content__main__panel:nth-child(3).show .footer__content__main__panel__section svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
@media (max-width: 1200px) {
  .footer__content__main__panel:nth-child(3).show a,
  .footer__content__main__panel:nth-child(3).show button {
    display: block;
  }
}
@media (max-width: 1200px) {
  .footer__content__main__panel:nth-child(4) {
    flex-direction: row;
    justify-content: space-between;
    padding: 3rem 0 4rem 0;
    border-top: 1px solid #fefefe;
    margin-top: 3rem;
  }
}
@media (max-width: 1200px) {
  .footer__content__main__panel:nth-child(4) a,
  .footer__content__main__panel:nth-child(4) button {
    margin-top: 0;
  }
}
@media (max-width: 1200px) {
  .footer__content__main__panel:nth-child(4) .footer__content__main__panel__section {
    display: none;
  }
}
.footer__content__main__panel:last-of-type {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1200px) {
  .footer__content__main__panel:last-of-type {
    flex-direction: row;
    justify-content: space-between;
  }
}
.footer__content__main__panel:last-of-type a {
  margin-top: 0;
}
.footer__content__main__panel:last-of-type img {
  margin-bottom: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .footer__content__main__panel:last-of-type img {
    margin-bottom: 0;
    height: 2rem;
  }
}
.footer__content__main__panel:last-of-type a:last-of-type img {
  margin-bottom: 0;
}
.footer__content__down {
  padding: 2rem 0 4rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1200px) {
  .footer__content__down {
    padding-top: 1.5rem;
  }
}
.footer__content__down__left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1200px) {
  .footer__content__down__left {
    display: none;
  }
}
.footer__content__down__left img {
  margin-right: 1rem;
  height: 1.25rem;
}
.footer__content__down__left img[alt="visa"],
.footer__content__down__left img[alt="mollie"],
.footer__content__down__left img[alt="paypal"],
.footer__content__down__left img[alt="dhl"],
.footer__content__down__left img[alt="fedex"],
.footer__content__down__left img[alt="gls"] {
  height: 1rem;
}
.footer__content__down__left img[alt="mastercard"],
.footer__content__down__left img[alt="gopay"],
.footer__content__down__left img[alt="ups"],
.footer__content__down__left img[alt="zasilkovna"] {
  height: 1.875rem;
}
.footer__content__down__left svg:last-of-type {
  margin-right: 0;
}
.footer__content__down__right {
  font-size: 0.75rem;
  color: #fdfdfd;
}
@media (max-width: 1200px) {
  .footer__content__down__right {
    width: 100%;
    font-size: 0.625rem;
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .footer.footer-checkout .footer__content__main__panel__logo-text {
    display: none;
  }
  .footer.footer-checkout .footer__content__main__panel:nth-child(3) .footer__content__main__panel__section {
    display: none;
  }
  .footer.footer-checkout .footer__content__main__panel:nth-child(3) a:nth-child(2),
  .footer.footer-checkout .footer__content__main__panel:nth-child(3) button:nth-child(4) {
    display: none;
  }
  .footer.footer-checkout .footer__content__main__panel:nth-child(2),
  .footer.footer-checkout .footer__content__main__panel:nth-child(4),
  .footer.footer-checkout .footer__content__main__panel:nth-child(5) {
    display: none;
  }
  .footer.footer-checkout .footer__content__main {
    border-bottom: 0;
    padding: 2rem 0 3rem 0;
  }
  .footer.footer-checkout .footer__content__down {
    display: none;
  }
}
