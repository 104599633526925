@import 'variables';

.select {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    &__label {
        margin-bottom: @spacingXXS;
        color: #1f1f1f;

        @media (max-width: @mobileBreakpoint) {
            font-size: 0.875rem;
        }
    }

    &__container {
        border-radius: 0 !important;
        overflow: hidden;

        div:first-of-type {
            background-color: #f7f7f7;
            padding: @spacingS;
            height: 3.5rem - @spacingS * 2;
        }
    }
    &__container:before {
        border-bottom: 0 !important;
    }
    &__container:after {
        border-bottom: 0 !important;
    }

    &__flag {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 2rem;
        padding: 0 !important;

        img {
            width: 1.5rem !important;
            height: 1.5rem !important;
        }

        span {
            margin-left: @spacingXXS;
            font-size: 1rem;
        }
    }
}
.select.disabled {
    opacity: .5;
}
