@import 'variables';

.input {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #1f1f1f !important;

    .MuiFilledInput-root {
        border-radius: 0 !important;
        background-color: #f7f7f7 !important;
        border: 1px solid transparent !important;
    }

    .MuiFilledInput-root:not(.MuiInputBase-multiline) {
        max-height: 3.5rem;
    }

    .MuiFilledInput-root:before, .MuiFilledInput-root:after {
        border-bottom: 0 !important;
    }

    &__error {
        font-size: 0.75rem;
        color: @colorRed;
        margin-top: @spacingXXS;
    }

    &__left {
        position: absolute;
        left: @spacingXS;
        top: 0.2rem;
        height: 3.5rem;
        display: flex;
        align-items: center;
        z-index: 100;

        svg {
            width: 2.5rem;
            height: 2.5rem;
            color: @colorNeutral2;
        }
    }

    &__right {
        position: absolute;
        right: @spacingS;
        top: 1.125rem + @spacingXXS;
        height: 3.5rem;
        display: flex;
        align-items: center;
    }

    &__right-password {
        position: absolute;
        right: @spacingS;
        bottom: 0.35rem;

        svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    .valid-icon {
        color: @colorGreen;
    }

    .error-icon {
        color: @colorRed;
    }

    &__label {
        height: 1.125rem;
        margin-bottom: @spacingXXS;
        color: #1f1f1f;

        @media (max-width: @mobileBreakpoint) {
            font-size: 0.875rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        span {
            font-size: 0.75rem;
            color: #1f1f1f;
        }
    }

    input {
        padding: @spacingS;
        height: 3.5rem - @spacingS * 2;

        @media (max-width: @mobileBreakpoint) {
            font-size: 0.875rem;
        }
    }

    .Mui-error {
        border: 1px solid @colorRed;
    }
}
.input.disabled {
    opacity: .5;
}
.input.right {
    input {
        padding-right: @spacingXL !important;
    }
}
.input.left {
    input {
        padding-left: @spacing2XL !important;
    }
}
