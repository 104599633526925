.lightbox {
  border-radius: 0 !important;
}
@media (max-width: 1200px) {
  .lightbox {
    margin: 0 !important;
  }
}
.lightbox > h2.MuiDialogTitle-root {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 2rem 2rem 0 2rem;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.4;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .lightbox > h2.MuiDialogTitle-root {
    font-size: 1.5rem;
    padding-left: 1.625rem;
    padding-right: 1.625rem;
    text-align: left !important;
  }
}
.lightbox > div.MuiDialogContent-root {
  padding: 2rem 2rem 1rem 2rem;
}
@media (max-width: 1200px) {
  .lightbox > div.MuiDialogContent-root {
    padding-left: 1.625rem;
    padding-right: 1.625rem;
  }
}
