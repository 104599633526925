@import 'variables';

.favorites-content {
    width: 43.75rem;

    @media (max-width: @mobileBreakpoint) {
        width: 100%;
    }

    &__products {
        &__product {
            margin-bottom: @spacingL;

            @media (max-width: @mobileBreakpoint) {
                margin-bottom: @spacing2XL;
            }

            &__up {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                min-height: 4rem;
                width: 100%;

                &__left {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    button {
                        margin-right: @spacingM;
                        width: 1.25rem;
                        height: 1.25rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @media (max-width: @mobileBreakpoint) {
                            margin-right: @spacingXS;
                        }

                        svg {
                            width: 1.25rem;
                            height: 1.25rem;
                            color: @colorNeutral2;
                        }
                    }

                    &__photo {
                        width: 4rem;
                        height: 4rem;

                        @media (max-width: @mobileBreakpoint) {
                            width: 3rem;
                            height: 3rem;
                        }

                        img {
                            width: 100%;
                            mix-blend-mode: multiply;
                        }
                    }
                }

                &__right {
                    display: flex;
                    flex-direction: row;
                    flex: 1;

                    @media (max-width: @mobileBreakpoint) {
                        flex-wrap: wrap;
                        margin-left: @spacingL;
                    }

                    &__info {
                        padding: 0 @spacingXL;
                        display: flex;
                        flex-direction: column;
                        flex: 1;

                        @media (max-width: @mobileBreakpoint) {
                            padding: 0;
                            min-width: 100%;
                        }

                        &__name {
                            .fontBarlow();

                            font-size: 1.125rem;
                            font-weight: bold;
                            font-style: italic;
                            letter-spacing: 0.9px;
                            color: #1f1f1f;
                            text-transform: uppercase;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                                margin-bottom: @spacingM;
                            }
                        }
                    }

                    &__counter {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-top: -0.5rem;

                        @media (max-width: @mobileBreakpoint) {
                            margin-top: -@spacingXXS;
                        }

                        &__button, button.favorites-content__products__product__up__right__counter__button {
                            width: 2.25rem;
                            height: 2.25rem;
                            border-radius: 100%;

                            svg {
                                width: 1.125rem;
                                height: 1.125rem;
                                color: @colorNeutral2;
                            }
                        }
                        &__button:hover, button.favorites-content__products__product__up__right__counter__button:hover {
                            background-color: @colorNeutral1;
                        }

                        &__value {
                            min-width: 1.875rem;
                            width: 1.875rem;
                            max-width: 1.875rem;
                            font-size: 1rem;
                            text-align: center;
                            color: #1f1f1f;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                                width: 1rem;
                                min-width: 1rem;
                                max-width: 1rem;
                            }
                        }
                    }

                    &__price {
                        font-weight: 500;
                        color: #000;
                        width: 8.75rem;
                        text-align: right;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                            width: auto;
                            flex: 1;
                        }
                    }
                }
            }
            &__up.with-extensions {
                align-items: flex-start;
            }

            &__extensions {
                margin-top: -1.875rem;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingXS;
                }

                &__extension {
                    margin-top: @spacingXXS;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-left: @spacingXL + @spacingM + 4rem + 1.25rem;

                    @media (max-width: @mobileBreakpoint) {
                        padding-left: @spacingXXS + @spacingM + 4rem + 1.25rem;
                    }

                    &__name {
                        font-size: 0.75rem;
                        color: #1f1f1f;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.625rem;
                        }

                        svg {
                            margin-left: 0.25rem;
                            width: 1rem;
                            height: 1rem;
                            color: @colorNeutral2;
                        }
                    }

                    &__price {
                        font-size: 0.75rem;
                        color: #1f1f1f;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.625rem;
                        }
                    }
                }
                &__extension:first-of-type {
                    margin-top: 0;
                }
            }
        }
        &__product:last-of-type {
            margin-bottom: 0;
        }
    }

    &__down {
        @media (max-width: @mobileBreakpoint) {
            display: block;
            margin-top: 0;
            position: fixed;
            left: 0;
            bottom: 0;
            padding: @spacingL;
            width: ~"calc(100% - @{spacingL} * 2)";
            box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.16);
            background-color: #f7f7f7;
        }

        &__price {
            margin-top: @spacing3XL;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @media (max-width: @mobileBreakpoint) {
                margin-top: 0;
            }

            &__text {
                font-size: 1.5rem;
                color: #000;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.25rem;
                }
            }

            &__amount {
                font-size: 1.5rem;
                color: #000;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.25rem;
                }
            }
        }

        &__button, button.favorites-content__down__button {
            .buttonPrimary();

            margin-top: @spacingL;
            width: 100%;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingXL;
            }
        }
    }
}
