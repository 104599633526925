.products-list {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.products-list__content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -2rem;
}
@media (max-width: 1200px) {
  .products-list__content {
    margin-top: 0;
  }
}
.products-list__content__item,
.products-list__content button.products-list__content__item {
  width: calc((100% - 2rem * 2) / 3);
  margin-right: 2rem;
  margin-top: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
}
@media (max-width: 1200px) {
  .products-list__content__item,
  .products-list__content button.products-list__content__item {
    margin-top: 3rem;
    margin-right: 0.5rem;
    padding: 0.5rem;
    width: calc((100% - 0.5rem) / 2);
  }
}
.products-list__content__item__flags,
.products-list__content button.products-list__content__item__flags {
  position: absolute;
  left: 0;
  top: 0;
}
.products-list__content__item__flags__flag,
.products-list__content button.products-list__content__item__flags__flag {
  padding: 0.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #fdfdfd;
  font-weight: bold;
}
@media (max-width: 1200px) {
  .products-list__content__item__flags__flag,
  .products-list__content button.products-list__content__item__flags__flag {
    font-size: 0.625rem;
  }
}
.products-list__content__item__variants,
.products-list__content button.products-list__content__item__variants {
  position: absolute;
  right: 2rem;
  top: 2rem;
  display: none;
}
.products-list__content__item__variants__variant,
.products-list__content button.products-list__content__item__variants__variant,
.products-list__content__item__variants button.product__right__variants__items__variant,
.products-list__content button.products-list__content__item__variants button.product__right__variants__items__variant {
  width: 2rem;
  height: 2rem;
  border: solid 1px #409b04;
  font-size: 0.75rem;
  color: #409b04;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.25rem !important;
}
.products-list__content__item__variants__variant[disabled],
.products-list__content button.products-list__content__item__variants__variant[disabled],
.products-list__content__item__variants button.product__right__variants__items__variant[disabled],
.products-list__content button.products-list__content__item__variants button.product__right__variants__items__variant[disabled] {
  border-color: #f7f7f7;
  color: #e1e1e1;
  background: linear-gradient(to top right, #f7f7f7 0%, #f7f7f7 calc(50% - 0.8px), #e1e1e1 50%, #f7f7f7 calc(50% + 0.8px), #f7f7f7 100%);
}
.products-list__content__item__variants__variant.order,
.products-list__content button.products-list__content__item__variants__variant.order,
.products-list__content__item__variants button.product__right__variants__items__variant.order,
.products-list__content button.products-list__content__item__variants button.product__right__variants__items__variant.order {
  border-color: #f39a33;
  color: #f39a33;
}
.products-list__content__item__variants__variant.instock,
.products-list__content button.products-list__content__item__variants__variant.instock,
.products-list__content__item__variants button.product__right__variants__items__variant.instock,
.products-list__content button.products-list__content__item__variants button.product__right__variants__items__variant.instock {
  border-color: #409b04;
  color: #409b04;
}
.products-list__content__item__variants__variant:last-of-type,
.products-list__content button.products-list__content__item__variants__variant:last-of-type,
.products-list__content__item__variants button.product__right__variants__items__variant:last-of-type,
.products-list__content button.products-list__content__item__variants button.product__right__variants__items__variant:last-of-type {
  margin-bottom: 0;
}
.products-list__content__item img,
.products-list__content button.products-list__content__item img {
  width: 100%;
  mix-blend-mode: multiply;
}
.products-list__content__item__info,
.products-list__content button.products-list__content__item__info {
  margin-top: 2rem;
  width: 100%;
  height: 4.375rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .products-list__content__item__info,
  .products-list__content button.products-list__content__item__info {
    flex-direction: column;
    height: 5rem;
    justify-content: flex-start;
  }
}
.products-list__content__item__info__left__name,
.products-list__content button.products-list__content__item__info__left__name {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
  letter-spacing: 0.9px;
  color: #1f1f1f;
  text-transform: uppercase;
  text-align: left;
}
@media (max-width: 1200px) {
  .products-list__content__item__info__left__name,
  .products-list__content button.products-list__content__item__info__left__name {
    font-size: 0.875rem;
  }
}
.products-list__content__item__info__left__variables,
.products-list__content button.products-list__content__item__info__left__variables {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  text-align: left;
  color: #8b8b8b;
}
@media (max-width: 1200px) {
  .products-list__content__item__info__left__variables,
  .products-list__content button.products-list__content__item__info__left__variables {
    font-size: 0.5rem;
  }
}
.products-list__content__item__info__right__price,
.products-list__content button.products-list__content__item__info__right__price {
  color: #1f1f1f;
  white-space: nowrap;
  text-align: right;
}
@media (max-width: 1200px) {
  .products-list__content__item__info__right__price,
  .products-list__content button.products-list__content__item__info__right__price {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    margin-left: 0;
    flex: 1;
    text-align: left;
  }
}
.products-list__content__item__info__right__price-vat,
.products-list__content button.products-list__content__item__info__right__price-vat {
  margin-top: 0.5rem;
  color: #8b8b8b;
  white-space: nowrap;
  text-align: right;
  font-size: 0.75rem;
}
@media (max-width: 1200px) {
  .products-list__content__item__info__right__price-vat,
  .products-list__content button.products-list__content__item__info__right__price-vat {
    margin-top: 0.25rem;
    font-size: 0.75rem;
    margin-left: 0;
    flex: 1;
    text-align: left;
  }
}
.products-list__content__item:hover {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
}
.products-list__content__item:hover .products-list__content__item__variants {
  display: block;
}
@media (max-width: 1200px) {
  .products-list__content__item:hover .products-list__content__item__variants {
    display: none;
  }
}
.products-list__content__item:nth-child(3n + 3),
.products-list__content button.products-list__content__item:nth-child(3n + 3) {
  margin-right: 0;
}
@media (max-width: 1200px) {
  .products-list__content__item:nth-child(3n + 3),
  .products-list__content button.products-list__content__item:nth-child(3n + 3) {
    margin-right: 0.5rem;
  }
}
@media (max-width: 1200px) {
  .products-list__content__item:nth-child(2n + 2),
  .products-list__content button.products-list__content__item:nth-child(2n + 2) {
    margin-right: 0;
  }
}
.products-list__load,
.products-list button.products-list__load {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  margin-top: 4rem;
}
@media (max-width: 1200px) {
  .products-list__load,
  .products-list button.products-list__load {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .products-list__load,
  .products-list button.products-list__load {
    margin-top: 3rem;
  }
}
