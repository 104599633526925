@import 'variables';

.change-delivery {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: @mobileBreakpoint) {
        flex-direction: column;
        align-items: center;
    }

    &__button, button.change-delivery__button {
        position: relative;
        margin-right: @spacingL;
        width: 10rem;
        height: 10rem;
        border-radius: 1rem;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
        background-color: #f7f7f7;
        border: 1px solid #f7f7f7;

        @media (max-width: @mobileBreakpoint) {
            margin-right: 0;
            margin-bottom: @spacingL;
        }

        >svg {
            position: absolute;
            right: 0.625rem;
            top: 0.625rem;
            width: 1.25rem;
            height: 1.25rem;
            color: @colorPrimary;
            opacity: 0;
        }

        img {
            margin-top: -@spacingXL;
            height: 3.5rem;
        }

        &__icon {
            margin-top: -@spacingXL;
            display: flex;
            flex-direction: column;
            align-items: center;

            svg {
                margin-bottom: @spacingXXS;
                color: @colorNeutral2;
                width: 2.5rem;
                height: 2.5rem;
            }

            span {
                text-align: center;
                font-size: 0.75rem;
                font-weight: 300;
                line-height: 1.08;
                color: #000;
            }
        }

        &__price {
            color: @colorNeutral2;
            position: absolute;
            margin: auto;
            bottom: @spacingL;
            left: 0;
            right: 0;
        }
    }
    &__button:last-of-type, button.change-delivery__button:last-of-type {
        margin-right: 0;
    }
    &__button.active, button.change-delivery__button.active {
        border-color: @colorPrimary;

        >svg {
            opacity: 1;
        }
    }
}
