.filter {
  width: 16.25rem;
  margin-right: 7rem;
  height: 100%;
}
@media (max-width: 1200px) {
  .filter {
    position: relative;
    top: 0;
    width: 100%;
    margin-right: 0;
    height: auto;
  }
}
.filter .products__content__sort {
  display: none;
}
@media (max-width: 1200px) {
  .filter .products__content__sort {
    display: flex;
    margin-top: 0;
    margin-bottom: 4rem;
    flex-wrap: wrap;
  }
}
@media (max-width: 1200px) {
  .filter .products__content__sort button {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    width: calc((100% - 0.5rem) / 2);
    min-width: calc((100% - 0.5rem) / 2) !important;
    max-width: calc((100% - 0.5rem) / 2) !important;
  }
}
@media (max-width: 1200px) {
  .filter .products__content__sort button:nth-child(2n + 2) {
    margin-right: 0;
  }
}
.filter__section-title,
.filter button.filter__section-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}
.filter__section-title__name,
.filter button.filter__section-title__name {
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.7px;
  color: #161616;
  text-transform: uppercase;
}
.filter__section-title svg,
.filter button.filter__section-title svg {
  height: 1.125rem;
  color: #1f1f1f;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.filter__section-title:first-of-type,
.filter button.filter__section-title:first-of-type {
  margin-top: 0;
}
.filter__section-title-closed svg,
.filter button.filter__section-title-closed svg {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.filter__section__storage {
  margin-bottom: 2rem;
}
.filter__section__storage .checkbox {
  width: 100%;
}
.filter__section__storage .count {
  font-weight: 300;
  color: #aaa;
}
.filter__section__categories {
  margin-bottom: 2rem;
}
.filter__section__categories > div {
  margin-top: 1rem;
}
.filter__section__categories > div:first-of-type {
  margin-top: 0;
}
.filter__section__categories__category,
.filter__section__categories button.filter__section__categories__category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 1.25rem;
}
@media (max-width: 1200px) {
  .filter__section__categories__category,
  .filter__section__categories button.filter__section__categories__category {
    font-size: 0.875rem;
  }
}
.filter__section__categories__category__name,
.filter__section__categories button.filter__section__categories__category__name {
  color: #1f1f1f;
}
.filter__section__categories__category svg,
.filter__section__categories button.filter__section__categories__category svg {
  height: 1.125rem;
  color: #1f1f1f;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.filter__section__categories__category-closed svg,
.filter__section__categories button.filter__section__categories__category-closed svg {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.filter__section__categories__category.active .filter__section__categories__category__name,
.filter__section__categories button.filter__section__categories__category.active .filter__section__categories__category__name {
  color: #4030dc;
  font-weight: bold;
}
.filter__section__categories__subcategory,
.filter__section__categories button.filter__section__categories__subcategory {
  margin-top: 1rem;
  padding-left: 2rem;
  color: #8b8b8b;
  height: 1.25rem;
  width: 100%;
  justify-content: flex-start;
}
@media (max-width: 1200px) {
  .filter__section__categories__subcategory,
  .filter__section__categories button.filter__section__categories__subcategory {
    font-size: 0.875rem;
  }
}
.filter__section__categories__subcategory.active,
.filter__section__categories button.filter__section__categories__subcategory.active {
  color: #4030dc;
  font-weight: bold;
}
.filter__section__variants {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1.25rem;
}
.filter__section__variants .checkbox {
  margin-right: 0;
  margin-bottom: 0.75rem;
  width: 50%;
}
.filter__section__variants .checkbox .count {
  font-weight: 300;
  color: #aaa;
}
.filter__section__variables {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.filter__section__variables .checkbox {
  margin-right: 0;
  margin-bottom: 0.75rem;
  width: 100%;
}
.filter__section__variables .checkbox .count {
  font-weight: 300;
  color: #aaa;
}
.filter__section__inputs {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.filter__section__inputs .input {
  width: 42%;
}
.filter__section__inputs__currency {
  font-weight: 300;
  color: #aaa;
}
.filter__buyed {
  margin-top: 4rem;
}
@media (max-width: 1200px) {
  .filter__buyed {
    padding-bottom: 8.5rem;
  }
}
.filter__buyed__title {
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.7px;
  color: #161616;
  text-transform: uppercase;
}
.filter__down {
  display: none;
}
@media (max-width: 1200px) {
  .filter__down {
    display: flex;
    margin-top: 0;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 2rem;
    width: calc(100% - 2rem * 2);
    box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.16);
    background-color: #f7f7f7;
  }
}
.filter__down .products__content__sort {
  margin-bottom: 0;
  width: 100%;
}
.filter__down .products__content__sort button {
  flex: 1;
}
.filter__down .products__content__sort button:last-of-type {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  height: 3.5rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #4030dc;
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #fff;
  text-transform: uppercase;
  border: 0 !important;
  border-radius: 0 !important;
  height: 2.5rem;
}
@media (max-width: 1200px) {
  .filter__down .products__content__sort button:last-of-type {
    font-size: 0.875rem;
  }
}
