.article {
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
}
@media (max-width: 1200px) {
  .article {
    margin-top: 1rem;
    flex-direction: column;
  }
}
.article__left {
  width: 62rem;
  margin-right: 10rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media (max-width: 1200px) {
  .article__left {
    margin-right: 0;
    width: 100%;
  }
}
.article__left__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  width: 38rem;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.4;
  color: #030303;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .article__left__title {
    width: 100%;
    font-size: 1.5rem;
  }
}
.article__left__photo {
  margin-top: 5.5rem;
  width: 100%;
  height: 31rem;
  background-color: #f5f5f5;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .article__left__photo {
    margin-top: 3rem;
    height: auto;
  }
}
.article__left__photo img {
  width: 100%;
}
.article__left__content {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .article__left__content {
    margin-top: 2rem;
    flex-direction: column;
  }
}
.article__left__content__author {
  width: 6.25rem;
}
@media (max-width: 1200px) {
  .article__left__content__author {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 3rem;
    align-items: center;
  }
}
.article__left__content__author__photo {
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
}
.article__left__content__author__photo img {
  width: 100%;
}
.article__left__content__author__name {
  margin-top: 1rem;
  font-size: 0.75rem;
  line-height: 1.4;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .article__left__content__author__name {
    margin-top: 0;
    margin-left: 1rem;
    font-size: 0.625rem;
  }
}
.article__left__content__data {
  width: 38rem;
  margin-left: 10rem;
}
@media (max-width: 1200px) {
  .article__left__content__data {
    margin-left: 0;
    width: 100%;
  }
}
.article__left__content__data__text {
  line-height: 1.8;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .article__left__content__data__text {
    font-size: 0.875rem;
  }
}
.article__left__content__data__text iframe {
  width: 100%;
  min-height: 300px;
}
.article__left__content__data__text img {
  max-width: 100%;
}
.article__left__content__data .comments {
  margin-top: 7rem;
}
.article__right {
  flex: 1;
}
.article__right__title {
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.7px;
  color: #161616;
  text-transform: uppercase;
}
.article__right__article,
.article__right button.article__right__article {
  margin: 1.5rem 0 0 -0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 1200px) {
  .article__right__article,
  .article__right button.article__right__article {
    width: 100%;
  }
}
.article__right__article__categories,
.article__right button.article__right__article__categories {
  font-size: 0.75rem;
  color: #8b8b8b;
}
@media (max-width: 1200px) {
  .article__right__article__categories,
  .article__right button.article__right__article__categories {
    font-size: 0.625rem;
  }
}
.article__right__article__name,
.article__right button.article__right__article__name {
  margin-top: 0.5rem;
  font-weight: 600;
  color: #1f1f1f;
  text-align: left;
}
@media (max-width: 1200px) {
  .article__right__article__name,
  .article__right button.article__right__article__name {
    font-size: 0.875rem;
  }
}
.article__right__article__date,
.article__right button.article__right__article__date {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .article__right__article__date,
  .article__right button.article__right__article__date {
    font-size: 0.625rem;
  }
}
.article__right__article:hover {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fdfdfd;
}
.article__right .popular-products {
  margin-top: 5.5rem;
}
