@import 'variables';

.content {
    &__screen {
        position: relative;
        margin: auto;
        padding: @spacingL @spacingXL @spacing4XL @spacingXL;
        max-width: ~"calc(1600px - @{spacingXL} * 2)";
        min-height: ~"calc(100vh - @{rem10} - @{spacing4XL})";

        @media (max-width: @pcBreakpoint) {
            max-width: ~"calc(100% - @{spacingXL} * 2)";
        }

        @media (max-width: @mobileBreakpoint) {
            padding: @spacingL 1.625rem @spacingXL 1.625rem;
            max-width: ~"calc(100% - 1.625rem * 2)";
            min-height: ~"calc(100vh - @{spacing2XL} - @{spacingXL})";
        }
    }
}
