.full-admin {
  margin: -2rem calc((100vw - 1600px) / -2 - 3rem) -7rem calc((100vw - 1600px) / -2 - 3rem);
  min-height: 100vh;
  background-color: #ecedf0;
}
.full-admin .translate-html-lightbox__tabs div {
  background-color: #8b8b8b;
}
.full-admin .translate-html-lightbox__buttons {
  display: none;
}
.full-admin .ck-editor {
  width: calc((100vw - 300px) / 2) !important;
  margin-bottom: 1.5rem !important;
}
.full-admin .blogs .ck-editor {
  width: calc(100vw - 380px) !important;
  margin-bottom: 1.5rem !important;
}
.full-admin .input .MuiFilledInput-root {
  border-radius: 0 !important;
  background-color: #fdfdfd !important;
  border: 1px solid transparent !important;
}
.full-admin .translations .input {
  margin: 0 !important;
  min-width: 400px;
}
.full-admin .translations .input .input__label {
  display: none;
}
.full-admin .translations .input .MuiFilledInput-root {
  background-color: #e1e1e1 !important;
}
.full-admin .orders .edit__panels__panel:last-of-type button {
  width: 100%;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #8b8b8b;
  text-decoration: underline;
  text-align: left;
  justify-content: left;
  align-items: flex-start;
}
.full-admin .input__upload {
  position: relative;
  margin-top: 1rem;
  border-radius: 0.5rem;
  background-color: #e1e1e1;
  padding: 3rem;
  max-width: calc((100vw - 300px) / 2);
}
.full-admin .input__upload > span,
.full-admin .input__upload > svg {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.5;
}
.full-admin .input__upload img {
  width: 100%;
}
.full-admin .input__upload input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.full-admin .input__upload.icon {
  max-width: 60px;
}
.full-admin .select__container div:first-of-type {
  background-color: #fdfdfd !important;
}
.full-admin .edit__panels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.full-admin .edit__panels__panel {
  flex: 1;
}
.full-admin .edit__panels__panel:first-of-type {
  margin-right: 3rem;
}
.full-admin__header {
  height: 60px;
  background-color: #ffffff;
  position: fixed;
  left: 0;
  top: 0;
  width: calc(100% - 60px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  z-index: 100;
}
.full-admin__header > img {
  height: 40px;
}
.full-admin__header__buttons {
  display: flex;
  flex-direction: row;
}
.full-admin__header__buttons button {
  flex-direction: column;
  align-items: center;
}
.full-admin__header__buttons button span {
  font-size: 0.75rem;
}
.full-admin__content {
  display: flex;
  flex-direction: row;
  padding-top: 60px;
  min-height: calc(100vh - 60px);
}
.full-admin__content__menu {
  flex: 1;
  min-width: 300px;
  max-width: 300px;
  background-color: #1d2127;
  position: fixed;
  left: 0;
  top: 60px;
  height: calc(100vh - 60px);
  overflow: scroll;
}
.full-admin__content__menu__header {
  height: 50px;
  background-color: #21262d;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  margin-bottom: 10px;
  color: #abb4be;
}
.full-admin__content__menu__header svg {
  width: 2rem;
  height: 2rem;
}
.full-admin__content__menu__header span {
  margin-left: 0.75rem;
  text-transform: uppercase;
}
.full-admin__content__menu button {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #abb4be;
  height: 34px;
  padding: 0 30px 0 50px;
  font-size: 0.875rem;
  width: 100%;
}
.full-admin__content__menu button span {
  flex: 1;
  text-align: left;
}
.full-admin__content__menu button svg {
  width: 1.25rem;
  height: 1.25rem;
}
.full-admin__content__menu button.active {
  color: #00cc87;
}
.full-admin__content__menu button:last-of-type {
  margin-bottom: 60px;
}
.full-admin__content__screen {
  padding-left: 300px;
  flex: 1;
  max-width: calc(100vw - 300px);
}
.full-admin__content__screen__header {
  height: 50px;
  background-color: #000;
  color: #fdfdfd;
  display: flex;
  align-items: center;
  padding: 0 40px;
  font-weight: bold;
}
.full-admin__content__screen__content {
  padding: 40px;
  width: calc(100% - 80px);
}
.full-admin__content__screen__content__header {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.full-admin__content__screen__content__header button {
  height: 40px;
  padding: 0 20px;
  background-color: #409b04;
  color: #fdfdfd;
  border-radius: 4px;
}
.full-admin__content__screen__content__header button.back {
  background-color: #8b8b8b;
}
.full-admin__content__screen__content table td:last-of-type {
  text-align: right;
}
.full-admin__content__screen__content .input,
.full-admin__content__screen__content .select,
.full-admin__content__screen__content .checkbox {
  margin-bottom: 1.5rem;
}
.full-admin__content__screen__content button.save {
  margin: 2rem auto 0 auto;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  background-color: #409b04;
  color: #fdfdfd;
  border-radius: 4px;
}
.translate-lightbox {
  width: 800px;
}
.translate-lightbox .input {
  margin-bottom: 1.5rem;
}
