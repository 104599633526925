@import 'variables';

.blog-screen {
    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: @mobileBreakpoint) {
            align-items: flex-start;
        }

        &__title {
            .fontBarlow();

            font-size: 5rem;
            font-weight: bold;
            font-style: italic;
            color: #1f1f1f;
            text-transform: uppercase;

            @media (max-width: @mobileBreakpoint) {
                font-size: 3.5rem;
            }
        }

        &__text {
            margin-top: @spacingM;
            width: 46rem;
            line-height: 1.8;
            text-align: center;
            color: #1f1f1f;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingL;
                font-size: 0.875rem;
                width: 100%;
                text-align: left;
            }
        }

        &__categories {
            position: relative;
            margin-top: @spacing2XL;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 47rem;

            @media (max-width: @mobileBreakpoint) {
                width: auto;
            }

            &__category, button.blog-screen__header__categories__category {
                .button();

                padding: 0;
                margin-right: @spacingS;
                margin-bottom: @spacingS;

                @media (max-width: @mobileBreakpoint) {
                    width: ~"calc((100% - @{spacingS}) / 2)";
                    min-width: ~"calc((100% - @{spacingS}) / 2)";
                }
            }
            &__category:nth-child(4n + 4), button.blog-screen__header__categories__category:nth-child(4n + 4) {
                margin-right: 0;

                @media (max-width: @mobileBreakpoint) {
                    margin-right: @spacingS;
                }
            }
            &__category:nth-child(2n + 2), button.blog-screen__header__categories__category:nth-child(2n + 2) {
                @media (max-width: @mobileBreakpoint) {
                    margin-right: 0;
                }
            }
            &__category.active, button.blog-screen__header__categories__category.active {
                box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.16);
                background-color: #1f1f1f;
                color: #fefefe;
            }
        }
    }

    .blog {
        margin-top: @spacing4XL;
    }
}
