@import 'variables';

.product {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;

    .delivery-payment {
        @media (max-width: @mobileBreakpoint) {
            width: 100%;
        }

        .delivery-payment__table {
            margin-top: @spacing3XL;
            width: 100%;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingXL;
            }

            .delivery-payment__table__title {
                font-size: 2rem;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.5rem;
                }
            }

            .delivery-payment__table__rows {
                margin-top: 0;
            }
        }
        .delivery-payment__table:not(.payments) {
            .delivery-payment__table__rows__row__column:nth-child(1), .delivery-payment__table__rows__row__column:nth-child(3) {
                display: none;
            }
        }

        .delivery-payment__table.payments {
            @media (max-width: @mobileBreakpoint) {
                margin-bottom: 0;
            }
        }
    }

    @media (max-width: @mobileBreakpoint) {
        flex-direction: column;
    }

    &__left {
        margin-right: 8rem;
        width: 48rem;

        @media (max-width: @mobileBreakpoint) {
            width: 100%;
            margin-right: 0;
            margin-top: @spacingXXS;
        }

        &__slideshow {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &__photos {
                width: 6rem;

                @media (max-width: @mobileBreakpoint) {
                    display: none;
                }

                &__photo, button.product__left__slideshow__photos__photo {
                    margin-bottom: @spacingL;
                    width: 6rem;
                    height: 7.75rem;
                    background-color: @colorNeutral1;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        height: 100%;
                        mix-blend-mode: multiply;
                    }
                }
            }

            &__photo {
                position: relative;
                width: 40rem;
                height: 40rem;
                background-color: @colorNeutral1;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                    height: 32rem;
                    border-radius: 0;
                }

                img {
                    height: 100%;
                    mix-blend-mode: multiply;
                }

                button {
                    position: absolute;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    width: 3.75rem;
                    height: 3.75rem;

                    @media (max-width: @mobileBreakpoint) {
                        background-color: @colorBaseColor;
                        border-radius: 100%;
                        width: 2.5rem;
                        height: 2.5rem;
                    }

                    svg {
                        width: 5rem;
                        height: 5rem;
                        opacity: .5;

                        @media (max-width: @mobileBreakpoint) {
                            width: 3rem;
                            height: 3rem;
                        }
                    }
                }
                button.prev {
                    left: 0.5rem;

                    .rotate(-180deg);
                }
                button.next {
                    right: 0.5rem;
                }
            }
        }

        &__sections {
            display: flex;
            flex-direction: row;
            margin-top: @spacing4XL;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacing3XL;
                flex-wrap: wrap;
            }

            button {
                .button();

                margin-right: @spacingS;

                @media (max-width: @mobileBreakpoint) {
                    width: ~"calc((100% - @{spacingS}) / 2)";
                    min-width: ~"calc((100% - @{spacingS}) / 2)";
                    margin-bottom: @spacingS;
                }
            }
            button:nth-child(2n + 2) {
                @media (max-width: @mobileBreakpoint) {
                    margin-right: 0;
                }
            }
            button.active {
                box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.16);
                background-color: #1f1f1f;
                color: #fefefe;
            }
        }

        &__description {
            margin-top: @spacing3XL;
            line-height: 1.8;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingXL;
            }
        }

        &__reviews {
            margin-top: @spacing3XL;
            display: flex;
            flex-direction: column;
            align-items: center;

            &__title {
                .fontBarlow();

                font-size: 2rem;
                font-weight: bold;
                font-style: italic;
                line-height: 1.4;
                color: #1f1f1f;
                text-transform: uppercase;
                width: 100%;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.5rem;
                }
            }

            &__stats {
                margin-top: @spacingM;
                display: flex;
                flex-direction: row;
                align-items: flex-end;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingL;
                    flex-direction: column-reverse;
                    align-items: flex-start;
                }

                &__left {
                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingXL;
                        width: 100%;
                    }

                    &__line {
                        height: 0.75rem;
                        margin-bottom: @spacingS;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        svg {
                            width: 1rem;
                            height: 1rem;
                        }

                        &__loader {
                            position: relative;
                            margin-left: @spacingS;
                            width: 13.875rem;
                            height: 0.5rem;
                            background-color: #f7f7f7;
                            border-radius: 0.5rem;
                            overflow: hidden;

                            @media (max-width: @mobileBreakpoint) {
                                width: auto;
                                flex: 1;
                            }

                            &__in {
                                position: absolute;
                                left: 0;
                                top: 0;
                                height: 100%;
                                background-color: #1f1f1f;
                            }
                        }

                        &__value {
                            margin-left: @spacingM;
                            font-size: 0.75rem;
                            color: #1f1f1f;

                            @media (max-width: @mobileBreakpoint) {
                                margin-left: @spacingL;
                            }
                        }
                    }

                    &__text {
                        margin-top: @spacingM;
                        line-height: 1.8;
                        color: #1f1f1f;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                        }
                    }
                }

                &__right {
                    margin-left: 10rem;

                    @media (max-width: @mobileBreakpoint) {
                        margin-left: 0;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        width: 100%;
                        justify-content: space-between;
                    }

                    &__value {
                        margin-bottom: @spacingS;
                        font-size: 1.625rem;
                        font-weight: bold;
                        line-height: 1.38;
                        color: #000;

                        @media (max-width: @mobileBreakpoint) {
                            width: 100%;
                            margin-bottom: @spacingXXS;
                            font-size: 1.25rem;
                        }
                    }

                    button {
                        .button();

                        margin-top: @spacingL;

                        @media (max-width: @mobileBreakpoint) {
                            margin-top: 0;
                        }
                    }
                    button[disabled] {
                        background-color: transparent !important;
                        opacity: .5;
                    }
                }
            }

            &__items {
                display: flex;
                flex-direction: row;
                margin-top: @spacing4XL;
                width: 100%;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingXL;
                    flex-direction: column;
                }

                &__panel {
                    flex: 1;

                    &__item {
                        margin-bottom: @spacingXL;

                        @media (max-width: @mobileBreakpoint) {
                            margin-bottom: @spacingL;
                        }

                        &__header {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            margin-bottom: @spacingXXS;

                            &__name {
                                .fontBarlow();

                                font-size: 1.125rem;
                                font-weight: bold;
                                font-style: italic;
                                line-height: 1.2;
                                letter-spacing: 0.9px;
                                color: #1f1f1f;
                                text-transform: uppercase;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.875rem;
                                }
                            }

                            &__date {
                                font-size: 0.75rem;
                                line-height: 1.4;
                                letter-spacing: normal;
                                text-align: right;
                                color: #1f1f1f;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.625rem;
                                }
                            }
                        }

                        svg {
                            width: 1rem;
                            height: 1rem;
                        }

                        &__text {
                            margin-top: @spacingS;
                            padding-right: @spacingXL;
                            line-height: 1.8;
                            color: #1f1f1f;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                                padding-right: 0;
                                margin-top: @spacingXS;
                            }
                        }

                        >img {
                            margin-top: @spacingM;
                            width: 100%;
                        }
                    }
                }
                &__panel:first-of-type {
                    margin-right: @spacingL;

                    @media (max-width: @mobileBreakpoint) {
                        margin-right: 0;
                    }
                }
            }

            >button {
                .button();

                white-space: nowrap;
            }
        }
    }

    &__right {
        position: sticky;
        width: 30.3125rem;
        top: 12.5rem;
        height: auto;

        @media (max-width: @mobileBreakpoint) {
            position: relative;
            width: 100%;
            top: 0;
            margin-top: @spacingM;
        }

        &__title {
            .fontBarlow();

            font-size: 2rem;
            font-weight: bold;
            font-style: italic;
            color: @colorDark;
            text-transform: uppercase;

            @media (max-width: @mobileBreakpoint) {
                font-size: 1.5rem;
            }
        }

        &__price {
            margin-top: @spacingS;
            font-size: 1.5rem;
            color: #000;

            @media (max-width: @mobileBreakpoint) {
                font-size: 1.25rem;
            }
        }

        &__info {
            margin-top: @spacingS;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @media (max-width: @mobileBreakpoint) {
                flex-direction: column;
                margin-top: @spacingL;
                align-items: flex-start;
            }

            &__variables {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                max-width: 70%;

                >div {
                    font-size: 0.75rem;
                    background-color: @colorNeutral1;
                    color: #1f1f1f;
                    height: 1.5rem !important;
                    margin-right: @spacingXS;
                    margin-bottom: @spacingXS;
                    border-radius: 0 !important;

                    span {
                        padding-left: @spacingXXS;
                        padding-right: @spacingXXS;
                    }
                }
            }

            &__rating {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 1rem;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingXS;
                }

                svg {
                    width: 1.25rem;
                    height: 1.25rem;

                    @media (max-width: @mobileBreakpoint) {
                        width: 1rem;
                        height: 1rem;
                    }
                }

                &__text {
                    margin-left: @spacingXXS;
                    font-size: 0.75rem;
                    color: #1f1f1f;
                    white-space: nowrap;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.625rem;
                    }
                }
            }
        }

        &__variants {
            margin-top: @spacingXL;

            &__title {
                font-size: 0.875rem;
                color: #1f1f1f;
            }
            &__title.order {
                color: @colorOrange;
            }
            &__title.instock{
                color: @colorGreen;
            }

            &__items {
                margin-top: @spacingXXS;
                display: flex;
                flex-direction: row;

                @media (max-width: @mobileBreakpoint) {
                    flex-wrap: wrap;
                    margin-top: 0;
                }

                >span {
                    margin-right: @spacingXXS;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingXXS;
                    }
                }

                &__variant, button.product__right__variants__items__variant {
                    width: 3rem;
                    height: 3rem;
                    border: solid 1px #409b04;
                    font-size: 0.875rem;
                    color: #409b04;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                &__variant[disabled], button.product__right__variants__items__variant[disabled] {
                    border-color: #f7f7f7;
                    color: @colorNeutral3;
                    background: linear-gradient(to top right, #f7f7f7 0%, #f7f7f7 calc(50% - 0.8px), @colorNeutral3 50%, #f7f7f7 calc(50% + 0.8px), #f7f7f7 100%);
                }
                &__variant.order, button.product__right__variants__items__variant.order {
                    border-color: @colorOrange;
                    color: @colorOrange;
                }
                &__variant.instock, button.product__right__variants__items__variant.instock {
                    border-color: @colorGreen;
                    color: @colorGreen;
                }
                &__variant.active, button.product__right__variants__items__variant.active {
                    border-color: @colorPrimary;
                    background-color: @colorPrimary;
                    color: @colorNeutral1;
                    font-weight: 600;
                }
                &__variant:last-of-type, button.product__right__variants__items__variant:last-of-type {
                    margin-right: 0;
                }
            }
        }

        &__table-size, button.product__right__table-size {
            margin-top: @spacingS;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 0.875rem;
            color: @colorNeutral2;

            svg {
                margin-right: 0.25rem;
                width: 1.25rem;
                height: 1.25rem;

                .rotate(45deg);
            }
        }

        &__extensions {
            padding: @spacingM;
            margin-top: @spacingXL;
            background-color: @colorNeutral1;

            &__item {
                margin-bottom: @spacingM;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                height: 1.25rem;

                @media (max-width: @mobileBreakpoint) {
                    flex-direction: column;
                    height: auto;
                    align-items: flex-start;
                    justify-content: flex-start;
                }

                .checkbox {
                    >span {
                        font-size: 0.875rem;
                        color: #1f1f1f;
                    }
                }

                &__price {
                    color: @colorPrimary;
                    font-size: 0.875rem;

                    @media (max-width: @mobileBreakpoint) {
                        margin-left: 2rem;
                    }
                }
            }
            &__item:last-of-type {
                margin-bottom: 0;
            }
        }

        &__buy {
            margin-top: @spacingL;
            display: flex;
            flex-direction: row;
            align-items: center;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingXL;
            }

            &__counter {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: @spacingL;

                &__button, button.product__right__buy__counter__button {
                    width: 2.25rem;
                    height: 2.25rem;
                    border-radius: 100%;
                    background-color: @colorNeutral1;

                    svg {
                        width: 1.125rem;
                        height: 1.125rem;
                        color: @colorNeutral2;
                    }
                }

                &__value {
                    width: 1.875rem;
                    font-size: 1rem;
                    text-align: center;
                    color: #1f1f1f;
                }
            }

            &__button, button.product__right__buy__button {
                flex: 1;
                height: 3.5rem;
                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
                background-color: @colorPrimary;

                @media (max-width: @mobileBreakpoint) {
                    height: 2.5rem;
                }

                svg {
                    margin-right: @spacingS;
                    width: 1.5rem;
                    height: 1.5rem;
                }

                span {
                    .fontBarlow();

                    font-size: 1rem;
                    font-weight: bold;
                    font-style: italic;
                    letter-spacing: 0.8px;
                    color: #fff;
                    text-transform: uppercase;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
            }
        }
        &__buy-fixed {
            display: none;
            position: fixed;
            left: 0;
            bottom: 0;
            width: ~"calc(100% - @{spacingM} * 2)";
            height: 2.5rem;
            box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.16);
            background-color: #f7f7f7;
            padding: @spacingM;
            z-index: 100;

            .product__right__buy {
                margin-top: 0;
            }

            @media (max-width: @mobileBreakpoint) {
                display: block;
            }
        }

        &__buttons {
            margin-top: @spacingM;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            @media (max-width: @mobileBreakpoint) {
                flex-direction: column;
                margin-top: @spacingXL;
            }

            button {
                margin-bottom: @spacingXXS;
                width: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                }

                svg {
                    margin-right: @spacingXXS;
                    width: 2rem;
                    height: 2rem;
                    color: @colorNeutral2;

                    @media (max-width: @mobileBreakpoint) {
                        margin-right: @spacingM;
                    }
                }

                span {
                    font-size: 0.75rem;
                    color: @colorNeutral2;
                    text-transform: uppercase;
                }
            }
            button.active {
                svg, span {
                    color: @colorRed;
                }
            }
        }

        &__short-description {
            margin-top: @spacing2XL;
            line-height: 1.8;
        }
    }

    &__related {
        margin-top: @spacing4XL;
        margin-right: 8rem;
        width: 48rem;

        @media (max-width: @mobileBreakpoint) {
            margin-right: 0;
            width: 100%;
        }

        &__title {
            .fontBarlow();

            font-size: 2rem;
            font-weight: bold;
            font-style: italic;
            color: @colorDark;
            text-align: center;
            text-transform: uppercase;

            @media (max-width: @mobileBreakpoint) {
                font-size: 2.5rem;
                max-width: 50%;
                text-align: left;
            }
        }

        .products-list {
            margin-top: @spacing2XL;

            @media (max-width: @mobileBreakpoint) {
                margin-top: 0;
                flex-direction: row;
                flex-wrap: nowrap;
            }

            .products-list__content {
                @media (max-width: @mobileBreakpoint) {
                    flex-direction: row;
                    flex-wrap: nowrap;
                }
            }

            button.products-list__content__item {
                width: ~"calc((100% - @{spacingXXS}) / 2)";
                margin-right: @spacingXXS !important;
            }
            button.products-list__content__item:nth-child(2n + 2) {
                margin-right: 0 !important;
            }

            .products-list__load {
                @media (max-width: @mobileBreakpoint) {
                    display: none;
                }
            }
        }
    }
}

.extension-lightbox {
    width: 50rem - 2 * @spacingL;

    @media (max-width: @mobileBreakpoint) {
        width: 100%;
    }

    &__text {
        margin: auto;
        width: 38rem;
        text-align: center;
        font-size: 1rem;
        line-height: 1.8;
        color: #1f1f1f;

        @media (max-width: @mobileBreakpoint) {
            width: 100%;
            text-align: left;
            font-size: 0.875rem;
        }
    }

    &__inputs {
        display: flex;
        flex-direction: row;
        margin-top: @spacing2XL;

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacingL;
            flex-direction: column;
        }

        .input {
            flex: 1;
        }
        .input:first-of-type {
            margin-right: @spacingL;

            @media (max-width: @mobileBreakpoint) {
                margin-right: 0;
                margin-bottom: @spacingM;
            }
        }
    }

    &__colors {
        display: flex;
        flex-direction: row;
        margin-top: @spacingL;
        flex-wrap: wrap;
        border-radius: 0.5rem;
        background-color: #f7f7f7;
        padding: 0 @spacingXL @spacingL @spacingXL;

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacingXL;
            padding-left: @spacingXXS + @spacingL;
            padding-right: @spacingXXS;
            justify-content: center;
        }

        &__color {
            display: flex;
            flex-direction: column;
            margin-top: @spacingL;
            margin-right: @spacingL;
            width: 5rem;

            &__code, >button {
                width: 5rem;
                height: 5rem;
                border-radius: 0.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);

                svg {
                    color: @colorBaseColor;
                }
            }

            &__name {
                margin-top: @spacingXXS;
                font-size: 0.75rem;
                line-height: 1.4;
                text-align: left;
                color: #1f1f1f;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.625rem;
                }
            }
        }
        &__color:nth-child(6n + 6) {
            margin-right: 0;

            @media (max-width: @mobileBreakpoint) {
                margin-right: @spacingL;
            }
        }
        &__color.ffffff {
            svg {
                color: @colorBlack;
            }
        }
    }

    &__buttons {
        margin-top: @spacingL;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        button {
            width: 11rem !important;
            height: 3rem !important;
            padding: 0 !important;
        }
        button:first-of-type {
            .button();

            margin-right: @spacingS;
        }
        button:last-of-type {
            .buttonPrimary();
        }
    }
}

.product-add-to-list-lightbox {
    width: 30rem - 2 * @spacingL;

    @media (max-width: @mobileBreakpoint) {
        width: 100%;
    }
}

.review-lightbox {
    width: 50rem - 2 * @spacingL;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: @mobileBreakpoint) {
        width: 100%;
    }

    &__text {
        margin: auto;
        width: 30rem;
        text-align: center;
        font-size: 1rem;
        line-height: 1.8;
        color: #1f1f1f;

        @media (max-width: @mobileBreakpoint) {
            width: 100%;
            font-size: 0.875rem;
        }

        span {
            font-size: 1.375rem;
            font-weight: 300;
            line-height: 1.27;
            color: @colorNeutral2;
        }
    }

    &__review-text {
        margin-top: 2.5rem;
        line-height: 1.8;
        color: #1f1f1f;

        @media (max-width: @mobileBreakpoint) {
            font-size: 0.875rem;
        }
    }

    &__review-rating {
        margin-top: @spacingXXS;
        margin-bottom: @spacingL;

        svg {
            width: 3rem;
            height: 3rem;
        }
    }

    .input__label {
        span {
            font-size: 1.375rem;
            font-weight: 300;
            line-height: 1.27;
            color: @colorNeutral2;
        }
    }

    &__upload {
        margin-top: @spacingM;
        width: 100%;

        &__label {
            font-size: 0.75rem;
            line-height: 1.4;
            color: #1f1f1f;

            @media (max-width: @mobileBreakpoint) {
                font-size: 0.625rem;
            }
        }

        &__button {
            position: relative;
            margin-top: @spacingS;
            display: flex;
            flex-direction: row;
            align-items: center;

            @media (max-width: @mobileBreakpoint) {
                justify-content: flex-end;
            }

            button {
                .button();
            }

            &__text {
                margin-left: @spacingS;
                font-size: 0.75rem;
                line-height: 1.4;
                color: #1f1f1f;

                @media (max-width: @mobileBreakpoint) {
                    display: none;
                }
            }

            input[type="file"] {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }
        }
    }

    &__inputs {
        display: flex;
        flex-direction: row;
        margin-top: @spacingXL;
        width: 100%;

        .input {
            flex: 1;
        }
        .input:first-of-type {
            margin-right: @spacingL;
        }
    }

    &__buttons {
        margin-top: @spacingXL;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @media (max-width: @mobileBreakpoint) {
            flex-direction: column;
            margin-top: @spacingL;
        }

        button {
            .buttonPrimary();

            width: 11rem !important;
            height: 3rem !important;
            padding: 0 !important;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingM;
            }
        }

        .checkbox {
            span {
                font-size: 0.75rem;
                line-height: 2.4;
                color: #1f1f1f;

                a {
                    color: @colorNeutral2;
                    text-decoration: underline;
                }
            }
        }
    }
}
