@import 'variables';

.article {
    display: flex;
    flex-direction: row;
    margin-top: @spacingXL;

    @media (max-width: @mobileBreakpoint) {
        margin-top: @spacingS;
        flex-direction: column;
    }

    &__left {
        width: 62rem;
        margin-right: 10rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media (max-width: @mobileBreakpoint) {
            margin-right: 0;
            width: 100%;
        }

        &__title {
            .fontBarlow();

            width: 38rem;
            font-size: 2rem;
            font-weight: bold;
            font-style: italic;
            line-height: 1.4;
            color: @colorDark;
            text-transform: uppercase;

            @media (max-width: @mobileBreakpoint) {
                width: 100%;
                font-size: 1.5rem;
            }
        }

        &__photo {
            margin-top: @spacing3XL;
            width: 100%;
            height: 31rem;
            background-color: @colorNeutral1;
            overflow: hidden;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingXL;
                height: auto;
            }

            img {
                width: 100%;
            }
        }

        &__content {
            margin-top: @spacingXL;
            display: flex;
            flex-direction: row;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingL;
                flex-direction: column;
            }

            &__author {
                width: 6.25rem;

                @media (max-width: @mobileBreakpoint) {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    margin-top: @spacingXL;
                    align-items: center;
                }

                &__photo {
                    width: 4rem;
                    height: 4rem;
                    border-radius: 100%;
                    background-color: @colorNeutral1;
                    overflow: hidden;

                    img {
                        width: 100%;
                    }
                }

                &__name {
                    margin-top: @spacingS;
                    font-size: 0.75rem;
                    line-height: 1.4;
                    color: #1f1f1f;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: 0;
                        margin-left: @spacingS;
                        font-size: 0.625rem;
                    }
                }
            }

            &__data {
                width: 38rem;
                margin-left: 10rem;

                @media (max-width: @mobileBreakpoint) {
                    margin-left: 0;
                    width: 100%;
                }

                &__text {
                    line-height: 1.8;
                    color: #1f1f1f;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.875rem;
                    }

                    iframe {
                        width: 100%;
                        min-height: 300px;
                    }

                    img {
                        max-width: 100%;
                    }
                }

                .comments {
                    margin-top: @spacing4XL;
                }
            }
        }
    }

    &__right {
        flex: 1;

        &__title {
            font-size: 0.875rem;
            font-weight: 600;
            letter-spacing: 0.7px;
            color: #161616;
            text-transform: uppercase;
        }

        &__article, button.article__right__article {
            margin: @spacingM 0 0 -@spacingXXS;
            padding: @spacingXXS;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media (max-width: @mobileBreakpoint) {
                width: 100%;
            }

            &__categories {
                font-size: 0.75rem;
                color: @colorNeutral2;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.625rem;
                }
            }

            &__name {
                margin-top: @spacingXXS;
                font-weight: 600;
                color: #1f1f1f;
                text-align: left;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                }
            }

            &__date {
                margin-top: @spacingXXS;
                font-size: 0.75rem;
                color: #1f1f1f;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.625rem;
                }
            }
        }
        &__article:hover {
            box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
            background-color: @colorBaseColor;
        }

        .popular-products {
            margin-top: @spacing3XL;
        }
    }
}
