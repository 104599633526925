.lost-password {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
@media (max-width: 1200px) {
  .lost-password {
    min-height: 86vh;
  }
}
.lost-password__panel {
  width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .lost-password__panel {
    width: 100%;
  }
}
.lost-password__panel__up__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  font-weight: bold;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .lost-password__panel__up__title {
    font-size: 3.5rem;
  }
}
.lost-password__panel__up__text {
  margin-top: 1rem;
  padding-bottom: 2rem;
  color: #1f1f1f;
  line-height: 1.8;
}
@media (max-width: 1200px) {
  .lost-password__panel__up__text {
    font-size: 0.875rem;
  }
}
.lost-password__panel__up .input {
  margin-top: 1.5rem;
}
.lost-password__panel__up__buttons {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.lost-password__panel__up__buttons__button,
.lost-password__panel__up__buttons button.lost-password__panel__up__buttons__button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
}
@media (max-width: 1200px) {
  .lost-password__panel__up__buttons__button,
  .lost-password__panel__up__buttons button.lost-password__panel__up__buttons__button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
.lost-password__panel__down {
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .lost-password__panel__down {
    display: flex;
    justify-content: center;
  }
}
.lost-password__panel__down button {
  margin-left: 0.25rem;
  font-weight: 300;
  color: #8b8b8b;
  text-decoration: underline;
}
@media (max-width: 1200px) {
  .lost-password__panel__down button {
    font-size: 0.875rem;
  }
}
.lost-password__photo {
  margin-left: 10rem;
  min-width: 46rem;
  width: 46rem;
  height: 46rem;
  background-color: #f5f5f5;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .lost-password__photo {
    display: none;
  }
}
.lost-password__photo img {
  width: 100%;
}
