.shops {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shops__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: center;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .shops__title {
    font-size: 3.5rem;
  }
}
.shops__map {
  margin-top: 1.5rem;
  min-width: 100%;
  width: 100%;
  height: 46rem;
  background-color: #f5f5f5;
  border-radius: 1rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .shops__map {
    height: 13.375rem;
  }
}
.shops__map iframe {
  width: 100%;
  height: 100%;
}
.shops__panel {
  margin-top: 7rem;
  padding-left: 8rem;
  width: calc(100% - 8rem);
}
@media (max-width: 1200px) {
  .shops__panel {
    margin-top: 3rem;
    padding-left: 0;
    width: 100%;
  }
}
.shops__panel__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .shops__panel__title {
    font-size: 1.5rem;
  }
}
.shops__panel__items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.shops__panel__items__item,
.shops__panel__items button.shops__panel__items__item {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-right: 3rem;
  width: 14rem;
}
@media (max-width: 1200px) {
  .shops__panel__items__item,
  .shops__panel__items button.shops__panel__items__item {
    margin-right: 0.5rem;
    width: calc((100% - 0.5rem) / 2);
  }
}
.shops__panel__items__item__title,
.shops__panel__items button.shops__panel__items__item__title {
  width: 100%;
  font-weight: 600;
  line-height: 1.8;
  color: #1f1f1f;
  text-align: left;
}
@media (max-width: 1200px) {
  .shops__panel__items__item__title,
  .shops__panel__items button.shops__panel__items__item__title {
    font-size: 0.875rem;
  }
}
.shops__panel__items__item__text,
.shops__panel__items button.shops__panel__items__item__text {
  margin-top: 1rem;
  line-height: 1.8;
  color: #1f1f1f;
  width: 100%;
  text-align: left;
}
@media (max-width: 1200px) {
  .shops__panel__items__item__text,
  .shops__panel__items button.shops__panel__items__item__text {
    font-size: 0.875rem;
  }
}
@media (max-width: 1200px) {
  .shops__panel__items__item:nth-child(2n + 2),
  .shops__panel__items button.shops__panel__items__item:nth-child(2n + 2) {
    margin-right: 0;
  }
}
.shops__panel__items__item.active .shops__panel__items__item__title,
.shops__panel__items button.shops__panel__items__item.active .shops__panel__items__item__title {
  color: #4030dc;
}
