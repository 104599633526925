.sizes {
  width: 76rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .sizes {
    width: 100%;
    flex-direction: column;
  }
}
.sizes__left {
  margin-right: 8rem;
  margin-left: 2rem;
  width: 22.5rem;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1200px) {
  .sizes__left {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    flex-direction: column-reverse;
  }
}
@media (max-width: 1200px) {
  .sizes__left__photo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.sizes__left__photo svg {
  width: 14rem;
  height: 14rem;
}
@media (max-width: 1200px) {
  .sizes__left__photo svg {
    width: 10rem;
    height: 10rem;
  }
}
.sizes__left__content__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .sizes__left__content__title {
    font-size: 1.5rem;
  }
}
.sizes__left__content__text {
  margin-top: 2rem;
  line-height: 1.8;
  font-size: 0.875rem;
}
@media (max-width: 1200px) {
  .sizes__left__content__text {
    margin-top: 1.5rem;
  }
}
.sizes__right {
  flex: 1;
}
@media (max-width: 1200px) {
  .sizes__right {
    overflow-x: scroll;
  }
}
.sizes__right__row {
  font-size: 0.875rem;
  display: flex;
  flex-direction: row;
}
.sizes__right__row__cell {
  min-width: 7rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid #8b8b8b;
}
.sizes__right__row__cell:first-of-type {
  flex: 1;
  font-weight: bold;
}
.sizes__right__row:first-of-type .sizes__right__row__cell {
  font-weight: bold;
}
.sizes__right__row:first-of-type .sizes__right__row__cell span {
  font-weight: normal;
  font-size: 0.75rem;
}
.sizes__right__row:last-of-type .sizes__right__row__cell {
  border-bottom: 0;
}
