@import 'variables';

button.filter-button {
    position: fixed;
    right: 0;
    bottom: 3.75rem;
    width: 3.5rem;
    height: 4.625rem;
    display: none;

    @media (max-width: @mobileBreakpoint) {
        display: flex;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
        background-color: @colorPrimary;
        z-index: 100;
        color: @colorBaseColor;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
        flex-direction: column;

        svg {
            width: 2rem;
            height: 2rem;
        }

        div {
            margin-top: @spacingXXS;
            font-size: 0.5rem;
        }
    }
}

.products {
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1;

    >.filter {
        @media (max-width: @mobileBreakpoint) {
            display: none;
        }
    }

    &__content {
        flex: 1;
        text-align: center;

        &__title {
            .fontBarlow();

            font-weight: bold;
            font-stretch: condensed;
            font-style: italic;
            color: #1f1f1f;
            text-transform: uppercase;
            font-size: 5rem;
            text-align: left;

            @media (max-width: @mobileBreakpoint) {
                font-size: 3.5rem;
            }
        }

        &__text {
            margin-top: @spacingL;
            max-width: 37.5rem;
            font-size: 1rem;
            line-height: 1.8;
            color: #1f1f1f;
            text-align: left;

            @media (max-width: @mobileBreakpoint) {
                font-size: 0.875rem;
            }

            button {
                display: block;
                color: @colorNeutral2;
                text-decoration: underline;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                }
            }
        }

        &__categories {
            margin-top: @spacingXL;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            &__category, button.products__content__categories__category {
                margin-right: @spacingS;
                margin-bottom: @spacingS;
                padding: 0.75rem 0.75rem 0.75rem 1rem;
                width: 14.875rem;
                height: 3.5rem;
                box-shadow: 0 4px 12px 0 rgba(0,0,0,0.1);
                background-color: #fefefe;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                @media (max-width: @mobileBreakpoint) {
                    margin-right: 0;
                    margin-bottom: @spacingXXS;
                    width: 100%;
                }

                &__info {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: flex-start;

                    &__name {
                        font-size: 0.875rem;
                        font-weight: 600;
                        color: #1f1f1f;
                        text-align: left;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.75rem;
                        }
                    }

                    &__count {
                        font-size: 0.75rem;
                        color: @colorNeutral2;
                    }
                }

                img {
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
            &__category:hover, button.products__content__categories__category:hover {
                box-shadow: none;
            }
        }

        &__popular {
            margin-top: @spacing2XL;
            padding: @spacingL;
            background-color: @colorNeutral1;
            border-radius: 1rem;

            @media (max-width: @mobileBreakpoint) {
                margin: @spacingXL -1.625rem 0 -1.625rem;
                padding: @spacingL 1.625rem;
            }

            &__title {
                .fontBarlow();

                font-size: 1.5rem;
                font-weight: bold;
                font-style: italic;
                text-align: center;
                color: #1f1f1f;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
            }

            &__items {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;

                @media (max-width: @mobileBreakpoint) {
                    flex-direction: column;
                }

                &__item, button.products__content__popular__items__item {
                    margin-top: @spacingM;
                    width: 42%;
                    height: 4rem;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    @media (max-width: @mobileBreakpoint) {
                        width: 100%;
                        margin-bottom: @spacingM;
                        height: 3rem;
                    }

                    &__left {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        img {
                            width: 4rem;
                            height: 4rem;
                            mix-blend-mode: multiply;

                            @media (max-width: @mobileBreakpoint) {
                                width: 3rem;
                                height: 3rem;
                            }
                        }

                        &__info {
                            margin-left: @spacingS;
                            display: flex;
                            flex-direction: column;

                            &__variables {
                                margin-bottom: 0.25rem;
                                font-size: 0.75rem;
                                color: @colorNeutral2;
                                text-align: left;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.5rem;
                                }
                            }

                            &__name {
                                .fontBarlow();

                                font-size: 1.125rem;
                                font-weight: bold;
                                font-style: italic;
                                letter-spacing: 0.9px;
                                color: #1f1f1f;
                                text-align: left;
                                text-transform: uppercase;
                                max-width: 86%;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.875rem;
                                }
                            }
                        }
                    }

                    &__amount {
                        font-size: 0.875rem;
                        color: #1f1f1f;
                        white-space: nowrap;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.75rem;
                        }
                    }
                }
            }
        }

        &__popular-button, button.products__content__popular-button {
            padding: @spacingS;
            background-color: @colorNeutral1;
            color: #1f1f1f;
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;

            @media (max-width: @mobileBreakpoint) {
                font-size: 0.875rem;
            }

            svg {
                height: 1.125rem;
                color: #1f1f1f;

                .rotate(90deg);
            }
        }
        button.products__content__popular-button.show {
            svg {
                .rotate(270deg);
            }
        }

        &__sort {
            margin-top: @spacing2XL;
            text-align: left;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingL;
                display: flex;
                flex-direction: row;
            }

            button {
                .button();

                margin-right: @spacingS;

                @media (max-width: @mobileBreakpoint) {
                    flex: 1;
                    min-width: 0 !important;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    svg {
                        margin-right: @spacingXS;
                        width: 1rem;
                        height: 1rem;
                    }
                }
            }
            button:last-of-type {
                @media (max-width: @mobileBreakpoint) {
                    margin-right: 0;
                }
            }
            button.active {
                box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.16);
                background-color: #1f1f1f;
                color: #fefefe;
            }
        }
        &__filtered {
            margin-top: @spacingM;
            text-align: left;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingS;
            }

            >div {
                font-size: 0.75rem;
                background-color: @colorNeutral1;
                color: #1f1f1f;
                height: 2rem !important;
                margin-right: @spacingXS;
                border-radius: 0 !important;

                span {
                    padding-left: @spacingS;
                    padding-right: @spacingS;
                }

                svg {
                    width: 1rem;
                    height: 1rem;
                    color: @colorNeutral2;
                }
            }
        }

        .products-list {
            margin-top: @spacing2XL;

            @media (max-width: @mobileBreakpoint) {
                margin-top: 0;
            }
        }

        &__empty {
            margin-top: @spacing4XL;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacing2XL;
            }

            &__text {
                .fontBarlow();

                width: 42rem;
                font-size: 3.5rem;
                font-weight: bold;
                font-style: italic;
                line-height: 1.3;
                text-align: center;
                color: #1f1f1f;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                    font-size: 2rem;
                    margin-bottom: @spacing2XL;
                }
            }

            >button {
                .button();

                margin-top: @spacing3XL;
            }

            &__button-text {
                margin-top: @spacingM;
                line-height: 1.8;
                letter-spacing: normal;
                text-align: center;
                color: #1f1f1f;
            }
        }
    }
}
