@import 'variables';

.lightbox {
    border-radius: 0 !important;

    @media (max-width: @mobileBreakpoint) {
        margin: 0 !important;
    }

    >h2.MuiDialogTitle-root {
        .fontBarlow();

        padding: @spacingL @spacingL 0 @spacingL;
        font-size: 2rem;
        font-weight: bold;
        font-style: italic;
        line-height: 1.4;
        color: #1f1f1f;
        text-transform: uppercase;

        @media (max-width: @mobileBreakpoint) {
            font-size: 1.5rem;
            padding-left: 1.625rem;
            padding-right: 1.625rem;
            text-align: left !important;
        }
    }

    >div.MuiDialogContent-root {
        padding: @spacingL @spacingL @spacingS @spacingL;

        @media (max-width: @mobileBreakpoint) {
            padding-left: 1.625rem;
            padding-right: 1.625rem;
        }
    }
}
