@font-face {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/redhattext/v12/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML-ZwVrY.ttf) format('truetype');
}
@font-face {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/redhattext/v12/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML7hwVrY.ttf) format('truetype');
}
@font-face {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/redhattext/v12/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML4pwVrY.ttf) format('truetype');
}
@font-face {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/redhattext/v12/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML2Z3VrY.ttf) format('truetype');
}
@font-face {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/redhattext/v12/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML193VrY.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowcondensed/v12/HTxxL3I-JCGChYJ8VI-L6OO_au7B6xTT3w.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrY3TWvA.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowcondensed/v12/HTx3L3I-JCGChYJ8VI-L6OO_au7B2xY.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B46r2_3E.ttf) format('truetype');
}
body {
  padding: 0;
  margin: 0;
  background-color: #fdfdfd;
  color: #030303;
  font-family: 'Red Hat Text', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
}
h1,
h2,
h3 {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}
html {
  font-size: 16px;
}
@media (max-width: 1550px) {
  html {
    font-size: 15.5px;
  }
}
@media (max-width: 1500px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 1450px) {
  html {
    font-size: 14.5px;
  }
}
@media (max-width: 1400px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 1350px) {
  html {
    font-size: 13.5px;
  }
}
@media (max-width: 1300px) {
  html {
    font-size: 13px;
  }
}
@media (max-width: 1250px) {
  html {
    font-size: 12.5px;
  }
}
@media (max-width: 1200px) {
  html {
    font-size: 16px;
  }
}
button {
  font-family: 'Red Hat Text', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
}
a {
  text-decoration: none;
}
#screen-loading {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
}
button[disabled] {
  background-color: #e1e1e1 !important;
  box-shadow: none;
}
.c-green {
  color: #409b04 !important;
}
.c-red {
  color: #c24646 !important;
}
b {
  font-weight: 600;
}
.snackbar .snackbar-content {
  box-shadow: none !important;
}
.snackbar .snackbar-message {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  padding: 0 2rem;
}
.snackbar .snackbar-message svg {
  margin-right: 10px;
}
.snackbar.success .snackbar-content {
  background-color: #409b04;
}
.snackbar.error .snackbar-content {
  background-color: #c24646;
}
.MuiDialog-paperScrollPaper {
  max-width: 100rem !important;
}
.MuiSnackbar-root .MuiPaper-root {
  padding: 0 !important;
  height: 52px;
}
.MuiSnackbar-root .MuiPaper-root .MuiSnackbarContent-message {
  padding: 0 !important;
}
.swiper-scrollbar {
  background-color: transparent;
  border-top: 2px solid rgba(31, 31, 31, 0.5);
  border-radius: 0;
}
.swiper-scrollbar-drag {
  margin-top: -5px;
  background-color: #8b8b8b;
  height: 8px;
}
