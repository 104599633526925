@import 'variables';

.products-list {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__content {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: -@spacingL;

        @media (max-width: @mobileBreakpoint) {
            margin-top: 0;
        }

        &__item, button.products-list__content__item {
            width: ~"calc((100% - @{spacingL} * 2) / 3)";
            margin-right: @spacingL;
            margin-top: @spacingL;
            padding: @spacingL;
            display: flex;
            flex-direction: column;
            position: relative;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingXL;
                margin-right: @spacingXXS;
                padding: @spacingXXS;
                width: ~"calc((100% - @{spacingXXS}) / 2)";
            }

            &__flags {
                position: absolute;
                left: 0;
                top: 0;

                &__flag {
                    padding: @spacingXXS;
                    font-size: 0.75rem;
                    text-transform: uppercase;
                    color: @colorBaseColor;
                    font-weight: bold;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.625rem;
                    }
                }
            }

            &__variants {
                position: absolute;
                right: @spacingL;
                top: @spacingL;
                display: none;

                &__variant, button.product__right__variants__items__variant {
                    width: 2rem;
                    height: 2rem;
                    border: solid 1px #409b04;
                    font-size: 0.75rem;
                    color: #409b04;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: @spacingXXS / 2 !important;
                }
                &__variant[disabled], button.product__right__variants__items__variant[disabled] {
                    border-color: #f7f7f7;
                    color: @colorNeutral3;
                    background: linear-gradient(to top right, #f7f7f7 0%, #f7f7f7 calc(50% - 0.8px), @colorNeutral3 50%, #f7f7f7 calc(50% + 0.8px), #f7f7f7 100%);
                }
                &__variant.order, button.product__right__variants__items__variant.order {
                    border-color: @colorOrange;
                    color: @colorOrange;
                }
                &__variant.instock, button.product__right__variants__items__variant.instock {
                    border-color: @colorGreen;
                    color: @colorGreen;
                }
                &__variant:last-of-type, button.product__right__variants__items__variant:last-of-type {
                    margin-bottom: 0;
                }
            }

            img {
                width: 100%;
                mix-blend-mode: multiply;
            }

            &__info {
                margin-top: @spacingL;
                width: 100%;
                height: 4.375rem;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                @media (max-width: @mobileBreakpoint) {
                    flex-direction: column;
                    height: 5rem;
                    justify-content: flex-start;
                }

                &__left {
                    &__name {
                        .fontBarlow();

                        font-size: 1.125rem;
                        font-weight: bold;
                        font-style: italic;
                        line-height: 1.2;
                        letter-spacing: 0.9px;
                        color: #1f1f1f;
                        text-transform: uppercase;
                        text-align: left;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                        }
                    }

                    &__variables {
                        margin-top: @spacingXXS;
                        font-size: 0.75rem;
                        text-align: left;
                        color: @colorNeutral2;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.5rem;
                        }
                    }
                }

                &__right {
                    &__price {
                        color: #1f1f1f;
                        white-space: nowrap;
                        text-align: right;

                        @media (max-width: @mobileBreakpoint) {
                            margin-top: @spacingXXS;
                            font-size: 0.75rem;
                            margin-left: 0;
                            flex: 1;
                            text-align: left;
                        }
                    }

                    &__price-vat {
                        margin-top: @spacingXXS;
                        color: @colorNeutral2;
                        white-space: nowrap;
                        text-align: right;
                        font-size: 0.75rem;

                        @media (max-width: @mobileBreakpoint) {
                            margin-top: @spacingXXS / 2;
                            font-size: 0.75rem;
                            margin-left: 0;
                            flex: 1;
                            text-align: left;
                        }
                    }
                }
            }
        }
        &__item:hover {
            box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);

            .products-list__content__item__variants {
                display: block;

                @media (max-width: @mobileBreakpoint) {
                    display: none;
                }
            }
        }
        &__item:nth-child(3n + 3), button.products-list__content__item:nth-child(3n + 3) {
            margin-right: 0;

            @media (max-width: @mobileBreakpoint) {
                margin-right: @spacingXXS;
            }
        }
        &__item:nth-child(2n + 2), button.products-list__content__item:nth-child(2n + 2) {
            @media (max-width: @mobileBreakpoint) {
                margin-right: 0;
            }
        }
    }

    &__load, button.products-list__load {
        .button();

        margin-top: @spacing2XL;

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacingXL;
        }
    }
}
