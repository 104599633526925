.register {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
@media (max-width: 1200px) {
  .register {
    min-height: 86vh;
  }
}
.register__panel {
  width: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .register__panel {
    width: 100%;
  }
}
.register__panel__up__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  font-weight: bold;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .register__panel__up__title {
    font-size: 3.5rem;
  }
}
.register__panel__up__text {
  margin-top: 1rem;
  padding-bottom: 2rem;
  font-size: 1rem;
  color: #1f1f1f;
  line-height: 1.8;
}
@media (max-width: 1200px) {
  .register__panel__up__text {
    font-size: 0.875rem;
  }
}
.register__panel__up__buttons {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
}
.register__panel__up__buttons button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
}
@media (max-width: 1200px) {
  .register__panel__up__buttons button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
.register__panel__up__buttons button.active {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  height: 3.5rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #4030dc;
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #fff;
  text-transform: uppercase;
  border: 0 !important;
  border-radius: 0 !important;
  height: 3rem;
}
@media (max-width: 1200px) {
  .register__panel__up__buttons button.active {
    font-size: 0.875rem;
  }
}
.register__panel__up .input {
  margin-top: 1.5rem;
}
.register__panel__up .checkbox {
  margin-top: 1.5rem;
}
.register__panel__up .checkbox > span {
  font-size: 0.75rem;
  line-height: 1.4;
  color: #1f1f1f;
}
.register__panel__up .checkbox > span button {
  color: #8b8b8b;
  text-decoration: underline;
  margin: 0 0.25rem;
  font-size: 0.75rem;
  line-height: 1.2;
}
.register__panel__down {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}
@media (max-width: 1200px) {
  .register__panel__down {
    flex-direction: column-reverse;
    align-items: flex-end;
    margin-top: 0;
  }
}
.register__panel__down__login {
  color: #1f1f1f;
}
.register__panel__down__login button {
  margin-left: 0.25rem;
  font-weight: 300;
  color: #8b8b8b;
  text-decoration: underline;
}
@media (max-width: 1200px) {
  .register__panel__down__login button {
    font-size: 0.875rem;
  }
}
@media (max-width: 1200px) {
  .register__panel__down__login {
    font-size: 0.875rem;
  }
}
.register__panel__down__button,
.register__panel__down button.register__panel__down__button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
}
@media (max-width: 1200px) {
  .register__panel__down__button,
  .register__panel__down button.register__panel__down__button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .register__panel__down__button,
  .register__panel__down button.register__panel__down__button {
    margin-bottom: 4rem;
  }
}
.register__right {
  margin-left: 10rem;
  min-width: 36rem;
  width: 36rem;
}
@media (max-width: 1200px) {
  .register__right {
    display: none;
  }
}
.register__right__photo {
  height: 20rem;
  background-color: #f5f5f5;
  overflow: hidden;
}
.register__right__photo img {
  width: 100%;
}
.register__right__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
}
.register__right__panel {
  flex: 1;
}
.register__right__panel__item {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.register__right__panel__item svg {
  margin-right: 1rem;
  color: #409b04;
}
.register__right__panel__item span {
  color: #1f1f1f;
}
.register__right__panel__item:last-of-type {
  margin-bottom: 0;
}
