.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact__header {
  display: flex;
  flex-direction: row;
  width: 100%;
}
@media (max-width: 1200px) {
  .contact__header {
    flex-direction: column-reverse;
  }
}
.contact__header__map {
  margin-right: 10rem;
  min-width: 46rem;
  width: 46rem;
  height: 46rem;
  background-color: #f5f5f5;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .contact__header__map {
    margin-right: 0;
    width: 100%;
    min-width: 100%;
    height: 20rem;
  }
}
.contact__header__map iframe {
  width: 100%;
  height: 100%;
}
.contact__header__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contact__header__content__up__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  font-weight: bold;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .contact__header__content__up__title {
    font-size: 3.5rem;
  }
}
.contact__header__content__up__text {
  margin-top: 3rem;
  line-height: 1.8;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .contact__header__content__up__text {
    margin-top: 2rem;
    font-size: 0.875rem;
  }
}
.contact__header__content__up__text span {
  margin: 0 0.25rem;
  font-weight: 600;
}
.contact__header__content__up__phone {
  margin-top: 1.5rem;
  font-size: 1.5rem;
  color: #8b8b8b;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .contact__header__content__up__phone {
    font-size: 1.25rem;
    margin-top: 3rem;
  }
}
.contact__header__content__up__phone svg {
  margin-right: 1rem;
  width: 1.875rem;
  height: 1.875rem;
}
@media (max-width: 1200px) {
  .contact__header__content__up__phone svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.contact__header__content__up__email {
  font-size: 1.5rem;
  color: #8b8b8b;
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}
@media (max-width: 1200px) {
  .contact__header__content__up__email {
    font-size: 1.25rem;
  }
}
.contact__header__content__up__email svg {
  margin-right: 1rem;
  width: 1.875rem;
  height: 1.875rem;
}
@media (max-width: 1200px) {
  .contact__header__content__up__email svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.contact__header__content__down {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .contact__header__content__down {
    flex-direction: column;
    margin-top: 4rem;
    align-items: center;
  }
}
.contact__header__content__down__panel {
  flex: 1;
}
@media (max-width: 1200px) {
  .contact__header__content__down__panel {
    margin-bottom: 3rem;
    width: 10.25rem;
  }
}
.contact__header__content__down__panel__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  color: #030303;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .contact__header__content__down__panel__title {
    font-size: 1.5rem;
  }
}
.contact__header__content__down__panel__text {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1200px) {
  .contact__header__content__down__panel__text {
    margin-top: 1rem;
  }
}
.contact__header__content__down__panel__text span {
  width: 100%;
  font-size: 1rem;
  color: #1f1f1f;
  line-height: 1.8;
}
@media (max-width: 1200px) {
  .contact__header__content__down__panel__text span {
    font-size: 0.875rem;
  }
}
.contact__header__content__down__panel__text .mini {
  margin-top: 1rem;
  font-size: 0.75rem;
  line-height: 1.4;
  color: #1f1f1f;
  max-width: 12.5rem;
}
@media (max-width: 1200px) {
  .contact__header__content__down__panel__text .mini {
    font-size: 0.625rem;
  }
}
.contact__header__content__down__panel:first-of-type {
  margin-right: 2rem;
}
@media (max-width: 1200px) {
  .contact__header__content__down__panel:first-of-type {
    margin-right: 0;
  }
}
.contact__persons {
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 62rem;
}
@media (max-width: 1200px) {
  .contact__persons {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}
.contact__persons__person {
  margin-top: 9rem;
  margin-right: 10rem;
  width: 14rem;
}
@media (max-width: 1200px) {
  .contact__persons__person {
    margin-top: 0;
    margin-bottom: 5.5rem;
    margin-right: 0;
  }
}
.contact__persons__person > span {
  margin: 0 0 0 0.25rem;
  text-decoration: underline;
}
.contact__persons__person__photo {
  width: 14rem;
  height: 18.6875rem;
  background-color: #f5f5f5;
  border-radius: 1rem;
  overflow: hidden;
}
.contact__persons__person__photo img {
  width: 100%;
}
.contact__persons__person__title {
  margin-top: 1.5rem;
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .contact__persons__person__title {
    font-size: 0.625rem;
  }
}
.contact__persons__person__name {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-top: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .contact__persons__person__name {
    font-size: 1.25rem;
  }
}
.contact__persons__person__email {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  color: #8b8b8b;
}
@media (max-width: 1200px) {
  .contact__persons__person__email {
    font-size: 0.875rem;
  }
}
.contact__persons__person__email svg {
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
}
@media (max-width: 1200px) {
  .contact__persons__person__email svg {
    width: 0.875rem;
    height: 0.875rem;
  }
}
.contact__persons__person__phone {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  color: #8b8b8b;
}
@media (max-width: 1200px) {
  .contact__persons__person__phone {
    font-size: 0.875rem;
  }
}
.contact__persons__person__phone svg {
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
}
@media (max-width: 1200px) {
  .contact__persons__person__phone svg {
    width: 0.875rem;
    height: 0.875rem;
  }
}
.contact__persons__person:first-of-type {
  font-size: 1.5rem;
  line-height: 1.4;
  color: #1f1f1f;
  position: relative;
}
@media (max-width: 1200px) {
  .contact__persons__person:first-of-type {
    font-size: 1.25rem;
    width: 22rem;
  }
}
.contact__persons__person:first-of-type .contact__persons__person__text {
  width: 22rem;
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 1200px) {
  .contact__persons__person:first-of-type .contact__persons__person__text {
    position: relative;
    text-align: center;
  }
}
.contact__persons__person:nth-child(3n + 3) {
  margin-right: 0;
}
