@import 'variables';

.checkout {
    display: flex;
    flex-direction: row;

    @media (max-width: @mobileBreakpoint) {
        flex-direction: column;
    }

    .input__left {
        top: auto;
        bottom: 0;
    }

    .phone {
        display: flex;
        flex-direction: row;
        flex: 1;

        .select {
            margin-top: -3px;
            width: 7.25rem;

            @media (max-width: @mobileBreakpoint) {
                margin-top: 0;
            }

            .select__label {
                opacity: 0;
            }

            .select__container {
                border-bottom-right-radius: 0 !important;
                border-top-right-radius: 0 !important;
            }
        }

        .input {
            .input__label {
                margin-left: -7.25rem;
            }

            .MuiFilledInput-root {
                border-bottom-left-radius: 0 !important;
                border-top-left-radius: 0 !important;
            }
        }
    }

    textarea {
        margin-top: -0.625rem;
    }

    &__settings {
        flex: 1;

        &__title {
            .fontBarlow();

            font-size: 5rem;
            font-weight: bold;
            font-style: italic;
            color: #1f1f1f;
            text-transform: uppercase;

            @media (max-width: @mobileBreakpoint) {
                font-size: 3.5rem;
            }
        }

        &__account {
            margin-top: @spacingS;

            &__header {
                height: 1.25rem;
                display: flex;
                flex-direction: row;
                align-items: center;

                @media (max-width: @mobileBreakpoint) {
                    justify-content: space-between;
                    height: auto;
                }

                &__text {
                    font-weight: 300;
                    color: #000;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.875rem;
                    }
                }

                &__button, button.checkout__settings__account__header__button {
                    margin-left: @spacing2XL;
                    font-weight: 300;
                    color: @colorNeutral2;
                    text-decoration: underline;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.875rem;
                        margin-left: 0;
                    }
                }
            }

            &__content {
                margin-top: @spacing2XL;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingXL;
                }

                &__inputs {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    @media (max-width: @mobileBreakpoint) {
                        flex-direction: column;
                    }

                    .input {
                        flex: 1;
                    }
                    .input:first-of-type {
                        margin-right: @spacingL;

                        @media (max-width: @mobileBreakpoint) {
                            margin-right: 0;
                            margin-bottom: @spacingM;
                        }
                    }
                }

                &__lost-password {
                    margin-top: @spacingXXS;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;

                    &__button, button.checkout__settings__account__content__lost-password__button {
                        margin-left: @spacing2XL;
                        font-weight: 300;
                        color: @colorNeutral2;
                        text-decoration: underline;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                        }
                    }
                }

                &__buttons {
                    margin-top: @spacingM;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;

                    @media (max-width: @mobileBreakpoint) {
                        flex-direction: column-reverse;
                    }

                    &__button, button.checkout__settings__account__content__buttons__button {
                        .button();

                        @media (max-width: @mobileBreakpoint) {
                            margin-bottom: @spacingS;
                        }
                    }
                }
            }
        }

        &__contact {
            margin-top: @spacingXL;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacing2XL;
            }

            &__title {
                .fontBarlow();

                font-size: 2rem;
                font-weight: bold;
                font-style: italic;
                color: @colorDark;
                text-transform: uppercase;
                padding-bottom: @spacingM;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.5rem;
                }
            }

            &__inputs {
                margin-top: @spacingM;
                display: flex;
                flex-direction: row;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: 0;
                    flex-direction: column;
                }

                .input {
                    flex: 1;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingM;
                    }
                }
                .select {
                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingM !important;
                    }
                }
                .input:first-of-type {
                    margin-right: @spacingL;
                    max-width: 21.25rem;

                    @media (max-width: @mobileBreakpoint) {
                        margin-right: 0;
                        max-width: 100%;
                    }
                }

                >.select:first-of-type {
                    margin-right: @spacingL;
                    max-width: 21.25rem;

                    @media (max-width: @mobileBreakpoint) {
                        margin-right: 0;
                        max-width: 100%;
                    }
                }
            }

            &__create-account, button.checkout__settings__contact__create-account {
                margin-top: @spacingXXS;
                font-weight: 300;
                color: @colorNeutral2;
                text-decoration: underline;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                }
            }

            >.input {
                margin-top: @spacingL;
                width: ~"calc((100% - @{spacingL}) / 2)";

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                    margin-top: @spacingM;
                }
            }
        }

        &__address {
            margin-top: @spacing2XL;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacing3XL;
            }

            &__title {
                .fontBarlow();

                font-size: 2rem;
                font-weight: bold;
                font-style: italic;
                color: @colorDark;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.5rem;
                }
            }

            >.checkbox {
                margin-top: @spacingM;
                margin-bottom: -@spacingXXS;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingS;
                    margin-bottom: 0;
                }
            }

            &__subtitle {
                .fontBarlow();

                font-size: 1.5rem;
                font-weight: bold;
                font-stretch: condensed;
                font-style: italic;
                line-height: 1.2;
                letter-spacing: normal;
                text-align: left;
                color: #1f1f1f;
                text-transform: uppercase;
                padding-top: @spacingXL;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.25rem;
                }
            }

            &__inputs {
                margin-top: @spacingM;
                display: flex;
                flex-direction: row;
                align-items: center;

                .input {
                    flex: 1;
                }
                .input:first-of-type {
                    margin-right: @spacingL;
                }
            }

            >.input {
                margin-top: @spacingM;
            }

            &__manual, button.checkout__settings__address__manual {
                margin-top: @spacingXXS;
                margin-bottom: @spacingXXS;
                font-weight: 300;
                color: @colorNeutral2;
                text-decoration: underline;
                display: block;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                }
            }

            &__delivery {
                margin-top: @spacingM;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingS;
                }

                >.input {
                    margin-top: @spacingM;
                }
            }
        }

        &__items {
            margin-top: @spacing2XL;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacing3XL;
            }

            &__title {
                .fontBarlow();

                font-size: 2rem;
                font-weight: bold;
                font-style: italic;
                color: @colorDark;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.5rem;
                    margin-bottom: @spacingXXS;
                }
            }

            &__buttons {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                &__button, button.checkout__settings__items__buttons__button {
                    position: relative;
                    margin-top: @spacingL;
                    margin-right: @spacingL;
                    width: ~"calc((100% - @{spacingL} * 3) / 4)";
                    border-radius: 0;
                    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
                    background-color: #f7f7f7;
                    border: 1px solid #f7f7f7;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingS;
                        margin-right: @spacingS;
                        width: ~"calc((100% - @{spacingS}) / 2)";
                    }

                    >svg {
                        position: absolute;
                        right: 0.625rem;
                        top: 0.625rem;
                        width: 1.25rem;
                        height: 1.25rem;
                        color: @colorPrimary;
                        opacity: 0;
                    }

                    img {
                        margin-top: -@spacingXL;
                        height: 3.5rem;
                    }

                    &__icon {
                        margin-top: -@spacingXL;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        svg {
                            margin-bottom: @spacingXXS;
                            color: @colorNeutral2;
                            width: 2.5rem;
                            height: 2.5rem;
                        }

                        span {
                            text-align: center;
                            font-size: 0.75rem;
                            font-weight: 300;
                            line-height: 1.08;
                            color: #000;
                        }
                    }

                    &__price {
                        color: @colorNeutral2;
                        position: absolute;
                        margin: auto;
                        bottom: @spacingL;
                        left: 0;
                        right: 0;
                    }
                }
                &__button.active, button.checkout__settings__items__buttons__button.active {
                    border-color: @colorPrimary;
                    box-shadow: none;

                    >svg {
                        opacity: 1;
                    }
                }
                &__button:hover, button.checkout__settings__items__buttons__button:hover {
                    box-shadow: none;
                }
                &__button:nth-child(4n + 4), button.checkout__settings__items__buttons__button:nth-child(4n + 4) {
                    margin-right: 0;

                    @media (max-width: @mobileBreakpoint) {
                        margin-right: @spacingS;
                    }
                }
                &__button:nth-child(2n + 2), button.checkout__settings__items__buttons__button:nth-child(2n + 2) {
                    @media (max-width: @mobileBreakpoint) {
                        margin-right: 0;
                    }
                }
                &__button::before, button.checkout__settings__items__buttons__button::before {
                    content: '';
                    float: left;
                    padding-top: 100%;
                }
            }
        }
    }

    &__summary {
        margin-left: 10rem;
        width: 37.5rem;

        @media (max-width: @mobileBreakpoint) {
            margin-left: 0;
            width: 100%;
            margin-top: @spacing3XL;
        }

        &__content {
            padding: @spacing2XL;
            border-radius: 1rem;
            background-color: #f7f7f7;

            @media (max-width: @mobileBreakpoint) {
                border-radius: 0;
                margin: 0 -1.625rem -@spacingXL -1.625rem;
                padding: @spacingXL 1.625rem;
            }

            &__title {
                .fontBarlow();

                font-size: 2rem;
                font-weight: bold;
                font-style: italic;
                color: @colorDark;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.5rem;
                    margin-bottom: @spacingS;
                }
            }

            &__products {
                &__product {
                    margin-top: @spacingL;

                    &__up {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        min-height: 4rem;

                        @media (max-width: @mobileBreakpoint) {
                            min-height: 3rem;
                        }

                        &__left {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            &__photo {
                                width: 4rem;
                                height: 4rem;

                                @media (max-width: @mobileBreakpoint) {
                                    width: 3rem;
                                    height: 3rem;
                                }

                                img {
                                    width: 100%;
                                    mix-blend-mode: multiply;
                                }
                            }
                        }

                        &__info {
                            padding: 0 @spacingXL;
                            display: flex;
                            flex-direction: column;
                            flex: 1;

                            @media (max-width: @mobileBreakpoint) {
                                padding: 0 @spacingXXS;
                            }

                            &__name {
                                .fontBarlow();

                                font-size: 1.125rem;
                                font-weight: bold;
                                font-style: italic;
                                letter-spacing: 0.9px;
                                color: #1f1f1f;
                                text-transform: uppercase;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.875rem;
                                }
                            }
                        }

                        &__price {
                            color: #1f1f1f;
                            font-size: 1rem;
                            text-align: right;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                            }
                        }
                    }
                    &__up.with-extensions {
                        align-items: flex-start;
                    }

                    &__extensions {
                        margin-top: -1.875rem;

                        @media (max-width: @mobileBreakpoint) {
                            margin-top: -1rem;
                        }

                        &__extension {
                            margin-top: @spacingXXS;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            padding-left: @spacingXL + 4rem;

                            @media (max-width: @mobileBreakpoint) {
                                padding-left: 3rem + @spacingXXS;
                            }

                            &__name {
                                font-size: 0.75rem;
                                color: #1f1f1f;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.625rem;
                                }

                                svg {
                                    margin-left: 0.25rem;
                                    width: 1rem;
                                    height: 1rem;
                                    color: @colorNeutral2;

                                    @media (max-width: @mobileBreakpoint) {
                                        width: 0.625rem;
                                        height: 0.625rem;
                                    }
                                }
                            }

                            &__price {
                                font-size: 0.75rem;
                                color: #1f1f1f;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 0.625rem;
                                }
                            }
                        }
                        &__extension:first-of-type {
                            margin-top: 0;
                        }
                    }
                }
            }

            &__coupon {
                margin-top: @spacing3XL;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacing2XL;
                }

                &__button, button.checkout__summary__content__coupon__button {
                    font-weight: 300;
                    color: @colorNeutral2;
                    text-decoration: underline;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.875rem;
                    }
                }

                &__content {
                    margin-top: @spacingXXS;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    @media (max-width: @mobileBreakpoint) {
                        flex-direction: column;
                        align-items: flex-end;
                    }

                    .input {
                        flex: 1;
                        margin-right: 5rem;

                        @media (max-width: @mobileBreakpoint) {
                            margin-right: 0;
                        }

                        .MuiFilledInput-root {
                            background-color: white !important;
                        }
                    }

                    &__button, button.checkout__summary__content__coupon__content__button {
                        .button();

                        @media (max-width: @mobileBreakpoint) {
                            margin-top: @spacingS;
                            white-space: nowrap;
                        }
                    }
                }
            }

            &__amounts {
                margin-top: @spacingXL;

                &__item {
                    margin-bottom: @spacingS;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    &__name {
                        font-size: 1rem;
                        color: #1f1f1f;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                        }
                    }

                    &__value {
                        font-size: 1rem;
                        color: #1f1f1f;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                        }
                    }
                }
                &__item:last-of-type {
                    margin-bottom: 0;
                }
            }

            &__amount {
                margin-top: @spacingM;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                &__name {
                    font-size: 1.5rem;
                    color: #000;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1.25rem;
                    }
                }

                &__value {
                    font-size: 1.375rem;
                    color: #000;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1.25rem;
                    }
                }
            }

            &__vat {
                margin-top: @spacingS;

                &__item {
                    margin-bottom: @spacingXXS;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    &__name {
                        font-size: 0.75rem;
                        color: #1f1f1f;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.625rem;
                        }
                    }

                    &__value {
                        font-size: 0.75rem;
                        color: #1f1f1f;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.625rem;
                        }
                    }
                }
                &__item:last-of-type {
                    margin-bottom: 0;
                }
            }

            >.input {
                margin-top: @spacingXL;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacing2XL;
                }

                .input__label {
                    font-weight: 300;
                }

                .MuiFilledInput-root {
                    background-color: white !important;
                }
            }

            >.checkbox {
                margin-top: @spacingM;
                align-items: flex-start;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingS;
                }

                >span {
                    font-size: 0.75rem;
                    color: #1f1f1f;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.625rem;
                    }
                }
            }

            &__down {
                margin-top: @spacingL;

                &__button, button.checkout__summary__content__down__button {
                    .buttonPrimary();

                    width: 100%;
                }

                &__text {
                    margin-top: @spacingM;
                    font-size: 0.75rem;
                    color: #1f1f1f;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingS;
                        font-size: 0.625rem;
                    }

                    button {
                        color: @colorNeutral2;
                        text-decoration: underline;
                        margin: 0 0.25rem;
                        font-size: 0.75rem;
                        line-height: 1.2;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.625rem;
                        }
                    }
                }
            }
            &__down-fixed {
                display: none;
                position: fixed;
                left: 0;
                bottom: 0;
                width: ~"calc(100% - @{spacingM} * 2)";
                height: 10rem - 2 * @spacingM;
                box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.16);
                background-color: #f7f7f7;
                padding: @spacingM;
                z-index: 100;

                .checkout__summary__content__down {
                    margin-top: 0;
                }

                @media (max-width: @mobileBreakpoint) {
                    display: block;
                }
            }
        }
    }
}
