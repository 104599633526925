.delivery {
  margin: 0 8rem 0 8rem;
  padding-top: 1.5rem;
}
@media (max-width: 1200px) {
  .delivery {
    margin: 0;
    padding-top: 1rem;
  }
}
.delivery__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  font-weight: bold;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .delivery__title {
    font-size: 3.5rem;
  }
}
.delivery__text {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .delivery__text {
    font-size: 0.75rem;
  }
}
.delivery > .select {
  margin-top: 3rem;
  width: 37.5rem;
}
@media (max-width: 1200px) {
  .delivery > .select {
    margin-top: 4rem;
    width: 100%;
  }
}
