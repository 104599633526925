@import 'variables';

.menu {
    position: sticky;
    top: 0;
    padding: @spacing2XL + @spacingS 0 @spacingL 0;
    height: 2.5rem;
    background-color: @colorBaseColor;
    z-index: 200;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);

    @media (max-width: @mobileBreakpoint) {
        padding: @spacingS 0;
        height: 2rem;
    }

    &__head {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 3rem;
        background-color: @colorNeutral1;

        @media (max-width: @mobileBreakpoint) {
            display: none;
        }

        &__content {
            margin: auto;
            padding: 0 @spacingXL;
            max-width: ~"calc(1600px - @{spacingXL} * 2)";
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 0.875rem;

            &__left {
                display: flex;
                flex-direction: row;
                align-items: center;

                &__phone {
                    font-weight: bold;
                }

                &__clock {
                    margin-left: @spacingS;
                }

                &__shops {
                    margin-left: @spacingL;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    &__text {
                        font-weight: bold;
                        margin-right: @spacingXXS;
                    }

                    button {
                        font-size: 0.875rem;

                        svg {
                            width: 1rem;
                            height: 1rem;
                        }
                    }
                    button:hover span {
                        text-decoration: underline;
                    }
                }
            }

            &__right {
                display: flex;
                flex-direction: row;
                align-items: center;

                button {
                    margin-left: @spacingM;
                    font-size: 0.75rem;
                    text-transform: uppercase;

                    svg {
                        width: 1rem;
                        height: 1rem;

                        .rotate(90deg);
                    }
                }
                button:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__content {
        margin: auto;
        padding: 0 @spacingXL;
        max-width: ~"calc(1600px - @{spacingXL} * 2)";
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media (max-width: @mobileBreakpoint) {
            padding: 0 1.625rem;
        }

        &__left {
            flex-direction: row;
            align-items: center;
            display: none;

            @media (max-width: @mobileBreakpoint) {
                display: flex;
            }

            &__button, button.menu__content__left__button {
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 0.5rem;

                &__content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    svg {
                        margin-top: 0.1875rem;
                        margin-bottom: 0.1875rem;
                        width: 1.5rem;
                        height: 1.5rem;

                        @media (max-width: @mobileBreakpoint) {
                            width: 1.25rem;
                            height: 1.25rem;
                        }
                    }

                    span {
                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.5rem;
                        }
                    }
                }

                >span {
                    font-size: 0.75rem;
                }
            }
        }

        &__logo {
            height: 3.625rem;
            cursor: pointer;
            mix-blend-mode: multiply;

            @media (max-width: @mobileBreakpoint) {
                height: 2rem;
            }
        }

        &__buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;

            @media (max-width: @mobileBreakpoint) {
                display: none;
            }

            button {
                margin-right: @spacingXL;
                font-size: 0.875rem;
                font-weight: 600;
                letter-spacing: 0.7px;
                text-transform: uppercase;

                svg {
                    color: @colorNeutral2;
                    height: 1.125rem;
                }
            }
            button.show-big {
                >svg {
                    .rotate(90deg);
                }
            }
            button:last-of-type {
                margin-right: 0;
            }
            button.active {
                color: @colorPrimary;
            }
        }

        &__right {
            display: flex;
            flex-direction: row;
            align-items: center;

            &__button, button.menu__content__right__button {
                margin-right: @spacingS;
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 0.5rem;

                @media (max-width: @mobileBreakpoint) {
                    display: none;
                }

                &__content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    svg {
                        width: 1.875rem;
                        height: 1.875rem;
                        color: @colorNeutral2;
                    }

                    span {
                        font-size: 0.75rem;
                        color: @colorNeutral2;
                    }
                }

                >span {
                    font-size: 0.75rem;
                }
            }
            &__button-currency, button.menu__content__right__button-currency {
                background-color: @colorNeutral1;
                width: 1.5rem;
                height: 1.5rem;
            }
            &__button-country, button.menu__content__right__button-country {
                background-color: @colorNeutral1;
                width: 1.5rem;
                height: 1.5rem;
                overflow: hidden;
                margin-right: @spacingL;

                img {
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }
            &__button-cart, button.menu__content__right__button-cart, button.menu__content__right__button-favorites, button.menu__content__right__button-lists, button.menu__content__right__button-compares {
                @media (max-width: @mobileBreakpoint) {
                    display: inline-flex;
                }

                .BaseBadge-badge {
                    @media (max-width: @mobileBreakpoint) {
                        font-size: 0.625rem;
                    }
                }

                .menu__content__right__button__content {
                    svg {
                        margin-top: 0.1875rem;
                        margin-bottom: 0.1875rem;
                        width: 1.5rem;
                        height: 1.5rem;

                        @media (max-width: @mobileBreakpoint) {
                            width: 1.25rem;
                            height: 1.25rem;
                        }
                    }

                    span {
                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.5rem;
                        }
                    }
                }
            }
            button.menu__content__right__button-favorites, button.menu__content__right__button-lists, button.menu__content__right__button-compares {
                margin-right: @spacingS;

                @media (max-width: @mobileBreakpoint) {
                    display: none;
                }
            }
            &__button:last-of-type, button.menu__content__right__button:last-of-type {
                margin-right: 0;
            }
        }
    }

    &__search {
        position: absolute;
        left: 0;
        top: 2.5rem + @spacing2XL + @spacingXL;
        width: 100%;
        min-height: ~"calc(100vh - 2.5rem - @{spacing2XL} - @{spacingXL})";
        background-color: rgba(0,0,0,0.25);

        &__window {
            padding: @spacing2XL 0 @spacing3XL 0;
            box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
            background-color: #f7f7f7;

            &__content {
                margin: auto;
                width: 56rem;

                &__input {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;

                    div.MuiFilledInput-root {
                        background-color: @colorBaseColor !important;
                    }

                    svg {
                        margin-bottom: 0.9rem;
                        width: 2rem;
                        height: 2rem;
                        color: @colorNeutral2;
                    }
                    svg:first-of-type {
                        margin-left: @spacingXS;
                    }
                }

                &__panels {
                    margin-top: @spacing2XL;
                    display: flex;
                    flex-direction: row;

                    &__products {
                        flex: 1;

                        &__title {
                            font-size: 0.875rem;
                            font-weight: 600;
                            line-height: 1.29;
                            letter-spacing: 0.7px;
                            color: #161616;
                            text-transform: uppercase;
                        }

                        &__items {
                            margin-top: @spacingL;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;

                            &__product, button.menu__search__window__content__panels__products__items__product {
                                margin-bottom: @spacingXL;
                                width: ~"calc(50% - @{spacingXXS} * 2)";
                                padding: @spacingXXS;
                                display: flex;
                                flex-direction: row;

                                &__photo {
                                    width: 4rem;
                                    height: 4rem;

                                    img {
                                        width: 100%;
                                        mix-blend-mode: multiply;
                                    }
                                }

                                &__info {
                                    margin-left: @spacingS;
                                    flex: 1;

                                    &__name {
                                        .fontBarlow();

                                        font-size: 1.125rem;
                                        font-weight: bold;
                                        font-style: italic;
                                        line-height: 1.2;
                                        color: #1f1f1f;
                                        text-transform: uppercase;
                                        text-align: left;
                                    }

                                    &__price {
                                        margin-top: @spacingXXS;
                                        font-size: 0.875rem;
                                        line-height: 1.29;
                                        color: #1f1f1f;
                                        text-align: left;
                                    }
                                }
                            }
                            &__product:hover, button.menu__search__window__content__panels__products__items__product:hover {
                                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
                            }
                        }

                        &__button, button.menu__search__window__content__panels__products__button {
                            .button();
                        }
                    }

                    &__articles {
                        margin-left: 6.5rem;
                        width: 15rem;

                        &__title {
                            font-size: 0.875rem;
                            font-weight: 600;
                            line-height: 1.29;
                            letter-spacing: 0.7px;
                            color: #161616;
                            text-transform: uppercase;
                        }

                        &__article, button.menu__search__window__content__panels__articles__article {
                            margin: @spacingM -@spacingXXS 0 -@spacingXXS;
                            padding: @spacingXXS;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            text-align: left;

                            &__category {
                                font-size: 0.75rem;
                                line-height: 1.4;
                                color: @colorNeutral2;
                            }

                            &__name {
                                margin-top: @spacingXXS;
                                font-weight: 600;
                                line-height: 1.8;
                                color: #1f1f1f;
                            }

                            &__date {
                                margin-top: @spacingXXS;
                                font-size: 0.75rem;
                                line-height: 1.4;
                                color: #1f1f1f;
                            }
                        }
                        &__article:hover, button.menu__search__window__content__panels__articles__article:hover {
                            box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
                        }

                        &__button, button.menu__search__window__content__panels__articles__button {
                            .button();

                            margin-top: @spacingXL;
                        }
                    }
                }
            }
        }
    }

    &__big {
        position: absolute;
        left: 0;
        top: 2.5rem + @spacing2XL - 1rem;
        width: 100%;
        min-height: ~"calc(100vh - 2.5rem - @{spacing2XL} + 1rem)";
        display: flex;
        flex-direction: column;

        @media (max-width: @mobileBreakpoint) {
            display: none;
        }

        input {
            opacity: 0;
        }

        &__background {
            position: absolute;
            left: 0;
            top: @spacingL + 1rem;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.25);
        }

        &__window {
            position: relative;
            margin-top: @spacingL + 1rem;
            padding: @spacing2XL 0 @spacing3XL 0;
            box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
            background-color: #f7f7f7;

            &__content {
                margin: auto;
                padding: 0 @spacingXL;
                max-width: ~"calc(1600px - @{spacingXL} * 2)";
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                &__panel {
                    width: 12.8125rem;

                    &__title, button.menu__big__window__content__panel__title {
                        font-size: 0.875rem;
                        font-weight: 600;
                        line-height: 1.29;
                        letter-spacing: 0.7px;
                        color: #161616;
                        margin-bottom: @spacingL;
                        text-transform: uppercase;
                    }

                    &__links {
                        button {
                            margin-top: @spacingM;
                            font-size: 1rem;
                            line-height: 1.58;
                            color: #161616;
                            display: block;
                            white-space: nowrap;
                        }
                        button:first-of-type {
                            margin-top: 0;
                        }
                    }
                }
                &__panel:last-of-type {
                    button {
                        font-weight: 600;
                        text-transform: uppercase;
                        font-size: 0.875rem;
                    }
                }
            }
        }

        &__back {
            flex: 1;
        }
    }
}

.mobile-menu {
    background-color: #f7f7f7;

    .input {
        .MuiFilledInput-root {
            background-color: @colorBaseColor !important;
        }

        input {
            font-size: 0.875rem !important;
        }
    }

    &__categories {
        margin: @spacingM -@spacingM @spacingXL -@spacingM;
        padding: @spacingL @spacingM;
        background-color: @colorBaseColor;

        &__category {
            margin-bottom: @spacingL;

            &__button, button.mobile-menu__categories__category__button {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                &__name {
                    font-size: 0.875rem;
                    font-weight: bold;
                    line-height: 1.29;
                    letter-spacing: 0.7px;
                    text-align: left;
                    color: #161616;
                    text-transform: uppercase;
                }

                svg {
                    width: 2rem;
                    height: 2rem;
                    color: @colorNeutral2;
                }
            }

            &__subcategories {
                margin-left: @spacingM;

                button {
                    margin-top: @spacingM;
                    width: 100%;
                    font-size: 0.875rem;
                    line-height: 1.8;
                    color: #1f1f1f;
                    justify-content: flex-start;
                }
            }
        }
        &__category:last-of-type {
            margin-bottom: 0;
        }
    }

    &__section, button.mobile-menu__section {
        margin-bottom: @spacingL;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &__left {
            display: flex;
            flex-direction: row;
            align-items: center;

            &__icon {
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 0.5rem;
                background-color: @colorBaseColor;
                overflow: hidden;

                img {
                    width: 1.5rem;
                    height: 1.5rem;
                }

                span {
                    font-size: 0.75rem;
                    color: @colorNeutral2;
                }
            }

            &__name {
                margin-left: @spacingM;
                font-size: 0.875rem;
                line-height: 1.8;
                color: #1f1f1f;
            }
        }

        >svg {
            width: 1.5rem;
            height: 1.5rem;
            color: @colorNeutral2;
        }
    }

    &__down {
        margin-top: @spacing4XL;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        button {
            font-size: 0.875rem;
            line-height: 1.8;
            color: #1f1f1f;
        }
    }
}

.menu.menu-checkout {
    position: relative;
    padding-top: @spacingL;
    padding-bottom: 0;
    box-shadow: none;

    .menu__head {
        display: none;
    }

    .menu__content__left {
        display: none;
    }

    .menu__content__buttons {
        display: none;
    }

    .menu__content__right__button-search, .menu__content__right__button-account, .menu__content__right__button-cart, .menu__content__right__button-favorites, .menu__content__right__button-lists, .menu__content__right__button-compares {
        display: none;
    }

    .menu__content__right__button-country {
        margin-right: 0;

        @media (max-width: @mobileBreakpoint) {
            display: inline-flex;
        }
    }
    .menu__content__right__button-currency {
        @media (max-width: @mobileBreakpoint) {
            display: inline-flex;
        }
    }
}

.pop {
    top: 1.5rem !important;

    .MuiPopover-paper {
        display: flex;
        flex-direction: column;
    }

    &__item, button.pop__item {
        width: 6.25rem;
        height: 2.5rem;
        font-size: 0.75rem;

        &__icon {
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 100%;
            overflow: hidden;
            margin-right: @spacingXXS;
        }
    }
}

.pop-info {
    top: -1rem !important;
    justify-content: flex-start;

    .pop__item, button.pop__item {
        width: 22rem;
        height: 3.75rem;
        font-size: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 @spacingL;
        white-space: nowrap;

        svg {
            width: 1.25rem;
            height: 1.25rem;
        }
    }
    .pop__item:hover, button.pop__item:hover {
        color: @colorPrimary;
    }
}
