@import 'variables';

.sizes {
    width: 80rem - 2 * @spacingL;
    display: flex;
    flex-direction: row;

    @media (max-width: @mobileBreakpoint) {
        width: 100%;
        flex-direction: column;
    }

    &__left {
        margin-right: 8rem;
        margin-left: @spacingL;
        width: 22.5rem;
        display: flex;
        flex-direction: column;

        @media (max-width: @mobileBreakpoint) {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
            flex-direction: column-reverse;
        }

        &__photo {
            @media (max-width: @mobileBreakpoint) {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            svg {
                width: 14rem;
                height: 14rem;

                @media (max-width: @mobileBreakpoint) {
                    width: 10rem;
                    height: 10rem;
                }
            }
        }

        &__content {
            &__title {
                .fontBarlow();

                font-size: 2rem;
                font-weight: bold;
                font-style: italic;
                line-height: 1.2;
                color: #1f1f1f;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.5rem;
                }
            }

            &__text {
                margin-top: @spacingL;
                line-height: 1.8;
                font-size: 0.875rem;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingM;
                }
            }
        }
    }

    &__right {
        flex: 1;

        @media (max-width: @mobileBreakpoint) {
            overflow-x: scroll;
        }

        &__row {
            font-size: 0.875rem;
            display: flex;
            flex-direction: row;

            &__cell {
                min-width: 7rem;
                padding: 0.75rem 0;
                border-bottom: 1px solid @colorNeutral2;
            }
            &__cell:first-of-type {
                flex: 1;
                font-weight: bold;
            }
        }
        &__row:first-of-type {
            .sizes__right__row__cell {
                font-weight: bold;

                span {
                    font-weight: normal;
                    font-size: 0.75rem;
                }
            }
        }
        &__row:last-of-type {
            .sizes__right__row__cell {
                border-bottom: 0;
            }
        }
    }
}
